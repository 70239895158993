import React from 'react';
import IconContainer from '../IconContainer';


const WrenchIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" fill="currentColor" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M15.7898311,11.2921654 C16.3601879,9.34664735 15.8554033,7.22300425 14.3943772,5.73354333 C12.6062336,3.9099529 9.83158994,3.48822229 7.58422838,4.64401961 C7.25163627,4.81506866 7.18291446,5.25612728 7.44803197,5.51813215 L10.4448512,8.47976689 C10.6506442,8.68314374 10.6506442,9.01248694 10.4445081,9.21655962 L9.2138694,10.4327496 C9.0081336,10.6360699 8.6744027,10.6360699 8.4686669,10.4327496 L5.49478064,7.49377853 C5.22666693,7.22881264 4.77459861,7.30177046 4.60570712,7.63726319 C3.52346384,9.78707404 3.90852532,12.3995028 5.6243179,14.1490818 C7.08614611,15.6385895 9.2178237,16.2032058 11.2031835,15.7006113 L15.2569386,19.8333268 C15.4702181,20.0507606 15.8214742,20.0561583 16.0414912,19.8453828 C16.2615081,19.6346074 16.2669699,19.2874752 16.0536904,19.0700414 L11.7681872,14.7010635 C11.6212742,14.5512889 11.4010927,14.4968682 11.200132,14.5606613 C9.52007122,15.0939813 7.66859322,14.6569414 6.42100715,13.3857325 C5.22555946,12.1667447 4.83010081,10.4282467 5.30616005,8.85824287 L7.68401946,11.2081848 C8.32310408,11.8397662 9.35943223,11.8397662 9.99851685,11.2081848 L11.2294986,9.99165547 C11.8685261,9.3590249 11.8685261,8.33585655 11.2294986,7.70433168 L8.81614139,5.31930872 C10.4756876,4.78972574 12.3392999,5.21355926 13.5975631,6.49676534 C14.8444491,7.76791842 15.2288904,9.61073194 14.6369822,11.2535809 C14.5661695,11.4501223 14.6143544,11.6693491 14.7612909,11.8191476 L19.0467941,16.1881254 C19.2600736,16.4055592 19.6113298,16.4109569 19.8313467,16.2001815 C20.0513637,15.989406 20.0568255,15.6422738 19.843546,15.42484 L15.7898311,11.2921654 Z"/>
    </g>

  </IconContainer>
);

WrenchIcon.iconId = 'wrench';


export default WrenchIcon;
