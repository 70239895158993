import { bool, date, number, object, string } from "yup";

const UserConfigSchema = object({
  activitySortCriteria: string()
    .default("category")
    .oneOf(["category", "cropName", "date", "dateAsc", "fieldName", "status"]),

  bushelNetworkPromptDate: date().default(null).nullable(),

  didOnboarding: bool().default(false),

  fieldSortCriteria: string().default("default").oneOf([
    "asc", // recent rainfall, least to most
    "default", // group name
    "desc", // recent rainfall, most to least
    "fieldname", // field name
  ]),

  fieldViewType: string().default("cards").oneOf(["cards", "type"]),

  mapBounds: object({
    center: string()
      // geographic center of continental US
      .default("39.833333, -98.583333"),
    zoom: number().default(5),
  }),
});

/**
 * @typedef {object} UserConfig
 * @property {('category'|'cropName'|'date'|'dateAsc'|'fieldName'|'status')} activitySortCriteria - Sort criteria for activities, defaults to "category".
 * @property {DateLike} bushelNetworkPromptDate
 * @property {boolean} didOnboarding - Whether the user has completed onboarding, defaults to false.
 * @property {('asc'|'default'|'desc'|'fieldname')} fieldSortCriteria - Sort criteria for fields, defaults to "default".
 * @property {('cards'|'type')} fieldViewType - The view type for fields, defaults to "cards".
 * @property {object} mapBounds - Configuration for map boundaries.
 * @property {string} mapBounds.center - The center of the map bounds, defaults to "39.833333, -98.583333".
 * @property {number} mapBounds.zoom - The zoom level for the map, defaults to 5.
 */

export default UserConfigSchema;
