import React from 'react';
import IconContainer from '../IconContainer';


const CardsLayoutIcon = (props) => (
  <IconContainer {...props}>
    <g id="cardStandard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="cardsStandard">
        <path
          d="M4,12 L9,12 L9,6 L4,6 L4,12 Z M4,19 L9,19 L9,13 L4,13 L4,19 Z M10,19 L15,19 L15,13 L10,13 L10,19 Z M16,19 L21,19 L21,13 L16,13 L16,19 Z M10,12 L15,12 L15,6 L10,6 L10,12 Z M16,6 L16,12 L21,12 L21,6 L16,6 Z"
          id="Shape" fill="currentColor" fillRule="nonzero"></path>
        <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
      </g>
    </g>

  </IconContainer>
);

CardsLayoutIcon.iconId = 'cardsLayout';


export default CardsLayoutIcon;
