import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { border } from 'styled-system';

import context from '../lib/context';
import { getImagePreviewUrl } from '../../fileClient';

const Image = styled.img`
  object-fit: contain;
`;


const ImageCell = styled((props) => {
  const { className, fileId, fileName, onClick, url } = props;
  const { cellSize } = useContext(context);
  const imageSrc = fileId ? getImagePreviewUrl(fileId, { height: cellSize, width: cellSize }) : url;

  return (
    <div className={className} onClick={onClick}>
      <Image alt={fileName} height={cellSize} src={imageSrc} width={cellSize} />
    </div>
  );
})`
  height: var(--cell-size);
  min-height: var(--cell-size);
  min-width: var(--cell-size);
  width: var(--cell-size);
  ${border}
`;

ImageCell.displayName = 'ImageCell';
ImageCell.propTypes = {
  fileId: PropTypes.string,
  fileName: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
};

ImageCell.defaultProps = {
  fileName: null,
  onClick: () => {}
};


export default ImageCell;
