import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { fetch } from "lib/ajax";

import { FormGroup, Input, TextArea } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";

const BuyerModal = ({ data, onClose, onSave }) => {
  const [buyerData, setBuyerData] = useState(data || {});
  const [isSaving, setIsSaving] = useState(false);
  const { city, id, name, notes, phone, state } = buyerData;
  const updateData = (newData) => setBuyerData((buyerData) => ({ ...buyerData, ...newData }));
  const handleSave = () => {
    setIsSaving(true);
    fetch(`v2.0/api/buyers`, {
      body: JSON.stringify(buyerData),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).then((response) =>
      response.json().then((responseData) => {
        onSave("buyers", responseData);
      })
    );
  };

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>{id ? "Edit Buyer" : "New Buyer"}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <FormGroup label="Name">
          <Input defaultValue={name} name="name" onChange={debounce((__, data) => updateData(data), 500)} />
        </FormGroup>

        <FormGroup label="City">
          <Input defaultValue={city} name="city" onChange={debounce((__, data) => updateData(data), 500)} />
        </FormGroup>

        <FormGroup label="State">
          <Input defaultValue={state} name="state" onChange={debounce((__, data) => updateData(data), 500)} />
        </FormGroup>

        <FormGroup label="Phone number">
          <Input defaultValue={phone} name="phone" onChange={debounce((__, data) => updateData(data), 500)} />
        </FormGroup>

        <FormGroup label="Notes">
          <TextArea defaultValue={notes} name="notes" onChange={debounce((__, data) => updateData(data), 500)} />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <ModalActionButtonGroup
          apply={{ action: handleSave, buttonText: "Save Buyer", disabled: !name || isSaving }}
          cancel={{ action: onClose, buttonText: "Cancel" }}
        />
      </ModalFooter>
    </Modal>
  );
};

BuyerModal.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default BuyerModal;
