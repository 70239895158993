import React from 'react';
import IconContainer from '../IconContainer';


const MenuIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M3.659 7.317a.659.659 0 1 1 0-1.317h16.68a.659.659 0 1 1 0 1.317H3.659zm0 5.392a.659.659 0 0 1 0-1.318h16.68a.659.659 0 1 1 0 1.318H3.659zm0 5.391a.659.659 0 0 1 0-1.317h16.68a.659.659 0 1 1 0 1.317H3.659z"
      fill="currentColor"
      fillRule="nonzero"
    />

  </IconContainer>
);

MenuIcon.iconId = 'menu';


export default MenuIcon;
