import React from 'react';
import IconContainer from '../IconContainer';


const LayersIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M11.99,18.54 L4.62,12.81 L3,14.07 L12,21.07 L21,14.07 L19.37,12.8 L11.99,18.54 Z M12,16 L19.36,10.27 L21,9 L12,2 L3,9 L4.63,10.27 L12,16 Z"
        id="Shape" fill="currentColor" fillRule="nonzero"></path>
    </g>

  </IconContainer>
);

LayersIcon.iconId = 'layers';


export default LayersIcon;
