import React from 'react';
import IconContainer from '../IconContainer';


const NewsIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M4.613 19.908c-.116.09-.246.162-.389.214-1.369.499-2.87-1.202-3.698-3.58-.831-2.376-.722-4.665.647-5.163.143-.052.287-.08.433-.086l-.013-.037 4.753-1.733c1.533-.779 3.44-2.648 4.392-4.582.21-.613.567-1.061 1.09-1.252 1.694-.618 4.049 2.815 5.44 6.8 1.375 3.942 1.648 8.18-.048 8.8-.594.216-1.22.042-1.83-.409-1.194-.5-2.565-.766-3.81-.818.29 1.791-.34 3.295-2.302 3.807-1.956.51-3.118-.523-3.689-2.255l-.957.35-.02-.056zm.773-1.837l2.3-.867.7-.252c1.883-.679 5.116-.522 7.657.572l.162.096c.278.217.455.271.519.248.462-.169.229-3.79-.847-6.873-1.084-3.105-3.103-6.05-3.553-5.886-.043.016-.116.108-.228.417-1.261 2.576-3.78 4.908-5.733 5.63l-.723.226-2.294.837c.6.66 1.136 1.625 1.525 2.739.39 1.117.572 2.213.515 3.113zm1.593 1.036c.346 1.075.92 1.565 1.933 1.3 1.125-.293 1.43-1.095 1.175-2.298-.241.028-.47.066-.682.114l-2.426.884zm-3.498-3.642c-.313-.896-.745-1.675-1.19-2.179-.335-.38-.579-.502-.623-.486-.043.016-.157.268-.18.78-.033.677.115 1.56.428 2.455.312.897.744 1.676 1.189 2.18.335.38.58.502.623.486.044-.016.157-.269.182-.78.032-.677-.116-1.56-.43-2.456zm16.718-5.245a.734.734 0 0 1-.943-.457.759.759 0 0 1 .447-.964l2.661-.97a.734.734 0 0 1 .943.458.759.759 0 0 1-.447.964l-2.661.97zm-1.437-4.128a.727.727 0 0 1-1.04.09.766.766 0 0 1-.088-1.064l1.53-1.85a.727.727 0 0 1 1.04-.09c.311.269.35.745.087 1.063l-1.529 1.85zm2.179 8.326a.75.75 0 0 1-.663-.824c.04-.414.4-.718.806-.677l2.25.224a.75.75 0 0 1 .662.824.743.743 0 0 1-.806.677l-2.25-.224z"
      fill="currentColor" fillRule="nonzero"/>

  </IconContainer>
);

NewsIcon.iconId = 'news';


export default NewsIcon;
