import _ from "lodash";
import React from "react";
import styled from "styled-components";

import { useAuth } from "collection/graphql/auth";
import { useSSOContext } from "context/SSOContext";
import { SupportLink } from "modules/billing/lib";
import BaseSSOPageContainer from "modules/sso/components/BaseSSOPageContainer";
import CreateNewAccountRow from "modules/sso/components/CreateNewAccountRow";
import Enterprise from "modules/sso/components/Enterprise";

import { Button } from "components/fl-ui";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const SSOEnterpriseManagement = ({ className, onComplete, onCreate }) => {
  const { logout } = useAuth();
  const { selectedMembership, setSelectedMembership, userMemberships } = useSSOContext();

  const accessibleMemberships = _.reject(userMemberships, ({ role }) => {
    return role.title === "No Access";
  });

  const onNewEnterpriseClick = (event) => {
    event.preventDefault();
    onCreate();
  };

  const onSelect = (selectedMembership) => {
    setSelectedMembership(selectedMembership);
    onComplete();
  };

  return (
    <BaseSSOPageContainer title={selectedMembership ? "" : "Welcome back"}>
      <div className={className}>
        <p className="access-text">Select the farm you&#39;d like to access</p>
        {accessibleMemberships.map((membership) => {
          const selected = selectedMembership?.enterprise?.uuid === membership.enterprise.uuid;
          return <Enterprise membership={membership} key={membership.id} onSelect={onSelect} selected={selected} />;
        })}
        <CreateNewAccountRow onClick={onNewEnterpriseClick} />
        <p className="bottom-text">
          <Button color="secondary" link onClick={logout}>
            Sign out
          </Button>{" "}
          &#x2022; <SupportLink>Support</SupportLink>
        </p>
      </div>
    </BaseSSOPageContainer>
  );
};

export default styled(SSOEnterpriseManagement)`
  .access-text {
    text-align: center;
  }

  .bottom-text {
    color: ${GreyColors.smoke60};
    font-weight: ${Typography.weights.medium};
    margin-top: ${Spacing.xlarge};
    text-align: center;
    a,
    button {
      color: ${GreyColors.smoke60};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;
