import ElevateConsentError from "billing/exceptions/ElevateConsentError";

import { fetch } from "lib/ajax";

export const useElevateConsent = () => {
  /**
   * @return {Promise<void>}
   * @throws {ElevateConsentError}
   */
  const handleElevateConsentSubmit = async ({ token, consentDecision }) => {
    const response = await fetch("/v2.0/users/elevate_plus/consent", {
      body: JSON.stringify({
        token: token,
        consent: consentDecision,
      }),
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    if (!response.ok) {
      const responseBody = await response.json();
      throw new ElevateConsentError(responseBody.message, {
        cause: { response: response },
      });
    }
  };

  return { handleElevateConsentSubmit };
};
