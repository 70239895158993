import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

import { notificationsClient } from "collection/graphql/client";

// Notification subscriptions
const GET_USER_SUBSCRIPTIONS = gql([
  `
  query getUserSubscriptions {
    subscriptions: getUserSubscriptions {
      email
      notificationType
      options
      push
    }
  }
`,
]);

const SET_USER_SUBSCRIPTION = gql([
  `
  mutation setUserNotification($notificationType: String!, $push: Boolean!, $email: Boolean!, $options: JSONObject) {
    subscribe(notificationType: $notificationType, push: $push, email: $email, options: $options) {
      ok
      subscription {
        notificationType
        push
        email
        options
      }
    }
  }
`,
]);

const getUserSubscriptions = graphql(GET_USER_SUBSCRIPTIONS, {
  options: {
    client: notificationsClient,
  },
});

const setUserSubscription = graphql(SET_USER_SUBSCRIPTION, {
  props: ({ mutate }) => ({
    /**
     * Sets a notification option by type
     * @param {Object} variables
     * @param {"imagery"|"news"|"market-prices"|"rainfall"|"work-orders"} variables.type
     * @param {Boolean} variables.push
     * @param {Boolean} variables.email
     * @param {Object} [variables.options={}]
     * @returns {Promise}
     */
    setUserSubscription: (variables) =>
      mutate({
        variables,
        refetchQueries: ["getUserSubscriptions"],
      }),
  }),
  options: {
    client: notificationsClient,
  },
});

export { getUserSubscriptions, setUserSubscription };
