import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { useAuth } from "collection/graphql/auth";
import { getCurrentUserMembership } from "collection/graphql/enterprise/queries";
import { fetch } from "lib/ajax";

const useAccountDeactivation = () => {
  const { data: userMembership } = useQuery(getCurrentUserMembership);
  const currentUserRole = userMembership?.currentMembership?.role?.name;
  const isAdmin = currentUserRole === "admin";
  const { logout } = useAuth();

  const handleError = (error) => {
    Sentry.withScope((scope) => {
      scope.setExtra("logger", "AccountDeactivationLogger");
      Sentry.captureException(error);
    });

    throw error;
  };

  const deactivateUser = async () => {
    const response = await fetch("/v2.0/users/deactivate_enterprise", {
      cache: "no-store",
      headers: { "Content-Type": "application/json" },
      method: "POST",
    });

    if (!isAdmin) {
      throw new Error("Unauthorized");
    }

    if (!response.ok) {
      throw new Error("Unable to deactivate enterprise");
    }

    return response.json();
  };

  return async () => {
    await deactivateUser()
      .then(() => {
        setTimeout(() => logout(), 5000);
      })
      .catch(handleError);
  };
};

export default useAccountDeactivation;
