"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const dismissAllUserNotifications = (0, client_1.gql) `
  mutation dismissAllNotifications {
    dismissAllUserNotifications {
      ok
    }
  }
`;
exports.default = dismissAllUserNotifications;
