import formatDate from 'date-fns/format';
import React, { useEffect, useMemo, useState } from 'react';

import { normalizeDate } from '../utils';


const useInputValue = (value, format) => {
  const valueAsString = useMemo(() => {
    const date = normalizeDate(value);
    return date ? formatDate(date, format) : '';
  }, [value]);
  const [ inputValue, setInputValue ] = useState(valueAsString);

  useEffect(() => {
    if (valueAsString !== inputValue) {
      setInputValue(valueAsString);
    }
  }, [valueAsString]);


  return [ inputValue, setInputValue ];
};


export default useInputValue;
