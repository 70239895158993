import { pendoTrack, PENDO_TRACK_EVENT } from "lib/pendo";

/**
 * Tracks when a cash contract is created or edited
 * @param {boolean} marketedCropModified if the existing marketed crop was modified during this save (always true for contract create)
 * @param {"add_contract"|"edit_contract"|"kebab_menu"} sourceCTA call to action of how the user got to the save form
 */
export function trackCashContractSave(marketedCropModified, sourceCTA) {
  pendoTrack(PENDO_TRACK_EVENT.CASH_CONTRACT_SAVE, {
    MARKETED_CROP_MODIFIED: marketedCropModified,
    SOURCE_CTA: sourceCTA,
  });
}

/**
 * Tracks when we determine a mobile app user is on an old version of the app and we show them UI about updating
 * @param {"DEPRECATED"|"EXPIRED"} state
 */
export function trackMobileForcedAppUpdatePresentation(appState) {
  pendoTrack(PENDO_TRACK_EVENT.MOBILE_FORCED_APP_UPDATE_PRESENTATION, {
    APP_STATE: appState,
  });
}

/**
 * Tracks when a user takes action from a forced app update prompt and what the resulting action was
 * @param {"DISMISS"|"STORE"} result
 */
export function trackMobileForcedAppUpdateResolution(result) {
  pendoTrack(PENDO_TRACK_EVENT.MOBILE_FORCED_APP_UPDATE_RESOLUTION, {
    RESULT: result,
  });
}

/**
 * Tracks when a marketing crop is created or edited
 */
export function trackMarketingCropSave() {
  pendoTrack(PENDO_TRACK_EVENT.MARKETING_CROP_SAVE);
}

export function trackTestEvent(timestamp) {
  pendoTrack(PENDO_TRACK_EVENT.TEST_EVENT, {
    TIMESTAMP: timestamp,
  });
}

export function trackSubscriptionBusinessTrialClose() {
  pendoTrack(PENDO_TRACK_EVENT.SUBSCRIPTION_BUSINESS_TRIAL_CLOSE, {});
}

export function trackSubscriptionBusinessTrialPresentation() {
  pendoTrack(PENDO_TRACK_EVENT.SUBSCRIPTION_BUSINESS_TRIAL_PRESENTATION, {});
}

export function trackSubscriptionBusinessTrialStart() {
  pendoTrack(PENDO_TRACK_EVENT.SUBSCRIPTION_BUSINESS_TRIAL_START, {});
}
