import React from 'react';
import IconContainer from '../IconContainer';


const NotesIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M8.93 11.71c-2.242.948-4.376.987-5.041-.288-.244-.468-.22-1 .01-1.537.19-1.106.136-2.373-.107-3.516-.725-.075-1.31-.384-1.61-.959-.65-1.246.518-2.828 2.357-3.751 1.84-.924 3.83-.928 4.479.318.292.561.216 1.19-.123 1.796.864.935 1.966 1.774 2.991 2.236.495.148.884.428 1.102.845.663 1.273-.614 2.946-2.703 4.175l1.636 3.085a.741.741 0 0 1-.325 1.009.769.769 0 0 1-1.027-.318l-1.64-3.095zm-.064-1.62a.777.777 0 0 1 .108-.055c1.767-.902 2.838-2.152 2.657-2.5-.011-.021-.061-.057-.28-.13C10.1 6.85 8.878 5.944 7.902 4.923c-.36.3-.78.574-1.242.806-.446.224-.9.394-1.343.505.257 1.306.3 2.731.066 4.002l-.059.181c-.089.19-.097.292-.08.324.182.349 1.841.227 3.621-.652zm5.278 12.415l.318-5.925c.02-.38.33-.685.717-.707l5.297-.297v-5.318h-5.415a.755.755 0 0 1-.762-.748c0-.413.341-.748.762-.748h6.177c.42 0 .762.335.762.748v8.202a.74.74 0 0 1-.198.503l-5.133 5.54a.768.768 0 0 1-.564.245H5.661a.755.755 0 0 1-.762-.748v-8.564c0-.413.341-.748.762-.748.42 0 .762.335.762.748v7.817h7.72zm1.525 0h.077l.041-.02.107-.116-1.084.083.054.053h.764l4.848-5.391v.173l-.074.192v-1.192l.074.068v.72l-4.528.253-.279 5.177zM7.661 2.658c-.139-.266-1.24-.264-2.428.332-1.187.596-1.834 1.472-1.696 1.738.14.268 1.242.266 2.43-.33 1.187-.596 1.834-1.472 1.694-1.74z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

NotesIcon.iconId = 'notes';


export default NotesIcon;
