import { css } from "aphrodite";
import MarketedCropFieldInput from "marketing/components/MarketedCropFieldInput";
import ProductionCosts from "marketing/forms/MarketedCropAddEdit/ProductionCosts";
import styles from "marketing/styles/formStyles";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import BasisInput from "modules/marketing/components/BasisInput";
import FieldInfo from "modules/marketing/forms/MarketedCropAddEdit/FieldInfo";

import CommodityChooser from "components/commodity/CommodityChooser";
import { Checkbox, FormGroup, Input } from "components/fl-ui/Form";
import { Icon } from "components/fl-ui/Icons";
import Tooltip from "components/fl-ui/Tooltip";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const BudgetLabel = () => (
  <span>
    Budget price
    <Tooltip message="The estimated value of unsold crop">
      <Icon className={css(styles.helpIcon)} icon="help" />
    </Tooltip>
  </span>
);

const CropFormFieldset = ({ data, error, isNew, onChange, showCreateFieldCrop, touchedFields }) => {
  const {
    acresPlanted,
    averageYield,
    commodity,
    createFieldCrops,
    disableExchangePricing,
    fields,
    harvestBasis,
    harvestPrice,
    id,
    itemizedProductionCosts,
    name,
    productionCost,
    productionCostUnit,
    useItemizedProductionCosts,
    year,
  } = data;

  const hideExchangePricingToggle = !commodity?.marketSymbol || (disableExchangePricing && id);
  const showBasisInput = commodity?.marketSymbol && !disableExchangePricing;

  const handleInputChange = ({ target }) => {
    const checkboxes = ["createFieldCrops", "disableExchangePricing"];
    const { checked, name, value: newValue } = target;
    const value = checkboxes.includes(name) ? checked : newValue;
    onChange(name, value);
  };

  return (
    <>
      <Row>
        <Col md={6} xs={12}>
          <FormGroup label="Name">
            <Input name="name" onChange={handleInputChange} size="large" type="text" value={name} />
          </FormGroup>
        </Col>

        {isNew && (
          <Col md={6} xs={12}>
            <FormGroup label="Commodity">
              <CommodityChooser
                commodityId={commodity?.id}
                onChange={(commodity) => onChange("commodity", commodity)}
              />
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        <Col xs={12}>
          <FormGroup
            label={
              <>
                Fields (optional)
                <br />
                <FieldInfo
                  className={css(styles.fieldsSummary)}
                  fields={fields}
                  commodity={commodity}
                  cropYear={year}
                />
              </>
            }
          >
            <MarketedCropFieldInput
              onChange={(fields) => onChange("fields", fields)}
              value={fields}
              year={year}
              commodity={commodity}
            />
          </FormGroup>
        </Col>
      </Row>

      {showCreateFieldCrop && (
        <Row>
          <Col xs={12}>
            <label>
              <Checkbox checked={!!createFieldCrops} name="createFieldCrops" onChange={handleInputChange} />
              Create crops on fields
            </label>
          </Col>
        </Row>
      )}

      <Row className={css(showCreateFieldCrop && styles.regularMarginTop)}>
        <Col md={6} xs={12}>
          <FormGroup
            error={touchedFields?.includes("acresPlanted") && error?.acresPlanted?.msg}
            inputId="total-acres"
            label="Crop acres"
          >
            <Input
              name="acresPlanted"
              onChange={handleInputChange}
              placeholder={0}
              size="large"
              suffix="ac"
              type="number"
              value={acresPlanted}
            />
          </FormGroup>
        </Col>
        <Col md={6} xs={12}>
          <FormGroup
            error={touchedFields?.includes("averageYield") && error?.averageYield?.msg}
            inputId="average-yield"
            label="Average yield"
          >
            <Input
              name="averageYield"
              onChange={handleInputChange}
              placeholder="0"
              size="large"
              suffix={<CommodityYieldUnit commodity={commodity} per="acre" />}
              type="number"
              value={averageYield}
            />
          </FormGroup>
        </Col>
      </Row>

      {!hideExchangePricingToggle && (
        <Row>
          <Col xs={12}>
            <label>
              <Checkbox checked={!!disableExchangePricing} name="disableExchangePricing" onChange={handleInputChange} />
              Use budget price instead of dynamic futures price
            </label>
          </Col>
        </Row>
      )}

      {commodity && (
        <Row>
          <Col className={css(styles.regularMarginTop)} md={6} xs={12}>
            {showBasisInput ? (
              <FormGroup
                error={touchedFields?.includes("harvestBasis") && error?.harvestBasis?.msg}
                label="Estimated Basis"
              >
                <BasisInput
                  onChange={(event) => onChange("harvestBasis", event.target.value)}
                  suffix={<CommodityYieldUnit commodity={commodity} per="unit" />}
                  value={harvestBasis}
                />
              </FormGroup>
            ) : (
              <FormGroup
                error={touchedFields?.includes("harvestPrice") && error?.harvestPrice?.msg}
                inputId="budget-price"
                label={<BudgetLabel />}
              >
                <Input
                  name="harvestPrice"
                  onChange={handleInputChange}
                  placeholder="0"
                  prefix="$"
                  size="large"
                  suffix={<CommodityYieldUnit commodity={commodity} per="unit" />}
                  type="number"
                  value={harvestPrice || ""}
                />
              </FormGroup>
            )}
          </Col>
        </Row>
      )}

      <Row>
        <Col xs>
          <ProductionCosts
            error={error}
            itemizedProductionCosts={itemizedProductionCosts}
            onChange={onChange}
            productionCost={productionCost}
            productionCostUnit={productionCostUnit}
            useItemizedProductionCosts={useItemizedProductionCosts}
          />
        </Col>
      </Row>
    </>
  );
};

CropFormFieldset.propTypes = {
  data: PropTypes.object,
  error: PropTypes.object,
  isNew: PropTypes.bool,
  onChange: PropTypes.func,
  showCreateFieldCrop: PropTypes.bool,
  touchedFields: PropTypes.arrayOf(PropTypes.string),
};

CropFormFieldset.defaultProps = {
  data: {},
  onChange: () => {},
};

export default CropFormFieldset;
