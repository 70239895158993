import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';

import BaseElement from './BaseElement';
import useTheme from '../theme/useTheme.js';


const MediaAware = (props) => {
  const { desktop, mobile, print, screen, ...rest } = props;
  const desktopBreakpoint = useTheme().breakpoints.desktop;
  const isMobile = !useMediaQuery(`(min-width: ${desktopBreakpoint})`);
  const isPrint = useMediaQuery('print');

  let shouldShow = true;
  if (desktop ^ mobile) {
    shouldShow = isMobile ? !!mobile : !!desktop;
  } else if (print ^ screen) {
    shouldShow = isPrint ? !!print : !!screen;
  }


  return shouldShow ? <BaseElement {...rest} /> : null;
};

MediaAware.propTypes = {
  // viewport widths
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  // media type
  print: PropTypes.bool,
  screen: PropTypes.bool,
  ...BaseElement.propTypes
};

MediaAware.defaultProps = {
  desktop: false,
  mobile: false,
  print: false,
  screen: false
};

MediaAware.displayName = 'MediaAware';


export default MediaAware;
