import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { border, color, space, typography, variant } from 'styled-system';

import Icon from '../Icon';


const ButtonUI = styled.button`
  ${border}
  ${color}
  ${space}
  ${typography}
  ${variant({scale: 'buttons'})}

  align-items: center;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  display: flex;
  ${props => props.addChildMargin && `
    > :first-child {
      margin-right: .5rem;
    }
  `}


  ${props => {
    const conditions = [
      props.variant === 'link',
      !props.disabled,
      props.href !== null
    ];
  
    if (conditions.every((result) => !!result)) {
      return `
        &:hover {
          text-decoration: underline;
        }
      `;
    }
  }}
`;



const Button = ({ children, icon, ...props }) => {
  const hasIconAndText = !!(icon && children);
  const px = icon && !hasIconAndText && !props.px ? props.py : 3; // make the padding uniform if the button is icon only

  return (
    <ButtonUI {...props} addChildMargin={hasIconAndText} px={px}>
      {icon && (
        <Icon icon={icon} />
      )}
      {children}
    </ButtonUI>
  )
};

Button.propTypes = {
  disabled: PropTypes.bool,
  fontSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  fontWeight: PropTypes.oneOf(['bold', 'normal']),
  href: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'dark', 'light', 'link'])
};

Button.defaultProps = {
  disabled: false,
  fontSize: 'md',
  fontWeight: 'bold',
  href: null,
  onClick: () => {},
  // px: 3,
  py: 2,
  type: 'button',
  variant: 'primary'
};


export default Button;
