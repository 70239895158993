import React from 'react';
import IconContainer from '../IconContainer';


const ListLayoutIcon = (props) => (
  <IconContainer {...props}>
    <g id="listCompact" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Shape">
        <polygon points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M3,15 L21,15 L21,13 L3,13 L3,15 Z M3,19 L21,19 L21,17 L3,17 L3,19 Z M3,11 L21,11 L21,9 L3,9 L3,11 Z M3,5 L3,7 L21,7 L21,5 L3,5 Z"
          fill="currentColor" fillRule="nonzero"></path>
      </g>
    </g>

  </IconContainer>
);

ListLayoutIcon.iconId = 'listLayout';


export default ListLayoutIcon;
