import React from 'react';
import IconContainer from '../IconContainer';


const DismissAllIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M4.73677127,8.71993808 C4.32986373,8.71993808 4,8.38358596 4,7.96867459 C4,7.55376322 4.32986373,7.2174111 4.73677127,7.2174111 L14.4728696,7.2174111 C14.8797772,7.2174111 15.2096409,7.55376322 15.2096409,7.96867459 C15.2096409,8.38358596 14.8797772,8.71993808 14.4728696,8.71993808 L4.73677127,8.71993808 Z M4.73677127,5.50252698 C4.32986373,5.50252698 4,5.16617486 4,4.75126349 C4,4.33635212 4.32986373,4 4.73677127,4 L16.4728696,4 C16.8797772,4 17.2096409,4.33635212 17.2096409,4.75126349 C17.2096409,5.16617486 16.8797772,5.50252698 16.4728696,5.50252698 L4.73677127,5.50252698 Z M4.73677127,11.8090334 C4.32986373,11.8090334 4,11.4726813 4,11.0577699 C4,10.6428585 4.32986373,10.3065064 4.73677127,10.3065064 L12.2801941,10.3065064 C12.6871016,10.3065064 13.0169654,10.6428585 13.0169654,11.0577699 C13.0169654,11.4726813 12.6871016,11.8090334 12.2801941,11.8090334 L4.73677127,11.8090334 Z M5.25749538,14.1212723 L10.1888574,18.0915617 L19.6771449,5.29259542 C19.9298329,4.96014533 20.3953758,4.90240396 20.7169642,5.16362648 C21.0385526,5.42484899 21.0944074,5.90611573 20.8417195,6.23856582 L10.8442623,19.7074236 C10.5722861,20.0652503 10.0602093,20.1002618 9.74501627,19.7825807 L4.22357789,15.2175569 C3.93073799,14.9224053 3.92479466,14.4377261 4.21030309,14.1349954 C4.49581152,13.8322648 4.96465548,13.8261207 5.25749538,14.1212723 Z"
      fill="currentColor"
      fillRule="nonzero"
    />

  </IconContainer>
);

DismissAllIcon.iconId = 'dismissAll';


export default DismissAllIcon;
