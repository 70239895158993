import React from 'react';
import IconContainer from '../IconContainer';


const ImplementsIcon = (props) => (
  <IconContainer {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z"/>
      <path
        d="M16.097 6.74a6.552 6.552 0 0 1 1.84 1.413.539.539 0 0 1-.047.774.573.573 0 0 1-.794-.047 5.43 5.43 0 0 0-1.552-1.185 5.507 5.507 0 0 0-2.506-.598c-2.99 0-5.414 2.363-5.414 5.278 0 2.915 2.424 5.278 5.414 5.278.765 0 1.507-.154 2.19-.449a.568.568 0 0 1 .741.279.543.543 0 0 1-.286.723 6.653 6.653 0 0 1-2.645.544c-2.944 0-5.434-1.897-6.254-4.506H3.563A.556.556 0 0 1 3 13.696c0-.303.252-.549.563-.549h2.983a6.279 6.279 0 0 1-.01-1.462H3.564A.556.556 0 0 1 3 11.137c0-.303.252-.548.563-.548h3.196C7.55 7.938 10.063 6 13.038 6a6.655 6.655 0 0 1 3.059.74zm-3.105 2.057a3.72 3.72 0 0 1 3.196 1.792h4.25c.31 0 .562.245.562.548a.556.556 0 0 1-.563.548h-4.59a.564.564 0 0 1-.51-.319 2.585 2.585 0 0 0-2.345-1.473c-1.429 0-2.587 1.13-2.587 2.523 0 1.393 1.158 2.522 2.587 2.522a2.585 2.585 0 0 0 2.345-1.472.564.564 0 0 1 .51-.319h4.59c.311 0 .563.246.563.549a.556.556 0 0 1-.563.548h-4.25a3.72 3.72 0 0 1-3.195 1.79c-2.05 0-3.712-1.62-3.712-3.618 0-1.999 1.662-3.62 3.712-3.62z"
        fill="currentColor" fillRule="nonzero"/>
    </g>
  </IconContainer>
);

ImplementsIcon.iconId = 'implements';


export default ImplementsIcon;
