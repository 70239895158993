import leaflet from "leaflet";
import render from "map/templates";
import numeral from "numeral";
import React from "react";
import { createRoot } from "react-dom/client";

import { readCurrentCropYear } from "hooks/useCurrentCropYear";

import RainfallIndicator from "fields/components/RainfallIndicator";

const closePopup = function (event) {
  if ((event.relatedTarget != null ? event.relatedTarget.nodeName : undefined) === "svg") {
    this._close();
  }
};

export default leaflet.Popup.extend({
  options: {
    minWidth: 200,
    maxWidth: 250,
    maxHeight: null,
    autoPan: false,
    closeButton: false,
    offset: [0, -9],
    autoPanPadding: [5, 5],
    keepInView: false,
    className: "map-popup",
    zoomAnimation: true,
  },

  onAdd() {
    leaflet.Popup.prototype.onAdd.apply(this, arguments);

    this.render();

    const rainfallEl = this._container.querySelector(".js-rainfall");
    this.root = createRoot(rainfallEl);

    this.showRainfall();

    const add = this._container.querySelector(".js-add-crop");

    if (add) {
      leaflet.DomEvent.on(add, "click", this.addCrop, this);
    }
    leaflet.DomEvent.on(this._container, "mouseout", closePopup, this);
    return this._container;
  },

  onRemove() {
    this.removeRainfall();
    const add = this._container.querySelector(".js-add-crop");

    if (add) {
      leaflet.DomEvent.off(add, "click", this.addCrop);
    }
    leaflet.DomEvent.off(this._container, "mouseout", closePopup);

    leaflet.Popup.prototype.onRemove.apply(this, arguments);
  },

  addCrop(e) {
    e.stopPropagation();
    this.createCrop(this.field.id, { cropYear: readCurrentCropYear() });
  },

  render() {
    const commodities = this.fieldCrops.map(({ commodity }) => commodity.name || "Unknown").join(", ");
    const data = {
      field: {
        ...this.field,
        acreage: +numeral(this.field.acreage).format("0.00"),
      },
      commodities,
      hasCommodities: !!commodities.length,
    };

    this.setContent(render("layer.field.popup", data));

    return this;
  },

  removeRainfall() {
    return this.root.unmount();
  },

  showRainfall() {
    const rainfall = <RainfallIndicator fieldId={this.field.id} disableTip />;
    this.root.render(rainfall);
  },
});
