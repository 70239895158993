import { useQuery } from "@apollo/client";
import { init, setUser } from "@sentry/react";
import { getPlatform } from "mobile/mobileManager";
import { useEffect } from "react";

import { useAuth } from "collection/graphql/auth";
import { getCurrentUserMembership } from "collection/graphql/enterprise/queries";
import useWindow from "hooks/useWindow";

const isEnabled = !!process.env.SENTRY_DSN;

const useSentrySetup = () => {
  const { currentUser } = useAuth();
  const win = useWindow();
  const platform = getPlatform();

  const clientVersion = process.env.CLIENT_VERSION || "dev";

  const { data: userMembership } = useQuery(getCurrentUserMembership);
  const currentMembershipId = userMembership?.currentMembership?.enterprise?.id;

  useEffect(() => {
    if (isEnabled) {
      init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.SENTRY_ENVIRONMENT || win.location.hostname,
        normalizeDepth: 10,
        release: clientVersion,
      });
    }
  }, []);

  useEffect(() => {
    if (isEnabled && currentUser) {
      setUser({
        clientVersion,
        id: currentUser.id,
        email: currentUser.email,
        enterpriseId: currentMembershipId,
        fullName: currentUser.name,
        ip_address: "{{auto}}",
        platform: platform,
        userAgent: win.navigator.userAgent,
      });
    }
  }, [currentUser, currentMembershipId]);
};

export default useSentrySetup;
