import React from 'react';
import IconContainer from '../IconContainer';


const LockIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M16.88,10.4 L16.88,8 C16.88,5.76 14.64,4 12.4,4 C10.16,4 7.92,5.76 7.92,8 L7.92,10.4 C6.96,10.4 6,11.36 6,12.32 L6,17.92 C6,19.04 6.96,20 7.92,20 L16.72,20 C17.84,20 18.8,19.04 18.8,18.08 L18.8,12.48 C18.8,11.36 17.84,10.4 16.88,10.4 Z M14.8,10.4 L10,10.4 L10,8 C10,6.72 11.12,5.92 12.4,5.92 C13.68,5.92 14.8,6.72 14.8,8 L14.8,10.4 Z"
          id="Shape" fill="currentColor" fillRule="nonzero"></path>
      </g>
    </g>

  </IconContainer>
);

LockIcon.iconId = 'lock';


export default LockIcon;
