import { BaseElement } from "components/fl-ui/common";
import { captureException, ErrorBoundary, withScope } from "@sentry/react";
import MobilePaymentError from "billing/exceptions/MobilePaymentError";
import { Section } from "demo/components";
import React, { useEffect, useState } from "react";

import { Button } from "components/fl-ui";

const isSentryEnabled = !!process.env.SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT;

const ErrorTestButton = ({ onClick: triggerError, text, ...rest }) => {
  const [state, setState] = useState("pending");

  const buttonProps = {
    color: state === "pending" ? "secondary" : "danger",
    children: state === "pending" ? text : "Triggered",
    onClick: (event) => {
      setState("triggering");
      const error = triggerError(event);
      withScope((scope) => {
        captureException(error, error.getContext());
        setTimeout(() => setState("pending"), 2000);
      });
    },
    ...rest,
  };

  return <Button {...buttonProps} />;
};

const ErrorProneComponent = () => {
  useEffect(() => {
    throw new Error("Test Error in useEffect");
  }, []);

  return <p>This will never be reached</p>;
};

const SentryTestDemo = (props) => {
  const [showLifecycleErrorComponent, setShowLifecycleErrorComponent] = useState(false);

  if (!isSentryEnabled) {
    return <div>Sentry has not been configured in this environment. No tests are available.</div>;
  }

  return (
    <div>
      <div>
        <strong>SENTRY_ENVIRONMENT</strong>: <code>{SENTRY_ENVIRONMENT}</code>
        <br />
        <br />
      </div>

      <Section title="Custom exceptions">
        <BaseElement display="flex" flexDirection="column" gap="8px">
          <ErrorTestButton
            onClick={() => {
              throw new MobilePaymentError("MobilePaymentError test", {
                order: { products: ["farmlogs_essentials"] },
                response: {},
              });
            }}
            text="Trigger MobilePaymentError"
          />

          <ErrorBoundary fallback={<p>An error has occurred</p>}>
            <Button
              onClick={() => {
                throw new Error("OnClick Error");
              }}
            >
              Basic onClick Error
            </Button>

            <Button onClick={() => setShowLifecycleErrorComponent(true)}>Show Lifecycle Error Component</Button>

            {showLifecycleErrorComponent && <ErrorProneComponent />}
          </ErrorBoundary>
        </BaseElement>
      </Section>
    </div>
  );
};

export default SentryTestDemo;
