import { fileClient } from "components/fl-ui/common";
import { browserOpenUrl, isNative } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useFullScreenHandle } from "react-full-screen";

import useFieldAttachments from "collection/graphql/fields/hooks/useFieldAttachments";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useViewType from "hooks/useViewType";
import { TableBody } from "modules/fields/components/TableBody";

import FileAttachmentsBlankStatePage from "components/advertisements/pages/FileAttachmentsBlankStatePage";
import AttachmentList from "components/field/AttachmentList";
import FilePopoverContainer from "components/field/FilePopoverContainer";
import { getSortedAttachments, getSrcURL } from "components/field/utils";
import Filters from "components/fl-ui/Filters/Filters";
import FullscreenContainer from "components/fl-ui/Layout/FullscreenContainer";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { AttachmentTable, AttachmentThumbnail, ButtonLink } from "fields/components/styledComponents";

const sortOptions = [
  { key: 1, label: "Date (New to Old)", value: "date" },
  { key: 2, label: "Name", value: "name" },
  { key: 3, label: "User Uploaded", value: "user" },
];

const AttachmentRow = ({ file, isUpdating, showPreview, updateAttachments }) => {
  const [hasPreview, setHasPreview] = useState(true);
  const handleError = ({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = "/images/app_icons/bushel-farm-icon-alt.svg";
    setHasPreview(false);
  };

  return (
    <tr>
      <td>
        <AttachmentThumbnail onError={handleError} src={getSrcURL(file.filepickerId)} />
      </td>
      <td>
        <ButtonLink color="primary" disabled lightWeight link>
          {file.name}
        </ButtonLink>
      </td>
      <td>{file.date}</td>
      <td>{file.uploadedBy}</td>
      <td>{file.size ?? "--"}</td>
      <td>
        <FilePopoverContainer
          file={file}
          handlePreview={() => showPreview(file)}
          hasPreview={hasPreview}
          isUpdating={isUpdating}
          updateAttachments={updateAttachments}
        />
      </td>
    </tr>
  );
};

const FieldAttachments = ({ fieldId }) => {
  const isMobileView = useViewType() === "mobile";
  const [selectedFile, setSelectedFile] = useState(null);
  const [sortCriteria, setSortCriteria] = useState("date");
  const { attachments, isUpdating, loading, updateAttachments } = useFieldAttachments(fieldId);
  const sortedAttachments = getSortedAttachments(attachments, sortCriteria);
  const handle = useFullScreenHandle();
  const hasAttachments = useEnterpriseFeature("file_storage");

  const showPreview = () => {
    isNative() ? browserOpenUrl(fileClient.getPreviewUrl(selectedFile?.filepickerId)) : handle.enter();
  };

  const handlePreview = (file) => {
    setSelectedFile(file);
    showPreview();
  };

  if (!hasAttachments && !loading && !sortedAttachments?.length) {
    return <FileAttachmentsBlankStatePage />;
  }

  return (
    <LoadingWrapper isLoading={loading}>
      {sortedAttachments?.length > 0 && (
        <>
          <Filters setSortCriteria={setSortCriteria} sortCriteria={sortCriteria} sortOptions={sortOptions} />

          {isMobileView ? (
            <AttachmentList
              fileList={sortedAttachments}
              isUpdating={isUpdating}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              showPreview={showPreview}
              updateAttachments={updateAttachments}
            />
          ) : (
            <AttachmentTable>
              <TableBody bordered borderTop>
                <tr>
                  <th />
                  <th>NAME</th>
                  <th>UPLOAD DATE</th>
                  <th>UPLOADED BY</th>
                  <th>Size</th>
                  <th />
                </tr>
                {sortedAttachments.map((file) => (
                  <AttachmentRow
                    file={file}
                    isUpdating={isUpdating}
                    key={file.filepickerId}
                    showPreview={handlePreview}
                    updateAttachments={updateAttachments}
                  />
                ))}
              </TableBody>
            </AttachmentTable>
          )}

          <FullscreenContainer handle={handle}>
            <iframe
              src={fileClient.getPreviewUrl(selectedFile?.filepickerId)}
              style={{ height: "100vh", width: "100%" }}
            />
          </FullscreenContainer>
        </>
      )}
    </LoadingWrapper>
  );
};

FieldAttachments.propTypes = {
  fieldId: PropTypes.number.isRequired,
};

export default FieldAttachments;
