import React from 'react';
import IconContainer from '../IconContainer';


const CircleAddIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M12.55 11.268h2.809a.64.64 0 0 1 0 1.281h-2.81v2.81a.64.64 0 1 1-1.28 0v-2.81h-2.81a.64.64 0 1 1 0-1.28h2.81v-2.81a.64.64 0 0 1 1.28 0v2.81zM20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-1.28 0a6.72 6.72 0 1 0-13.438 0 6.72 6.72 0 0 0 13.437 0z"
      fill="currentColor" fillRule="nonzero"/>

  </IconContainer>
);

CircleAddIcon.iconId = 'circleAdd';


export default CircleAddIcon;
