import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo, useState } from 'react';
import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';

import Icon from '../Icon';
import Input from '../Form/Input';
import { supportsDateInput as dateInputCheck } from './utils';


const DateInputIcon = styled(({ className, onClick }) => (
  <span className={className} onClick={onClick}>
    <Icon icon="calendar" ml={1} />
  </span>
))`
  border: 1px solid ${themeGet('colors.input.borderColor')};
  border-left-width: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: ${themeGet('colors.input.placeholderColor')};
  cursor: pointer;
  padding: 6px 5px 4px;
  
  &.disabled {
    background-color: ${themeGet('colors.input.disabledBackground')};
    cursor: default;
  }
  
  &.focus {
    border-color: ${themeGet('colors.input.focusBorderColor')};
  }
`;


const DateInput = styled(forwardRef((props, ref) => {
  const { className, hideIcon, onChange, ...rest } = props;
  const supportsDateInput = useMemo(() => dateInputCheck(), []);
  const [ isFocused, setIsFocused ] = useState(rest.autoFocus === true);

  const handleChange = (event) => onChange(event.target.value);
  const handleClick = !supportsDateInput ? () => {} : (event) => { event.preventDefault(); };
  const handleBlur = () => setIsFocused(false);
  const handleFocus = () => setIsFocused(true);
  const handleIconClick = () => {
    if (!rest.disabled) {
      ref.current.focus();
    }
  };

  return (
    <div className={className} onBlur={handleBlur} onFocus={handleFocus}>
      <Input
        {...rest}
        className={!hideIcon ? 'withIcon' : undefined}
        maxLength={10}
        name="date"
        onChange={handleChange}
        onClick={handleClick}
        ref={ref}
        type="text"
      />

      {!hideIcon && (
        <DateInputIcon
          className={cx({ disabled: rest.disabled, focus: isFocused })}
          onClick={handleIconClick}
        />
      )}
    </div>
  );
}))`
  align-items: center;
  display: flex;
  
  > input.withIcon {
    border-right: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
`;


DateInput.propTypes = {
  disabled: PropTypes.bool,
  format: PropTypes.string,
  hideIcon: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

DateInput.defaultProps = {
  disabled: false,
  hideIcon: false,
  format: 'MM/dd/yyyy',
  placeholder: 'MM/DD/YYYY'
};


export default DateInput;
