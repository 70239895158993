import { useMutation } from "@apollo/client";
import { css } from "aphrodite";
import _ from "lodash";
import CropFormFieldset from "marketing/forms/MarketedCropAddEdit/CropFormFieldset";
import { formStyles as styles } from "marketing/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col } from "react-styled-flexboxgrid";

import { marketingClient } from "collection/graphql/client";
import { BULK_CREATE_FIELD_CROPS } from "collection/graphql/fields/mutations";
import { withCreateMarketedCrop, withEditMarketedCrop } from "collection/graphql/marketing";
import App from "layout/app";
import { trackMarketingCropSave } from "lib/metrics/events/trackEvents";
import { calculateAcreage } from "modules/marketing/forms/MarketedCropAddEdit/FieldInfo";
import {
  buildDefaultMarketedCrop,
  getFieldsWithoutSelectedCommodity,
  sanitizeMarketedCrop,
} from "modules/marketing/forms/MarketedCropAddEdit/lib";
import cropFormValidator from "modules/marketing/validators/CropForm";

import { Button } from "components/fl-ui";
import { Form } from "components/fl-ui/Form/index";

const CropForm = ({
  createMarketedCrop,
  editMarketedCrop,
  marketedCrop,
  onCancel,
  onSaveFailure,
  onSaveSuccess,
  marketedCrops = [],
}) => {
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(!marketedCrop?.id);
  const [formData, setFormData] = useState(buildDefaultMarketedCrop(marketedCrop));
  const [hasAcreageBeenEdited, setHasAcreageBeenEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [touchedFields, setTouchedFields] = useState([]);
  const [bulkCreateFieldCrops] = useMutation(BULK_CREATE_FIELD_CROPS, {
    client: marketingClient,
    refetchQueries: ["GetFieldCrops"],
  });
  const isNew = !marketedCrop?.id;
  const fieldsWithoutCommodity = getFieldsWithoutSelectedCommodity(formData);

  const onChange = (name, value) => {
    const newFormData = { ...formData, [name]: value };
    if (isNew && name === "fields" && !hasAcreageBeenEdited) {
      newFormData.acresPlanted = calculateAcreage(newFormData.fields);
    }

    const errors = cropFormValidator.validate(newFormData, { marketedCrops });
    setError(errors);
    setDisabled(!_.isEmpty(errors));
    setFormData(newFormData);
    /*
     * we do not need to track whether harvestBasis has been touched. when it contains an invalid ephemeral value like "." and
     * is blurred, we reset the input value to be "".
     */
    if (name !== "harvestBasis") {
      setTouchedFields(_.uniq([...touchedFields, name]));
    }

    if (name === "acresPlanted") {
      setHasAcreageBeenEdited(true);
    }
  };

  const onSave = async () => {
    setIsSaving(true);
    const { id, ...input } = sanitizeMarketedCrop(formData);

    if (formData.createFieldCrops && fieldsWithoutCommodity.length) {
      const crops = fieldsWithoutCommodity.map(({ acreage, id }) => ({
        acreage,
        commodityId: input.commodityId,
        cropYear: input.year,
        fieldId: id,
      }));

      try {
        await bulkCreateFieldCrops({ variables: { crops } });
      } catch (err) {
        App.notify("Some field crops were not successfully created");
      }
    }

    try {
      const result = await (id ? editMarketedCrop({ id, input }) : createMarketedCrop(input));
      setIsSaving(false);
      onSaveSuccess(result);
      trackMarketingCropSave();
    } catch (err) {
      setIsSaving(false);
      onSaveFailure(err);
    }
  };

  return (
    <Form data-cy="cropForm" onSubmit={() => {}}>
      <Row>
        <Col xs>
          <CropFormFieldset
            data={formData}
            error={error}
            isNew={isNew}
            touchedFields={touchedFields}
            onChange={onChange}
            showCreateFieldCrop={!!fieldsWithoutCommodity.length}
          />

          <div className={css(styles.formButtonContainer)}>
            <Button className={css(styles.secondaryButton)} color="primary" data-cy="back" link onClick={onCancel}>
              Cancel
            </Button>

            <Button color="primary" data-cy="next" disabled={disabled || isSaving} onClick={onSave}>
              {isSaving ? "Saving..." : "Save"}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

CropForm.propTypes = {
  createMarketedCrop: PropTypes.func.isRequired,
  editMarketedCrop: PropTypes.func.isRequired,
  marketedCrop: PropTypes.object.isRequired,
  marketedCrops: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func,
  onSaveFailure: PropTypes.func,
  onSaveSuccess: PropTypes.func,
};

CropForm.defaultProps = {
  onCancel: () => {},
  onSaveFailure: () => {},
  onSaveSuccess: () => {},
};

export default _.flowRight(withEditMarketedCrop, withCreateMarketedCrop)(CropForm);
