"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAppRedirectUrl = getAppRedirectUrl;
function getAppRedirectUrl(urlString) {
    const url = new URL(urlString);
    // /app/activity?id=1234
    if (url.pathname === "/app/activity") {
        const id = url.searchParams.get("id");
        const hash = "/#/activity";
        if (id) {
            return new URL(hash + "/" + id, url.origin);
        }
        else {
            return new URL(hash, url.origin);
        }
    }
    // /app/password/reset/passwordToken?email=jdoe%40example.com
    if (url.pathname.startsWith("/app/password/reset/")) {
        const hash = url.pathname.replace(/^\/app/, "#");
        return new URL("/" + hash + url.search, url.origin);
    }
    return undefined;
}
