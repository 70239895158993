"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const currentEnterpriseVar = (0, client_1.makeVar)(localStorage.getItem("enterprise") || null);
const persist = (newValue) => {
    if (!newValue) {
        localStorage.removeItem("enterprise");
    }
    else {
        localStorage.setItem("enterprise", newValue);
    }
    currentEnterpriseVar.onNextChange(persist);
};
currentEnterpriseVar.onNextChange(persist);
window.addEventListener("storage", (event) => {
    if (event.key === "enterprise") {
        currentEnterpriseVar(event.newValue || null);
        if (window.navigator.onLine) {
            window.location.reload();
        }
    }
});
exports.default = currentEnterpriseVar;
