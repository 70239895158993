import { BaseElement } from "components/fl-ui/common";
import ContractQuantity from "contracts/components/ContractQuantity";
import ContractStatus from "contracts/components/ContractStatus";
import NotesArea from "contracts/components/NotesArea";
import Price from "contracts/components/Price";
import PricingAndDeliveryArea from "contracts/components/PricingAndDeliveryArea";
import { PRICELINE_STATUSES } from "contracts/constants/pricelineStatuses";
import CashContractForm, { withCashContractFormContext } from "contracts/form/CashContractForm";
import CashContractFormActions from "contracts/form/CashContractForm/CashContractFormActions";
import useContractPermissions from "contracts/hooks/useContractPermissions";
import useContractsIndexPage from "contracts/hooks/useContractsIndexPage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ContractStatusCopy, ContractSubtypeCopy } from "components/copy";
import { Button } from "components/fl-ui";
import { IconContainer } from "components/fl-ui/Icons";
import { Stack } from "components/fl-ui/Layout";
import Tooltip from "components/fl-ui/Tooltip";
import ColorPalette from "components/fl-ui/colors/palette";
import { Borders, Spacing } from "components/fl-ui/constants";

const BorderedRow = styled(({ children, className }) => <div className={className}>{children}</div>)`
  height: 42px;
  padding-top: ${Spacing.small};
  padding-right: ${Spacing.xxxsmall};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: ${(props) => (props.hideBorder ? "none" : Borders.dark)};
  flex: 1;

  & > div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .right-content {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MarketedCropLink = ({ marketedCrop }) => {
  const { id, name } = marketedCrop;

  return <Link to={`/marketing/${id}`}>{name}</Link>;
};

const ExpandedRowArea = styled(({ className, contract, onEdit }) => {
  const { canWrite, requiresMarketingUpgrade } = useContractPermissions();
  const isEditable = canWrite(contract);
  const marketingTooltipText = requiresMarketingUpgrade()
    ? "Upgrade your subscription to organize your contracts into Marketing Crops"
    : "Create new Marketing Crops in the Marketing feature";

  const pricingTooltipText =
    contract.contractStatus === "OPEN"
      ? "The pricing for the contract is ongoing. Displayed is the current market value."
      : "Pricing is complete. Displayed is the final cash cash price for the contract.";

  return (
    <div className={className}>
      <h4>Details</h4>

      <div className="split-half">
        <BorderedRow hideBorder>
          <div>Buyer</div>
          <div className="right-content">{contract.buyer ?? "--"}</div>
        </BorderedRow>

        <BorderedRow>
          <div>Contract Number</div>
          <div className="right-content">{contract.contractNumber ?? "--"}</div>
        </BorderedRow>

        <BorderedRow>
          <div>Commodity</div>
          <div className="right-content">{contract?.commodity?.name ?? "--"}</div>
        </BorderedRow>

        <BorderedRow>
          <div>Booked Date</div>
          <div className="right-content">{moment(contract.contractDate).format("MM/DD/YYYY")}</div>
        </BorderedRow>

        <BorderedRow>
          <div>Type</div>
          <div className="right-content">
            {contract.contractType ? (
              <ContractSubtypeCopy subtype={contract.contractType} product={contract.product} />
            ) : (
              "--"
            )}
          </div>
        </BorderedRow>

        <BorderedRow>
          <div>Contract Status</div>
          <div className="right-content">
            <ContractStatus>
              {contract.contractStatus ? <ContractStatusCopy status={contract.contractStatus} /> : "--"}
            </ContractStatus>
          </div>
        </BorderedRow>
      </div>

      <div className="split-half">
        <BorderedRow hideBorder>
          <BaseElement alignItems="center" display="flex" gap={Spacing.xxxsmall}>
            <div>Pricing</div>
            <Tooltip message={pricingTooltipText}>
              <IconContainer icon="help" size="regular" />
            </Tooltip>
          </BaseElement>

          <div className="right-content">
            <Price
              price={contract.netPrice}
              variant={contract.contractFinalStatus !== PRICELINE_STATUSES.final ? "YELLOW" : null}
            />
          </div>
        </BorderedRow>

        <BorderedRow>
          <div>Quantity Contracted</div>
          <div className="right-content">
            <ContractQuantity contract={contract} quantity={contract?.quantity} />
          </div>
        </BorderedRow>

        <BorderedRow>
          <div>Quantity Delivered</div>
          <div className="right-content">
            <ContractQuantity contract={contract} quantity={contract?.quantityDelivered} />
          </div>
        </BorderedRow>

        <BorderedRow>
          <div>Quantity Canceled</div>
          <div className="right-content">
            <ContractQuantity contract={contract} quantity={contract?.quantityCanceled} />
          </div>
        </BorderedRow>

        <BorderedRow>
          <div>Quantity Priced</div>
          <div className="right-content">
            <ContractQuantity contract={contract} quantity={contract?.quantityPriced} />
          </div>
        </BorderedRow>

        <BorderedRow>
          <BaseElement alignItems="center" display="flex" gap={Spacing.xxxsmall}>
            <div>Marketing Crop</div>

            <Tooltip message={marketingTooltipText}>
              <IconContainer icon="help" size="regular" />
            </Tooltip>
          </BaseElement>

          <div className="right-content">
            {contract.marketedCrop ? <MarketedCropLink marketedCrop={contract.marketedCrop} /> : "--"}
          </div>
        </BorderedRow>

        {isEditable && (
          <BaseElement display="flex" justifyContent="flex-end">
            <Button className="btn btn-hollow btn-primary" onClick={onEdit}>
              Edit Details
            </Button>
          </BaseElement>
        )}
      </div>
    </div>
  );
})`
  h3 {
    color: ${ColorPalette["bushel-blue"]};
  }

  h4 {
    color: ${ColorPalette["bushel-blue"]};
    text-transform: uppercase;
  }

  .split-half {
    width: 50%;
    display: inline-block;
    padding: ${Spacing.xlarge};
    vertical-align: top;
  }
`;

const ContractFormArea = withCashContractFormContext(({ contractId, onCancel, onSave }) => {
  const { reloadAll } = useContractsIndexPage();
  const handleDelete = () => {
    reloadAll();
  };

  return (
    <Stack>
      <CashContractForm contractId={contractId} formId="contractForm" onCancel={onCancel} onSave={onSave} />
      <CashContractFormActions clusterActions formId="contractForm" onCancel={onCancel} onDelete={handleDelete} />
    </Stack>
  );
});

export default styled(({ className, contract, expanded }) => {
  const [view, setView] = useState("details");
  useEffect(() => {
    if (!expanded) {
      setView("details");
    }
  }, [expanded]);

  return !expanded ? null : (
    <div className={className}>
      <div>
        <h3>Breakdown</h3>

        {view === "details" && (
          <ExpandedRowArea contract={contract} expanded={expanded} onEdit={() => setView("edit")} />
        )}

        {view === "edit" && (
          <ContractFormArea
            contractId={contract.id}
            onCancel={() => setView("details")}
            onSave={() => setView("details")}
          />
        )}

        <PricingAndDeliveryArea contract={contract} expanded={expanded} />

        <NotesArea contract={contract} />
      </div>
    </div>
  );
})`
  border-top: ${(props) => (props.expanded ? Borders.dark : "none")};
  > :first-child {
    padding: ${Spacing.xxlarge};
  }
`;
