import React from 'react';
import IconContainer from '../IconContainer';


const StorageIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M5.945 10.463v8.792c0 .986 2.668 2.114 6.053 2.114 3.386 0 6.054-1.128 6.054-2.114 0-.05 0-.05.002-.097l.003-.076.01-8.627c-1.474.8-3.657 1.262-6.069 1.262-2.403 0-4.58-.46-6.053-1.254zM19.62 9.127l-.012 9.99-.003.089-.002.05c0 2.206-3.446 3.664-7.604 3.664-4.157 0-7.603-1.458-7.603-3.665V9.152A2.228 2.228 0 0 1 4 7.901c0-.251.122-.487.327-.633l7.223-5.125a.775.775 0 0 1 .897 0l7.224 5.125a.775.775 0 0 1 .327.633c0 .438-.133.85-.379 1.226zM12 3.726L5.64 8.237c.51.969 3.13 1.93 6.357 1.93 3.229 0 5.848-.961 6.359-1.93l-6.359-4.511z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

StorageIcon.iconId = 'storage';


export default StorageIcon;
