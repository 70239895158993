import React, { useState } from "react";

import App from "layout/app";
import { fetch } from "lib/ajax";
import { downloadFile } from "lib/download/downloadFile";

import { Button } from "components/fl-ui";

const DownloadInvoiceButton = ({ invoice }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleClick = async () => {
    setIsDownloading(true);
    await fetch(`v2.0/subscriptions/invoices/id/${invoice.id}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Invoice fetch error: ${response.statusText}`);
        }
        downloadFile(response, "invoice");
      })
      .catch((error) => {
        App.notify("Invoice download failed: ", error);
        throw error;
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <Button disabled={isDownloading} color="primary" hollow loading={isDownloading} onClick={handleClick}>
      {isDownloading ? "Downloading" : "Download"}
    </Button>
  );
};

export default DownloadInvoiceButton;
