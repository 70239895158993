"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadFile = downloadFile;
const mobileManager_1 = require("mobile/mobileManager");
const createDownloadLink_1 = require("lib/download/helpers/createDownloadLink");
const getExportFilename_1 = require("lib/download/helpers/getExportFilename");
const writeFileAndShare_1 = require("lib/download/helpers/writeFileAndShare");
function downloadFile(apiResponse, filenameDefault) {
    return __awaiter(this, void 0, void 0, function* () {
        const dataBlob = yield apiResponse.blob();
        const filename = (0, getExportFilename_1.getExportFilename)(apiResponse, filenameDefault);
        if ((0, mobileManager_1.isNative)()) {
            (0, writeFileAndShare_1.writeFileAndShare)(dataBlob, filename);
        }
        else {
            (0, createDownloadLink_1.createDownloadLink)(dataBlob, filename).click();
        }
    });
}
