import React from 'react';
import IconContainer from '../IconContainer';


const OverflowHorizontalIcon = (props) => (
  <IconContainer {...props}>
    <g
      transform="rotate(90 12 12)"
      fill="none"
      fillRule="evenodd"
    >
      <g id="overflow-right" fillRule="evenodd" fill="currentColor">
        <path
          d="M11.887 7.408a1.765 1.765 0 1 1 .001-3.531 1.765 1.765 0 0 1 0 3.53zm0 6.122a1.765 1.765 0 1 1 0-3.53 1.765 1.765 0 0 1 0 3.53zm0 6.122a1.765 1.765 0 1 1 0-3.53 1.765 1.765 0 0 1 0 3.53z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
    </g>

  </IconContainer>
);

OverflowHorizontalIcon.iconId = 'overflowHorizontal';


export default OverflowHorizontalIcon;
