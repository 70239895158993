/* eslint-disable react/display-name */
import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

import withActivityTypes from "collection/graphql/activities/withActivityTypes";
import { CapsHeader } from "modules/reports/components/Helpers";

export const ActivityTypeFilter = (props) => {
  const {
    data,
    onParamChanged,
    param: { id, title },
  } = props;
  const activityTypes = _.filter(data.activityTypes, { isArchived: false });

  return (
    <div>
      <CapsHeader>{title}</CapsHeader>
      <ReactSelect
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        isMulti
        onChange={(activityTypes) =>
          onParamChanged({
            id,
            value: _.map(activityTypes, "id"),
          })
        }
        options={activityTypes}
        styles={chooserStyles}
      />
    </div>
  );
};

ActivityTypeFilter.propTypes = {
  onParamChanged: PropTypes.func.isRequired,
  param: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const WrappedActivityTypeFilter = withActivityTypes(ActivityTypeFilter);

export default (props) => <WrappedActivityTypeFilter {...props} />;
