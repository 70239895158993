import { useQuery } from "@apollo/client";
import { BaseElement } from "components/fl-ui/common";
import { css } from "aphrodite";
import _ from "lodash";
import dollarFormat from "marketing/utils/dollarFormat";
import numeral from "numeral";
import PurchaseFormModal from "products/containers/PurchaseFormModal";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import getMinimalPurchases from "collection/graphql/products/getMinimalPurchases";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import usePermissions from "hooks/usePermissions";
import useViewType from "hooks/useViewType";
import styles, { Label, StyledTableColumn } from "modules/activity/common/styles";

import { Button } from "components/fl-ui";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";
import ProductApplyUnit from "components/product/ProductApplyUnit";
import ProductPurchaseUnit from "components/product/ProductPurchaseUnit";

const usePurchasedProductIds = (cropYear, skip) => {
  const { data, refetch } = useQuery(getMinimalPurchases, {
    skip,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      year: cropYear,
    },
  });

  return {
    refreshQuery: refetch,
    purchasedProductIds: useMemo(
      () =>
        _.reduce(
          data?.purchaseSummaries,
          (productIds, { product, purchases }) => (purchases.length === 0 ? productIds.concat(product.id) : productIds),
          []
        ),
      [data]
    ),
  };
};

const DesktopInputsListing = styled.table`
  th {
    text-align: left;
    text-transform: uppercase;
    &:last-child {
      text-align: right;
    }
  }

  td:last-child {
    text-align: right;
  }
`;

const LogPurchaseButton = ({ onClick }) => (
  <Button color="primary" link onClick={onClick}>
    Log Purchase
  </Button>
);

const ResponsiveInputsListing = styled(({ canLogPurchases, className, handleLogPurchase, inputs, showLogPrompt }) => {
  return (
    <div className={className}>
      <h3>Inputs</h3>

      {inputs.map((input) => {
        const { acreage, amount, amountRate, id, inputCostPerUnit, inputCostPerAcre, product } = input;
        const { id: productId, name, type } = product;

        return (
          <section key={id}>
            <dl>
              <dt>Input</dt>
              <dd>{_.upperFirst(name || type)}</dd>

              <dt>Rate</dt>
              <dd>
                <ProductApplyUnit
                  source={input}
                  amount={numeral(amount).format("0,0[.]00")}
                  attribute="name"
                  per={amountRate === "PER_ACRE" ? "acre" : undefined}
                />
              </dd>

              {canLogPurchases && (
                <>
                  <dt>Cost per unit</dt>
                  <dd>
                    <ProductPurchaseUnit
                      source={input}
                      amount={dollarFormat(inputCostPerUnit)}
                      per="unit"
                      attribute="name"
                    />{" "}
                    {showLogPrompt(productId) && <LogPurchaseButton onClick={() => handleLogPurchase(input)} />}
                  </dd>
                </>
              )}

              <dt>Area</dt>
              <dd>{numeral(acreage).format("0,0[.]00")} ac</dd>

              {canLogPurchases && (
                <>
                  <dt>Cost per acre</dt>
                  <dd>{dollarFormat(inputCostPerAcre)} / ac</dd>
                </>
              )}
            </dl>
          </section>
        );
      })}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  h3 {
    font-size: ${Typography.sizes.mn};
    margin-bottom: 0;
    text-transform: uppercase;
  }

  section {
    border-radius: ${BorderRadius.xlarge};
    box-shadow: ${Borders.tableRow};
    padding: ${Spacing.small};
  }

  dl {
    align-items: stretch;
    //background-color: #ccc;
    display: grid;
    grid-gap: 1px 0;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
  }

  dt,
  dd {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    place-content: center;
    &:last-of-type {
      border: none;
    }
  }
  dd {
    font-weight: bold;
    margin: 0;
    text-align: right;
  }
`;

const InputsListing = ({ activity, onProductAdd }) => {
  const {
    crop: { cropYear },
    inputCostPerAcre,
  } = activity;
  const [currentProduct, setCurrentProduct] = useState(null);
  const canReadInputCosts = usePermissions().canRead("inputs");
  const isMobile = useViewType() === "mobile";

  const inputs = useMemo(() => {
    return _.reject(activity.inputs.concat(activity.irrigation || {}), ({ product }) => {
      return !product?.name;
    });
  }, [activity.inputs, activity.irrigation]);

  const { loadingAccess, showContent } = useFeatureAndPermissionAccess({
    featureName: "products",
    permissionName: "inputs",
  });

  const canLogPurchases = !loadingAccess && showContent;

  const { purchasedProductIds, refreshQuery } = usePurchasedProductIds(cropYear, !canLogPurchases);

  const handleClose = () => {
    setCurrentProduct(null);
  };

  const handleSuccess = () => {
    setCurrentProduct(null);
    onProductAdd();
    refreshQuery();
  };

  const showLogPrompt = (id) => canLogPurchases && purchasedProductIds.includes(id);

  if (_.isEmpty(inputs)) {
    return <p style={{ padding: "1em 0" }}>No inputs for this activity.</p>;
  }

  const handleLogPurchase = (activityInput) => {
    const purchase = { amount: activityInput.amount, amountUnit: activityInput.amountUnit };
    purchase.costPerUnit = activityInput.inputCostPerUnit;
    purchase.costRate = "PER_UNIT";
    purchase.product = _.cloneDeep(activityInput.product);
    setCurrentProduct(purchase);
  };

  return (
    <>
      {isMobile && (
        <>
          <ResponsiveInputsListing
            canLogPurchases={canLogPurchases}
            inputs={inputs}
            handleLogPurchase={handleLogPurchase}
            showLogPrompt={showLogPrompt}
          />

          {canReadInputCosts && (
            <BaseElement display="flex" justifyContent="space-between" pb="10px">
              <span>Total</span>
              <strong>{dollarFormat(inputCostPerAcre)} / ac</strong>
            </BaseElement>
          )}
        </>
      )}

      {!isMobile && (
        <DesktopInputsListing>
          <thead>
            <tr>
              <th>Input</th>
              <th>Rate</th>
              <th>Cost per unit</th>
              <th>Area</th>
              <th>Cost per ac</th>
            </tr>
          </thead>

          <tbody>
            {inputs.map((activityInput, i) => {
              const { acreage, amount, amountRate, inputCostPerUnit, inputCostPerAcre, product } = activityInput;
              const { id: productId, name, type } = product;

              return (
                <tr key={i}>
                  <StyledTableColumn>
                    <Label>{_.upperFirst(name || type)}</Label>
                  </StyledTableColumn>

                  <StyledTableColumn className={css(styles.borderBottom)}>
                    <ProductApplyUnit
                      source={activityInput}
                      amount={numeral(amount).format("0,0[.]00")}
                      attribute="name"
                      per={amountRate === "PER_ACRE" ? "acre" : undefined}
                    />
                  </StyledTableColumn>

                  <StyledTableColumn className={css(styles.borderBottom)}>
                    {canLogPurchases ? (
                      <>
                        <ProductPurchaseUnit
                          source={activityInput}
                          amount={dollarFormat(inputCostPerUnit)}
                          per="unit"
                          attribute="name"
                        />{" "}
                        {showLogPrompt(productId) && (
                          <span>
                            (<LogPurchaseButton onClick={() => handleLogPurchase(activityInput)} />)
                          </span>
                        )}
                      </>
                    ) : (
                      "--"
                    )}
                  </StyledTableColumn>

                  <StyledTableColumn>{numeral(acreage).format("0,0[.]00")} ac</StyledTableColumn>

                  <StyledTableColumn>
                    {canLogPurchases ? <>{dollarFormat(inputCostPerAcre)} / ac</> : "--"}
                  </StyledTableColumn>
                </tr>
              );
            })}

            {canReadInputCosts && (
              <tr>
                <StyledTableColumn>Total</StyledTableColumn>
                <StyledTableColumn colSpan={4}>{dollarFormat(inputCostPerAcre)} / ac</StyledTableColumn>
              </tr>
            )}
          </tbody>
        </DesktopInputsListing>
      )}

      {currentProduct && (
        <PurchaseFormModal
          cropYear={cropYear}
          onClose={handleClose}
          onSaveSuccess={handleSuccess}
          purchase={currentProduct}
          lockProduct
        />
      )}
    </>
  );
};

InputsListing.propTypes = {
  activity: PropTypes.object.isRequired,
  onProductAdd: PropTypes.func,
};

export default InputsListing;
