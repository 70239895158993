import EquipmentIconPicker from "equipment/components/EquipmentIconPicker";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { GroupedButtons } from "components/fl-ui/Buttons";
import { FormGroup, Input, NumericInput, Select, TextArea } from "components/fl-ui/Form";

const ImplementForm = (props) => {
  const { formId, implementTypes, onChange, onSubmit } = props;
  const { icon, implementTypeId, manufactureYear, isRented, name, notes } = props.formFields;
  const typeOptions = _.sortBy(implementTypes, "name").map(({ id, name }) => ({ label: name, value: id }));
  const handleChange = (event, payload) => onChange(payload);
  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    onChange({ [name]: value });
  };
  const selectedOwnership = isRented !== null ? { id: isRented ? "isRented" : "isOwned" } : null;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Row>
        <Col md={6} sm xs={12}>
          <FormGroup inputId="name" label="Name">
            <Input id="name" maxLength={45} name="name" onChange={handleChange} type="text" value={name} />
          </FormGroup>
        </Col>

        <Col md={6} sm xs={12}>
          <FormGroup inputId="implementTypeId" label="Type">
            <Select
              id="implementTypeId"
              name="implementTypeId"
              onChange={(event) => onChange({ implementTypeId: +event.target.value })}
              options={typeOptions}
              value={implementTypeId}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} sm xs={12}>
          <FormGroup inputId="manufactureYear" label="Manufacture Year (optional)">
            <NumericInput
              id="manufactureYear"
              max={new Date().getFullYear() + 2}
              min={1900}
              name="manufactureYear"
              onChange={handleNumericChange}
              step={1}
              type="integer"
              value={manufactureYear}
            />
          </FormGroup>
        </Col>

        <Col md={6} sm xs={12}>
          <FormGroup label="Ownership (optional)">
            <GroupedButtons
              onClick={({ id }) => onChange({ isRented: id === "isRented" })}
              options={[
                { id: "isOwned", label: "Owned" },
                { id: "isRented", label: "Rented" },
              ]}
              selected={selectedOwnership}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={12} xs={12}>
          <FormGroup inputId="notes" label="Notes (optional)">
            <TextArea id="notes" name="notes" onChange={handleChange} style={{ minHeight: "200px" }} value={notes} />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={12} xs={12}>
          <FormGroup label="Icon (optional)">
            <EquipmentIconPicker iconGroup={implementTypeId} onChange={(icon) => onChange({ icon })} value={icon} />
          </FormGroup>
        </Col>
      </Row>
    </form>
  );
};

ImplementForm.propTypes = {
  formId: PropTypes.string.isRequired,
  implementTypes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ImplementForm;
