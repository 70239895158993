import React from 'react';
import IconContainer from '../IconContainer';


const ErrorIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M16.929 18.877a8.461 8.461 0 1 0-9.86-13.754 8.461 8.461 0 0 0 9.86 13.754zm.896 1.25c-4.49 3.218-10.735 2.186-13.952-2.303C.656 13.336 1.686 7.09 6.175 3.872 10.664.656 16.911 1.686 20.127 6.174c3.218 4.489 2.187 10.736-2.302 13.953zM11.173 7.598v6.123a.77.77 0 0 0 1.538 0V7.598c0-.256-.28-.793-.769-.792-.49.002-.77.484-.77.792zm1.983 8.877a1.156 1.156 0 1 0-2.312-.001 1.156 1.156 0 0 0 2.312 0z"
      fill="currentColor" fillRule="nonzero"/>

  </IconContainer>
);

ErrorIcon.iconId = 'error';


export default ErrorIcon;
