import { useEffect, useState } from "react";

import useFieldLayers from "lib/map/layers/useFieldLayers";

const useSoilLayers = ({ fieldId, skip, sanitize }) => {
  const { layers, loading } = useFieldLayers({ fieldId, skip, type: "soil.ssurgo" });
  const [data, setData] = useState({ loading, soilLayers: [] });

  useEffect(() => {
    if (!loading) {
      const soilLayers = sanitize ? sanitize(layers) : layers;
      setData({ loading, soilLayers });
    }
  }, [fieldId, layers, loading, skip, sanitize]);

  return data;
};

export default useSoilLayers;
