export const PENDO_TRACK_EVENT = {
  CASH_CONTRACT_SAVE: "CASH_CONTRACT_SAVE",
  MARKETING_CROP_SAVE: "MARKETING_CROP_SAVE",
  MOBILE_FORCED_APP_UPDATE_PRESENTATION: "MOBILE_FORCED_APP_UPDATE_PRESENTATION",
  MOBILE_FORCED_APP_UPDATE_RESOLUTION: "MOBILE_FORCED_APP_UPDATE_RESOLUTION",
  SUBSCRIPTION_BUSINESS_TRIAL_CLOSE: "SUBSCRIPTION_BUSINESS_TRIAL_CLOSE",
  SUBSCRIPTION_BUSINESS_TRIAL_PRESENTATION: "SUBSCRIPTION_BUSINESS_TRIAL_PRESENTATION",
  SUBSCRIPTION_BUSINESS_TRIAL_START: "SUBSCRIPTION_BUSINESS_TRIAL_START",
  TEST_EVENT: "TEST_EVENT",
};

export const isPendoReady = new Promise((resolve) => {
  if (process.env.PENDO_API_KEY) {
    const interval = setInterval(() => {
      if ("pendo" in window) {
        clearInterval(interval);
        resolve(window.pendo);
      }
    }, 1000);
  }
});

/**
 * Tracks a custom event in Pendo
 * @param {PENDO_TRACK_EVENT} eventName
 * @param {Object} eventProperties
 */
export const pendoTrack = (eventName, eventProperties = {}) => {
  isPendoReady.then((pendo) => {
    pendo.track(eventName, eventProperties);
  });
};
