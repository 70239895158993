import React from 'react';
import IconContainer from '../IconContainer';


const LineChartIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <polygon fill="currentColor"
                 points="3.5 18.49 9.5 12.48 13.5 16.48 22 6.92 20.59 5.51 13.5 13.48 9.5 9.48 2 16.99"></polygon>
        <polygon points="0 0 24 0 24 24 0 24"></polygon>
      </g>
    </g>

  </IconContainer>
);

LineChartIcon.iconId = 'lineChart';


export default LineChartIcon;
