import React from 'react';
import IconContainer from '../IconContainer';


const CameraIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M17.3525489,5.68592018 L21.4753,5.68143389 C22.3176773,5.67947825 23.0020333,6.33564314 22.9999955,7.14235426 L22.9857637,19.5418314 C22.9857637,20.3475709 22.3034138,21 21.4629138,21 L2.52183327,21 C1.68109034,21 1,20.3463622 1,19.5408567 L1,7.16396816 C1,6.35936241 1.67974805,5.70580529 2.51995067,5.70385058 L6.64109984,5.69936241 L8.78670777,3.25976976 C8.93157575,3.09505241 9.14485271,3 9.36957472,3 L14.6304212,3 C14.8548066,3 15.0677994,3.094769 15.2126669,3.2590643 L17.3525489,5.68592018 Z M16.9999383,7.1483723 C16.7752505,7.14861683 16.56189,7.0538253 16.4168272,6.88930848 L14.2766056,4.46206747 L9.72397563,4.46206747 L7.57870706,6.90127426 C7.43403426,7.0657697 7.22112523,7.16079915 6.99670613,7.16104355 L2.52488304,7.16591302 L2.52488304,19.5379325 L21.4608847,19.5379325 L21.4751108,7.1435019 L16.9999383,7.1483723 Z M16.7304901,13.3573834 C16.7304901,15.8614979 14.6122699,17.891742 12.0003029,17.891742 C9.38833598,17.891742 7.27011575,15.8614979 7.27011575,13.3573834 C7.27011575,10.8531411 9.38846948,8.82205014 12.0003029,8.82205014 C14.6121364,8.82205014 16.7304901,10.8531411 16.7304901,13.3573834 Z M15.2056071,13.3573834 C15.2056071,11.6606186 13.7699667,10.2841176 12.0003029,10.2841176 C10.2306391,10.2841176 8.79499879,11.6606186 8.79499879,13.3573834 C8.79499879,15.0539045 10.2303848,16.4296746 12.0003029,16.4296746 C13.7702211,16.4296746 15.2056071,15.0539045 15.2056071,13.3573834 Z M20.3810155,9.57719021 C20.4171619,10.3093264 19.8292185,10.9299583 19.0616803,10.9669598 C18.2996344,10.9994815 17.6517861,10.435277 17.6155718,9.70393342 C17.5794305,8.97189953 18.1673739,8.35126771 18.9327636,8.31436076 C19.6967719,8.2796845 20.3447743,8.8453036 20.3810155,9.57719021 Z"
        fill="currentColor" fillRule="nonzero"></path>
    </g>

  </IconContainer>
);

CameraIcon.iconId = 'camera';


export default CameraIcon;
