"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.writeFileAndShare = writeFileAndShare;
const mobileManager_1 = require("mobile/mobileManager");
const blobToBase64_1 = require("lib/download/helpers/blobToBase64");
function writeFileAndShare(blob, filename) {
    return __awaiter(this, void 0, void 0, function* () {
        const fileAsBase64String = yield (0, blobToBase64_1.blobToBase64)(blob);
        const writeFileResult = yield (0, mobileManager_1.fileWrite)({
            path: `com.farmlogs.app/${filename}`,
            data: fileAsBase64String,
            directory: (0, mobileManager_1.directoryCache)(),
            recursive: true,
        });
        yield (0, mobileManager_1.share)({
            title: filename,
            url: writeFileResult.uri,
        }).catch((error) => {
            if (error.message === "Share canceled")
                return;
            throw new Error(error);
        });
    });
}
