import React from 'react';
import IconContainer from '../IconContainer';


const PersonWarningIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-38.000000, -250.000000)">
        <g transform="translate(16.000000, 231.000000)">
          <g transform="translate(16.000000, 16.000000)">
            <g>
              <rect x="0" y="0" width="40" height="40"></rect>
            </g>
            <g transform="translate(6.666667, 3.333333)" fill="currentColor" fillRule="nonzero">
              <g>
                <circle cx="13.3333333" cy="3.33333333" r="3.33333333"></circle>
                <path
                  d="M19.8166667,10.1833333 C19.1666667,9.53333333 18.05,8.33333333 15.8833333,8.33333333 C15.5333333,8.33333333 13.5166667,8.33333333 11.65,8.33333333 C7.06666667,8.31666667 3.33333333,4.58333333 3.33333333,0 L0,0 C0,5.26666667 3.51666667,9.73333333 8.33333333,11.1833333 L8.33333333,33.3333333 L11.6666667,33.3333333 L11.6666667,23.3333333 L15,23.3333333 L15,33.3333333 L18.3333333,33.3333333 L18.3333333,13.4166667 L24.9166667,20 L27.2666667,17.65 L19.8166667,10.1833333 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>

  </IconContainer>
);

PersonWarningIcon.iconId = 'personWarning';


export default PersonWarningIcon;
