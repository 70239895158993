import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color, space } from 'styled-system';


const IconContainer = styled((props) => {
  const { children, height = 24, width = 24, viewBox = '0 0 24 24', ...rest } = props;

  return (
    <div {...rest}>
      <svg height={height} width={width} version={1.1} viewBox={viewBox}>
        {children}
      </svg>
    </div>
  );
})`
  ${color}
  ${space}
`;

IconContainer.propTypes = {
  children: PropTypes.any.isRequired
};


export default IconContainer;
