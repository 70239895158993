import { fileClient } from "components/fl-ui/common";
import ImageThumbnails from "activity/components/ActivityAttachments/ImageThumbnails";
import PreviewImage from "activity/components/ActivityAttachments/PreviewImage";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { getIconPath } from "components/field/utils";
import { Stack } from "components/fl-ui/Layout";

const getImageUrl = (filepickerId, size) =>
  fileClient.getImagePreviewUrl(filepickerId, {
    height: size,
    quality: 75,
    width: size,
  });

const ActivityAttachments = (props) => {
  const { activity, previewSize, thumbnailSize } = props;

  let images = activity.images;
  images = _.map(images, ({ filepickerId, url }) => ({
    id: filepickerId || url,
    isRecording: false,
    previewUrl: filepickerId ? getImageUrl(filepickerId, previewSize) : url,
    thumbUrl: filepickerId ? getImageUrl(filepickerId, thumbnailSize) : url,
  }));

  activity.recordings?.forEach((recording) => {
    if (recording?.frames?.length) {
      images.push({
        id: recording.id,
        isRecording: true,
        previewUrl: getIconPath(activity.field, { height: previewSize, width: previewSize }),
        recording,
        thumbUrl: getIconPath(activity.field, { height: thumbnailSize, width: thumbnailSize }),
      });
    }
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (id) => {
    setSelectedImage(_.find(images, { id }));
  };

  useEffect(() => {
    if (images.length === 0 && selectedImage) {
      setSelectedImage(null);
    } else if (images.length > 0) {
      if (!selectedImage || !_.map(images, ["id"]).includes(selectedImage.id)) {
        setSelectedImage(images[0]);
      }
    }
  }, [activity.images]);

  return (
    <Stack>
      <PreviewImage activity={activity} image={selectedImage} previewSize={previewSize} />

      <ImageThumbnails
        images={images}
        onImageSelect={handleImageSelect}
        previewSize={previewSize}
        selectedId={selectedImage?.id || null}
        thumbnailSize={thumbnailSize}
      />
    </Stack>
  );
};

ActivityAttachments.propTypes = {
  activity: PropTypes.any.isRequired,
  previewSize: PropTypes.number,
  thumbnailSize: PropTypes.number,
};

ActivityAttachments.defaultProps = {
  previewSize: 336,
  thumbnailSize: 77,
};

export default ActivityAttachments;
