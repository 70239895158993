import React from 'react';
import IconContainer from '../IconContainer';


const NotificationsIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M15.471 17.685c-.234 2.21-1.367 3.605-3.472 3.605-2.104 0-3.238-1.395-3.472-3.603C4.402 17.535 3 16.969 3 15.193c0-.049.003-.096.007-.143a.733.733 0 0 1 .101-.492c.142-.414.418-.789.806-1.129 1.587-2.324 2.692-4.575 2.692-6.056a5.373 5.373 0 1 1 10.748 0 .757.757 0 0 1-.006.092c.044 1.486 1.146 3.655 2.78 6.034.303.282.529.593.668.932l.05.065a.738.738 0 0 1 .114.68c0 1.785-1.393 2.355-5.49 2.51zm-5.434.039c.185 1.375.823 2.067 1.962 2.067s1.776-.692 1.962-2.068c-.612.01-1.27.013-1.98.013-.696 0-1.343-.003-1.944-.012zm9.03-3.166l-.122-.137c-1.877-2.719-3.096-5.123-3.096-7.048 0-.032.002-.063.006-.094a3.875 3.875 0 0 0-7.75.094c0 1.913-1.22 4.38-3.01 6.986l-.14.152c-.237.197-.376.38-.429.535a.767.767 0 0 1-.01.06.61.61 0 0 0-.016.087c0 .706 2.137 1.045 7.48 1.045 5.345 0 7.482-.34 7.482-1.045a.794.794 0 0 0-.017-.087 1.192 1.192 0 0 0-.379-.548z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

NotificationsIcon.iconId = 'notifications';


export default NotificationsIcon;
