/* eslint-disable react/display-name */
import _ from "lodash";
import React from "react";

import useHistoricalRainfall from "collection/graphql/weather/hooks/useHistoricalRainfall";

import { organizeIntoGroups } from "fields/utils";

export default (WrappedComponent) =>
  ({ fields, ...props }) => {
    props.isGrouped = false;
    const recent = useHistoricalRainfall().historicalRainfallMap;
    const { sortCriteria } = props.selectedFilters;

    switch (sortCriteria) {
      case "asc":
      case "desc":
        props.fields = fields;

        if (recent) {
          const fieldsById = _.keyBy(fields, "id");
          let [last24, historical] = _.partition(
            Array.from(recent.keys()),
            (fieldId) => recent.get(fieldId).state === "recent"
          );
          if (sortCriteria === "desc") {
            last24 = _.reverse(last24);
            historical = _.reverse(historical);
          }

          props.fields = last24.concat(historical).map((fieldId) => {
            const entry = fieldsById[fieldId];
            delete fieldsById[fieldId];

            return entry;
          });

          // any fields remaining in fieldsById have no rainfall data
          const remainingFields = _.sortBy(Object.values(fieldsById), "name");
          if (sortCriteria === "asc") {
            props.fields = remainingFields.concat(props.fields);
          } else {
            props.fields = props.fields.concat(remainingFields);
          }
          props.fields = _.compact(props.fields);
        }
        break;

      case "fieldname":
        props.fields = _.sortBy(fields, (f) => (isNaN(f.name) ? f.name.toLowerCase() : parseInt(f.name)));
        break;

      default:
        props.isGrouped = true;
        props.fields = fields;
        props.groupedFields = organizeIntoGroups(fields);
        break;
    }

    return <WrappedComponent {...props} />;
  };
