import React from 'react';
import IconContainer from '../IconContainer';


const ButtonChevronDownIcon = (props) => (
  <IconContainer {...props}>

    <defs>
      <path
        d="M2.13388348,0.366116524 C1.64572811,-0.122038841 0.854271888,-0.122038841 0.366116524,0.366116524 C-0.122038841,0.854271888 -0.122038841,1.64572811 0.366116524,2.13388348 L5,6.76776695 L9.63388348,2.13388348 C10.1220388,1.64572811 10.1220388,0.854271888 9.63388348,0.366116524 C9.14572811,-0.122038841 8.35427189,-0.122038841 7.86611652,0.366116524 L5,3.23223305 L2.13388348,0.366116524 Z"
        id="path-1"></path>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(12.000000, 12.750000) rotate(-360.000000) translate(-12.000000, -12.750000) translate(7.000000, 9.000000)">
        <mask id="mask-2" fill="currentColor">
          <use href="#path-1"></use>
        </mask>
        <g id="shape-mask" fillRule="nonzero"></g>
        <g id="_mixin-/-swatch-/-Gray" mask="url(#mask-2)" fill="#737B8D">
          <g transform="translate(-7.500000, -10.000000)" id="style">
            <rect x="0" y="0" width="24" height="24"></rect>
          </g>
        </g>
      </g>
    </g>

  </IconContainer>
);

ButtonChevronDownIcon.iconId = 'buttonChevronDown';


export default ButtonChevronDownIcon;
