import AgreementRowSubtype from "agreements/components/AgreementRowSubtype";
import { css } from "aphrodite";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";

import * as constants from "modules/agreements/constants";
import { getFormattedList } from "modules/agreements/utils";

import { Checkbox } from "components/fl-ui/Form";
import styles from "fields/common/styles";

const AgreementRow = (props) => {
  const {
    agreementSummary,
    agreementType,
    bulkSelection,
    fields,
    id,
    lenderName,
    maturityDate,
    onBulkSelect,
    revenueSource,
    startDate,
    subType,
    terms,
  } = props;
  const navigate = useNavigate();

  const checked = bulkSelection.includes(id);
  const dateRange = `${moment(startDate).format("MM/DD/YYYY")} - ${moment(maturityDate).format("MM/DD/YYYY")}`;
  const getIssuer = () => {
    switch (agreementType) {
      case constants.LEASE:
        const { firstName, lastName, organization } = props.landlord;
        return organization ? _.upperFirst(organization) : `${_.upperFirst(firstName)} ${_.upperFirst(lastName)}`;
      case constants.LOAN:
        return _.upperFirst(lenderName);
      case constants.REVENUE:
        return _.capitalize(revenueSource);
      default:
        return "";
    }
  };

  const redirectToDetail = (id, event) => {
    if (event.target.tagName !== "INPUT") {
      event.preventDefault();
      navigate(`/land_costs/${id}`);
    }
  };

  return (
    <div
      className={css(
        styles.fieldCard,
        styles.fieldCard_compact,
        styles.fieldCard_Col,
        checked && styles.checkedBackground
      )}
    >
      <Row middle="xs" onClick={redirectToDetail.bind(this, id)}>
        <Col xs md={1}>
          <Checkbox checked={checked} onChange={() => onBulkSelect(id)} value="checkbox" />
        </Col>

        <Col xs md={1}>
          <Link className={css(styles.fieldCard_name, styles.blueText)} to={`/land_costs/${id}`}>
            <strong>{id}</strong>
          </Link>
        </Col>

        <Col xs>
          <p className={css(styles.fieldCard_p)}>
            <AgreementRowSubtype {...{ agreementType, subType, terms }} />
          </p>
        </Col>

        <Col xs>
          <p className={css(styles.fieldCard_p)}>{dateRange}</p>
        </Col>

        <Col xs>
          <p className={css(styles.fieldCard_p)}>{getFormattedList(fields, "name")}</p>
        </Col>

        <Col xs>
          <p className={css(styles.fieldCard_p)}>{getIssuer()}</p>
        </Col>

        <Col xs className={css(styles.rightAlign)}>
          <p className={css(styles.fieldCard_p)}>{agreementSummary}</p>
        </Col>
      </Row>
    </div>
  );
};

AgreementRow.propTypes = {
  agreementType: PropTypes.string.isRequired,
  bulkSelection: PropTypes.array,
  fields: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  issuingPartyName: PropTypes.string,
  maturityDate: PropTypes.string,
  onBulkSelect: PropTypes.func,
  revenueSource: PropTypes.string,
  startDate: PropTypes.string,
  subType: PropTypes.string,
  terms: PropTypes.arrayOf(PropTypes.object),
};

export default AgreementRow;
