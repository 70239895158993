import ScoutingIndexPage from "notes/ScoutingIndexPage";
import NoteContainer from "notes/components/NoteContainer";
import React from "react";

export default [
  {
    children: [
      {
        path: "/scouting",
        element: <ScoutingIndexPage />,
      },

      {
        path: "/scouting/:actionOrId",
        element: <NoteContainer />,
      },
    ],
  },
];
