import React from 'react';
import IconContainer from '../IconContainer';


const ReportsIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M19.633 20.545a3.694 3.694 0 0 1-4.366-5.915 3.692 3.692 0 1 1 5.468 4.897l1.808 1.944a.75.75 0 0 1-1.098 1.021l-1.812-1.947zM2.53 19.548h10.007a.75.75 0 1 1 0 1.5H1.78a.75.75 0 0 1-.75-.749L1 1.751A.75.75 0 0 1 1.75 1l16.782.015a.75.75 0 0 1 .749.75v9.765a.75.75 0 1 1-1.5 0V2.514l-15.28-.013.028 17.047zm16.661-.533a2.191 2.191 0 1 0-3.095-.172 2.192 2.192 0 0 0 3.095.172zM6.35 10.119a.75.75 0 1 1 0-1.5h7.874a.75.75 0 1 1 0 1.5H6.35zm0-3.212a.75.75 0 0 1 0-1.5h7.874a.75.75 0 1 1 0 1.5H6.35zm0 6.296a.75.75 0 1 1 0-1.5h4.624a.75.75 0 1 1 0 1.5H6.35z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

ReportsIcon.iconId = 'reports';


export default ReportsIcon;
