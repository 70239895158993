import { gql } from "@apollo/client";

import { COUNTY_FRAGMENT } from "collection/graphql/prices";

// `crops` and `method` are currently only returned from the REST endpoint.
export default gql`
  fragment Enterprise on Enterprise {
    id
    acreage
    address
    address2
    city
    country
    county {
      ...County
    }
    created
    features
    latlng
    name
    options
    phone
    postalCode
    state
    timezone
    totalAcreage
    useType
    updated
    uuid
  }
  ${COUNTY_FRAGMENT}
`;
