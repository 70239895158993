import React from 'react';
import styled from 'styled-components';
import { border, color, compose, typography } from 'styled-system';
import { useTranslation } from 'react-i18next';

import FileAttachmentCell from './FileAttachmentCell';
import '../../i18n';


const Container = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
  text-decoration: none;
  }

  ${compose(border, color, typography)}
`;
Container.displayName = 'AddFileCellContainer';


const Copy = ({ accept, maxFiles }) => {
  const { t } = useTranslation();
  const acceptImages = accept.some((mimeType) => /^image\//i.test(mimeType));
  const acceptVideos = accept.some((mimeType) => /^video\//i.test(mimeType));

  let i18nKey = 'attach_file_plural';
  if (acceptImages && acceptVideos) {
    i18nKey = 'attach_media';
  } else if (acceptImages) {
    i18nKey = 'attach_image';
  } else if (acceptVideos) {
    i18nKey = 'attach_video';
  }

  return t(i18nKey, {
    count: maxFiles
  });
};

const CustomCell = ({ CustomContainer, ...props }) => CustomContainer ? <CustomContainer {...props} /> : null;
const DefaultCell = ({ accept, CustomContainer, ...containerProps }) => (
  <FileAttachmentCell border="basic">
    <Container {...containerProps}>
      <Copy accept={accept} />
    </Container>
  </FileAttachmentCell>
);


const AddFileCell = ({ accept, canAdd, children, setShowFilePicker }) => {
  const AttachmentCell = children ? CustomCell : DefaultCell;
  const customProps = {
    accept,
    CustomContainer: children,
    color: 'blue',
    fontSize: 'md',
    fontWeight: 'bold',
    href: '#',
    onClick: (event) => {
      event.preventDefault();
      setShowFilePicker(true);
    },
  };

  return !canAdd ? null : <AttachmentCell {...customProps} />;
};


export default AddFileCell;
