/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { number } from "yup";

import { useUserConfig } from "collection/graphql/config";
import useHistoricalRainfall from "collection/graphql/weather/hooks/useHistoricalRainfall";
import useRecentRainfall from "collection/graphql/weather/hooks/useRecentRainfall";
import { FALLOW } from "lib/constants";

// Fallow has an id of -2. Handle down to that number but not below.
const idSchema = number().min(FALLOW).integer().required();

const withPersistence = (WrappedComponent) => (props) => {
  const userConfig = useUserConfig();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const getDefaultFilters = () => ({
    commodities: [],
    groups: [],
    irrigation: [],
    source: [],
    search: "",
    sortCriteria: userConfig("fieldSortCriteria"),
    viewType: userConfig("fieldViewType"),
  });

  const selectedFilters = useMemo(() => {
    const defaultFilters = {
      ...getDefaultFilters(),
      search,
    };

    for (const [key, value] of searchParams.entries()) {
      switch (key) {
        case "commodities":
        case "groups":
          if (idSchema.isValidSync(value)) {
            defaultFilters[key].push(+value);
          }
          break;

        case "sortCriteria":
        case "viewType":
          defaultFilters[key] = value;
          break;

        case "irrigation":
        case "source":
          defaultFilters[key].push(value);
          break;
      }
    }

    return defaultFilters;
  }, [search, searchParams]);

  const hasLast24Loaded = !useRecentRainfall().loading;
  const hasRecentLoaded = !useHistoricalRainfall().loading;
  const rainfallHasLoaded = hasLast24Loaded && hasRecentLoaded;

  useEffect(() => {
    const viewType = userConfig("fieldViewType");
    if (viewType !== selectedFilters.viewType) {
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.set("viewType", viewType);
      setSearchParams(updatedParams);
    }
  }, [userConfig("fieldViewType")]);

  const { fields } = props;
  const onFilterReset = useCallback(() => {
    setSearchParams({}, { replace: true });
  }, []);

  const onFilterUpdate = (updatedFilters) => {
    const updatedParams = new URLSearchParams(searchParams);
    for (const [key, value] of Object.entries(updatedFilters)) {
      updatedParams.delete(key);
      if (key !== "search") {
        [[value]]
          .flat(Infinity)
          .filter((x) => x)
          .forEach((item) => updatedParams.append(key, item));
      }
    }
    setSearchParams(updatedParams, { replace: true });
  };

  const onSearchUpdate = useCallback((search) => setSearch(search), []);

  const onSortUpdate = useCallback(
    (sortCriteria) => {
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.set("sortCriteria", sortCriteria);
      setSearchParams(updatedParams);
    },
    [searchParams]
  );

  const onViewTypeUpdate = useCallback((viewType) => {
    onFilterUpdate({ viewType });
    userConfig("fieldViewType", viewType);
  }, []);

  return (
    <WrappedComponent
      {...props}
      fields={fields}
      rainfallHasLoaded={rainfallHasLoaded}
      onFilterReset={onFilterReset}
      onFilterUpdate={onFilterUpdate}
      onSearchUpdate={onSearchUpdate}
      onSortUpdate={onSortUpdate}
      onViewTypeUpdate={onViewTypeUpdate}
      search={selectedFilters.search}
      selectedFilters={selectedFilters}
      sortCriteria={selectedFilters.sortCriteria}
      viewType={selectedFilters.viewType}
    />
  );
};

export default withPersistence;
