import { useReactiveVar } from "@apollo/client";
import { datadogLogs } from "@datadog/browser-logs";
import { debug } from "debug";
import { useEffect } from "react";
import { useAuth as useSSOAuth } from "react-oidc-context";

import ssoUserVar from "collection/graphql/auth/vars/ssoUserVar";
import useCreateSiemLog from "collection/graphql/logging/hooks/useCreateSiemLog";

const useOIDCEvents = () => {
  const auth = useSSOAuth();
  const { sendLog, buildSiemLogInput } = useCreateSiemLog();
  const logAuth = debug("auth");
  const ssoUser = useReactiveVar(ssoUserVar);

  useEffect(() => {
    if (ssoUser) {
      const callbacks = [];
      callbacks.push(
        // Fires on each new user & token Refresh
        auth.events.addUserLoaded((user) => {
          logAuth("useOIDCEvents.js addUserLoaded", ssoUserVar());
          const existingDdUser = datadogLogs.getUser();
          datadogLogs.setUser({ id: user.profile.bushel_id, name: user.profile.name, email: user.profile.email });

          // also fires on token refresh - don't need those in the logs
          if (!_.isEqual(existingDdUser, datadogLogs.getUser())) {
            sendLog(
              buildSiemLogInput({
                "usr.id": user.profile.bushel_id,
                "usr.name": user.profile.name,
                "usr.email": user.profile.email,
                "evt.name": "bushel_farm.login",
                "evt.outcome": "success",
                message: `User Login/New Session`,
              })
            );
          }
        })
      );

      callbacks.push(
        auth.events.addSilentRenewError((error) => {
          logAuth("AuthEvent SIEM addSilentRenewError", error, datadogLogs.getUser());
          sendLog(
            buildSiemLogInput({
              "evt.name": "bushel_farm.token-refresh",
              "evt.outcome": "failure",
              message: `Token Refresh Failed ${error}`,
            })
          );
        })
      );

      callbacks.push(
        auth.events.addUserUnloaded((user) => {
          logAuth("AuthEvent SIEM addUserUnloaded", user, datadogLogs.getUser());
          sendLog(
            buildSiemLogInput({
              "evt.name": "bushel_farm.logout",
              "evt.outcome": "success",
              message: `User LogOut`,
            })
          );
          datadogLogs.clearUser();
        })
      );

      return () => {
        callbacks.forEach((callback) => callback());
      };
    }
  }, [ssoUser]);
};

export default useOIDCEvents;
