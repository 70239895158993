import React from 'react';
import IconContainer from '../IconContainer';


const WarningIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      fillRule="nonzero"
      fill="currentColor"
    />
  </IconContainer>
);

WarningIcon.iconId = 'warning';


export default WarningIcon;
