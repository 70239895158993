import aar from './AarIcon.js';
import activities from './ActivitiesIcon.js';
import add from './AddIcon.js';
import aerial from './AerialIcon.js';
import arrowDown from './ArrowDownIcon.js';
import arrowLeft from './ArrowLeftIcon.js';
import arrowLeftSmall from './ArrowLeftSmallIcon.js';
import arrowLeftSmallDouble from './ArrowLeftSmallDoubleIcon.js';
import arrowRight from './ArrowRightIcon.js';
import arrowRightSmall from './ArrowRightSmallIcon.js';
import arrowRightSmallDouble from './ArrowRightSmallDoubleIcon.js';
import arrowUp from './ArrowUpIcon.js';
import autohedge from './AutohedgeIcon.js';
import buttonChevronDown from './ButtonChevronDownIcon.js';
import calendar from './CalendarIcon.js';
import camera from './CameraIcon.js';
import candlestick from './CandlestickIcon.js';
import cardsLayout from './CardsLayoutIcon.js';
import check from './CheckIcon.js';
import chevronDown from './ChevronDownIcon.js';
import chevronLeft from './ChevronLeftIcon.js';
import chevronRight from './ChevronRightIcon.js';
import chevronUp from './ChevronUpIcon.js';
import circleAdd from './CircleAddIcon.js';
import circleCheck from './CircleCheckIcon.js';
import circleRemove from './CircleRemoveIcon.js';
import close from './CloseIcon.js';
import collapse from './CollapseIcon.js';
import dismissAll from './DismissAllIcon.js';
import dollar from './DollarIcon.js';
import edit from './EditIcon.js';
import equipment from './EquipmentIcon.js';
import error from './ErrorIcon.js';
import expand from './ExpandIcon.js';
import fields from './FieldsIcon.js';
import flag from './FlagIcon.js';
import harvesting from './HarvestingIcon.js';
import help from './HelpIcon.js';
import imagery from './ImageryIcon.js';
import implementsIcon from './ImplementsIcon.js';
import inputs from './InputsIcon.js';
import irrigation from './IrrigationIcon.js';
import land_costs from './Land_costsIcon.js';
import layers from './LayersIcon.js';
import lineChart from './LineChartIcon.js';
import listLayout from './ListLayoutIcon.js';
import loads from './LoadsIcon.js';
import lock from './LockIcon.js';
import map from './MapIcon.js';
import mapPin from './MapPinIcon.js';
import marketing from './MarketingIcon.js';
import marketingPrices from './MarketingPricesIcon.js';
import menu from './MenuIcon.js';
import newWindow from './NewWindowIcon.js';
import news from './NewsIcon.js';
import notes from './NotesIcon.js';
import notifications from './NotificationsIcon.js';
import overflow from './OverflowIcon.js';
import overflowHorizontal from './OverflowHorizontalIcon.js';
import overflowVertical from './OverflowVerticalIcon.js';
import people from './PeopleIcon.js';
import personWarning from './PersonWarningIcon.js';
import plans from './PlansIcon.js';
import play from './PlayIcon.js';
import print from './PrintIcon.js';
import profitAndLoss from './ProfitAndLossIcon.js';
import rainfall from './RainfallIcon.js';
import remove from './RemoveIcon.js';
import reports from './ReportsIcon.js';
import settings from './SettingsIcon.js';
import signOut from './SignOutIcon.js';
import soil from './SoilIcon.js';
import storage from './StorageIcon.js';
import tune from './TuneIcon.js';
import typeToSearch from './TypeToSearchIcon.js';
import warning from './WarningIcon.js';
import wrench from './WrenchIcon.js';


export default {
  aar,
  activities,
  add,
  aerial,
  arrowDown,
  arrowLeft,
  arrowLeftSmall,
  arrowLeftSmallDouble,
  arrowRight,
  arrowRightSmall,
  arrowRightSmallDouble,
  arrowUp,
  autohedge,
  buttonChevronDown,
  calendar,
  camera,
  candlestick,
  cardsLayout,
  check,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  circleAdd,
  circleCheck,
  circleRemove,
  close,
  collapse,
  dismissAll,
  dollar,
  edit,
  equipment,
  error,
  expand,
  fields,
  flag,
  harvesting,
  help,
  imagery,
  implementsIcon,
  inputs,
  irrigation,
  land_costs,
  layers,
  lineChart,
  listLayout,
  loads,
  lock,
  map,
  mapPin,
  marketing,
  marketingPrices,
  menu,
  newWindow,
  news,
  notes,
  notifications,
  overflow,
  overflowHorizontal,
  overflowVertical,
  people,
  personWarning,
  plans,
  play,
  print,
  profitAndLoss,
  rainfall,
  remove,
  reports,
  settings,
  signOut,
  soil,
  storage,
  tune,
  typeToSearch,
  warning,
  wrench
};
