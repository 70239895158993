import React from 'react';
import IconContainer from '../IconContainer';


const IrrigationIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M12.644 17.84l-.616-4.015-.013-.052a9.587 9.587 0 0 1-.153-.813c-.187-1.24-.218-2.541.01-3.778.213-1.151.64-2.155 1.322-2.956.771-.906 1.8-1.536 3.014-1.933 1.37-.45 2.789-.561 4.01-.489.116.007.2.014.248.02l.547.057.049.57c.004.044.009.12.014.225.061 1.297-.056 2.823-.505 4.299-.32 1.055-.79 1.98-1.432 2.733-.68.798-1.548 1.397-2.553 1.823a9.8 9.8 0 0 1-2.538.674c-.214.029-.416.05-.602.064l.548 3.572h5.928a.68.68 0 0 1 .666.693.68.68 0 0 1-.666.693H3.05a.68.68 0 0 1-.666-.693.68.68 0 0 1 .666-.693h9.595zm.59-4.946c.194-.012.41-.033.646-.064a8.53 8.53 0 0 0 2.203-.583c.827-.35 1.525-.832 2.06-1.458.507-.596.89-1.351 1.157-2.232.345-1.135.47-2.333.457-3.384-.986-.02-2.097.098-3.148.442-1 .327-1.821.83-2.417 1.53-.51.599-.84 1.376-1.01 2.298-.174.937-.172 1.947-.054 2.926.026.058.045.12.055.186l.052.339zM3.05 22a.68.68 0 0 1-.666-.693.68.68 0 0 1 .666-.693h16.873a.68.68 0 0 1 .666.693.68.68 0 0 1-.666.693H3.05zM5.67 4.22c-.325.47-.649.968-.95 1.47-.257.426-.486.837-.683 1.226-.465.924-.715 1.676-.705 2.134.034 1.55 1.094 2.628 2.5 2.591 1.407-.038 2.42-1.174 2.385-2.727-.01-.444-.279-1.156-.762-2.024a18.206 18.206 0 0 0-.74-1.205A28.05 28.05 0 0 0 5.671 4.22zm3.88 4.662c.05 2.31-1.534 4.086-3.684 4.145C3.716 13.083 2.052 11.39 2 9.082c-.017-.756.294-1.692.857-2.81.213-.423.46-.864.733-1.318a30.061 30.061 0 0 1 1.528-2.288L5.62 2l.53.64a29.537 29.537 0 0 1 1.664 2.257c.297.447.565.882.797 1.298.587 1.055.924 1.949.94 2.687zm-3.069.443c-.091.261-.258.435-.458.499a.699.699 0 0 0-.44.866c.107.366.48.572.832.46.612-.196 1.089-.693 1.319-1.35a.701.701 0 0 0-.399-.89.66.66 0 0 0-.854.415z"
      fill="currentColor" fillRule="nonzero"/>

  </IconContainer>
);

IrrigationIcon.iconId = 'irrigation';


export default IrrigationIcon;
