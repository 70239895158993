import PropTypes from "prop-types";
import React, { useState } from "react";
import DataIntegrationsModal from "settings/components/DataIntegrationsModal";
import { List, OrderedList, StyledText, TextBox, UnorderedList } from "settings/styledComponents";

import useFieldMutations from "collection/graphql/fields/hooks/useFieldMutations";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import App from "layout/app";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import PayGateCommonBlankStateModal from "components/advertisements/modals/PayGateCommonBlankStateModal";
import { Button } from "components/fl-ui";
import InlineFilePicker from "components/fl-ui/Attachments/InlineFilePicker";

const FieldBoundariesUpload = ({ onClose }) => {
  const [showSample, setShowSample] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const hasFieldsFeature = useEnterpriseFeature("fields");
  const { importFields } = useFieldMutations();

  if (!hasFieldsFeature) {
    return <PayGateCommonBlankStateModal banner={PayGateAdBanner} onClose={onClose} width={960} />;
  }

  if (showSuccessModal) {
    return (
      <DataIntegrationsModal onClose={onClose} title="Your field boundary import has started">
        <StyledText>
          {`We're processing your files now. Boundary imports typically only take a few minutes. You'll receive an alert once your import is complete.`}
        </StyledText>
      </DataIntegrationsModal>
    );
  }

  return (
    <DataIntegrationsModal onClose={onClose} title="Upload Field Boundaries">
      <StyledText>
        Shapefiles and <code>.KML</code> files are computer files that can contain the boundaries of your fields. You
        can upload them here to import your field boundaries into Bushel Farm.
      </StyledText>

      <OrderedList>
        <List>
          <StyledText>
            <strong>Request your shapefiles from the FSA</strong>
          </StyledText>

          <StyledText>
            {"If you already have your shapefiles, skip to the next step. "}
            {`You can look up the email address for your county FSA office's executive director `}
            <a
              href="https://offices.sc.egov.usda.gov/locator/app?state=us&agency=fsa"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
            {" and send them an email requesting your shapefiles. "}
            <Button link onClick={() => setShowSample(!showSample)}>
              {showSample ? "Hide" : "Show"} sample email
            </Button>
          </StyledText>

          {showSample && (
            <TextBox>
              <StyledText>
                <code>To whom it may concern,</code>
              </StyledText>

              <StyledText>
                <code>
                  {`I would like to request digital "Shapefile" copies of the Common Land Units (CLUs) associated with my production in accordance with the rules and procedures set forth under FSA Handbook 8-CM, Par. 253 C. I look forward to your prompt return of this data.`}
                </code>
              </StyledText>
            </TextBox>
          )}
        </List>

        <List>
          <StyledText>
            <strong>Create a .zip of your shapefiles</strong>
          </StyledText>

          <StyledText>
            {"Follow "}
            <a href="https://www.wikihow.com/Make-a-Zip-File" rel="noopener noreferrer" target="_blank">
              these
            </a>
            {" instructions to create a zip file that you can upload on this page. "}
            Your zip file must have at least one of each of the following:
          </StyledText>

          <UnorderedList>
            <li>
              <StyledText>
                A <code>.shp</code> shapefile
              </StyledText>
            </li>
            <li>
              <StyledText>
                A <code>.shx</code> index file
              </StyledText>
            </li>
            <li>
              <StyledText>
                A <code>.dbf</code> database file
              </StyledText>
            </li>
            <li>
              <StyledText>
                A <code>.prj</code> projection file (if available)
              </StyledText>
            </li>
          </UnorderedList>

          <StyledText>
            Instead of a <code>.zip</code> of files, <code>.kml</code> data files may also be uploaded.
          </StyledText>
        </List>

        <List>
          <StyledText>
            <strong>Upload your zip file</strong>
          </StyledText>

          <InlineFilePicker
            onError={(error) => {
              App.notify("An error occurred, please try again.");
              throw error;
            }}
            onSuccess={({ filesUploaded }) => {
              if (filesUploaded?.length) {
                const { filename: name, handle: filepicker } = filesUploaded[0];

                importFields({ filepickerId: filepicker, fileName: name }).then(
                  () => setShowSuccessModal(true),
                  (error) => {
                    App.notify("An error occurred, please try again.");
                    throw error;
                  }
                );
              }
            }}
            pickerOptions={{ accept: [".kml", "application/zip", "application/x-zip-compressed"] }}
          />
        </List>
      </OrderedList>
    </DataIntegrationsModal>
  );
};

FieldBoundariesUpload.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FieldBoundariesUpload;
