import Layers from "map/Layers";
import { getFieldLayers, sanitizeSoilLayers, sanitizeYieldLayers } from "map/layers/utils";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useImageryLayers from "lib/map/imagery/useImageryLayers";
import useSoilLayers from "lib/map/layers/useSoilLayers";
import useYieldLayers from "lib/map/layers/useYieldLayers";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import useFieldAccess from "fields/hooks/useFieldAccess";

const CURRENT_YEAR = new Date().getFullYear();

const LayersContainer = () => {
  const { crops } = useYearFieldCrops(CURRENT_YEAR);
  const fieldId = +useParams().id;
  const [searchParams] = useSearchParams();
  const defaultLayer = searchParams.get("type") || "";

  const { field } = useFieldAccess(fieldId, "/map");
  const fieldGeometry = field?.geometry;
  const { imageryLayers, loading } = useImageryLayers({ fieldId, imageType: "NDVI" });
  const soilMapsFeatureFlag = !useEnterpriseFeature("soil_maps");
  const { soilLayers } = useSoilLayers({ fieldId, sanitize: sanitizeSoilLayers, skip: soilMapsFeatureFlag });
  const { yieldLayers } = useYieldLayers({ fieldId, sanitize: sanitizeYieldLayers });
  const fieldCrops = crops.filter(({ field }) => field.id === fieldId);
  const allLayers = { fieldLayers: getFieldLayers(field, fieldCrops), imageryLayers, soilLayers, yieldLayers };

  return fieldGeometry ? (
    <Layers
      allLayers={allLayers}
      defaultLayer={defaultLayer}
      geometry={fieldGeometry}
      id={fieldId}
      key="layers"
      loading={loading}
    />
  ) : (
    <LoadingWrapper />
  );
};

export default LayersContainer;
