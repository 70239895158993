import { logoutMobileUser } from "mobile";

import { clearData } from "collection/graphql/auth/lib/clearData";
import currentEnterpriseVar from "collection/graphql/auth/vars/currentEnterpriseVar";
import ssoUserVar from "collection/graphql/auth/vars/ssoUserVar";
import { fetch } from "lib/ajax";

export default async function logout() {
  const ssoUser = ssoUserVar();

  const actions = [];
  actions.push(clearData());
  actions.push(logoutMobileUser());

  actions.push(fetch("/v2.0/users/logout").catch(() => {}));

  if (ssoUser?.access_token) {
    const logOutUrl = new URL(process.env.KEYCLOAK_LOGOUT_URL);
    logOutUrl.searchParams.set("id_token_hint", ssoUser?.id_token);
    // wait for logout keycloak before app logout
    await fetch(logOutUrl, { mode: "no-cors" }).catch(() => {});
    // logout bushel farm
    actions.push(Promise.resolve(ssoUserVar(null)));
    actions.push(Promise.resolve(currentEnterpriseVar(null)));
  }

  await Promise.allSettled(actions);
}
