"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
exports.default = (0, client_1.gql) `
  mutation createEnterprise($input: CreateEnterpriseInput!) {
    createEnterprise(input: $input) @rest(type: "CreateEnterpriseResponse", path: "/users/register/enterprise", method: "POST") {
      enterpriseId
    }
  }
`;
