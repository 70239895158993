import React from 'react';
import IconContainer from '../IconContainer';


const FlagIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M4.378 3.58c8.856-5.62 4.157 5.936 16.776 1.142.416-.16.749.098.502.439-10.223 14.097-7.148 2.456-14.801 7.76l2.05 7.731a.637.637 0 0 1-.51.743.637.637 0 0 1-.75-.498L3.011 3.425a.637.637 0 0 1 .509-.743.637.637 0 0 1 .75.498l.107.4zm2.196 8.216c7.59-4.836 5.2 4.268 13.457-5.84-10.049 4.55-8.815-6.616-15.36-1.105l1.903 6.945z"
      fill="currentColor"
      fillRule="nonzero"
    />

  </IconContainer>
);

FlagIcon.iconId = 'flag';


export default FlagIcon;
