import React from 'react';
import IconContainer from '../IconContainer';


const AutohedgeIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M17.195 15.612v1.444c1.716.36 2.476.903 2.476 1.991 0 1.249-.754 1.634-2.476 1.702v.643a.573.573 0 0 1-1.146 0v-.65c-.982-.078-1.812-.388-2.419-.855a.573.573 0 0 1 .699-.908c.412.317.998.542 1.72.612v-1.587c-1.514-.312-2.396-.794-2.396-1.732 0-1.333.817-1.764 2.396-1.832v-.839a.573.573 0 0 1 1.146 0v.86c.726.068 1.443.248 2.032.517a.573.573 0 0 1-.476 1.042 5.255 5.255 0 0 0-1.556-.408zm-1.146-.026c-.926.043-1.25.23-1.25.686 0 .037.107.146.406.283.223.101.508.193.844.274v-1.243zm1.146 4.017c1.027-.04 1.33-.198 1.33-.556 0-.343-.32-.59-1.33-.82v1.376zM17.18 12h-1.8l.537-.848-3.932-.284a.755.755 0 0 1-.709-.762l.12-5.64-5.315 8.382 3.932.284c.406.03.717.364.709.762l-.12 5.64L12 17.33v2.839l-1.57 2.476c-.41.647-1.431.35-1.415-.41l.163-7.662-4.469-.323c-.573-.042-.896-.664-.593-1.142l7.455-11.753c.41-.647 1.43-.35 1.414.41l-.163 7.662 4.469.323c.573.042.896.664.593 1.142L17.181 12z"
      fill="currentColor" fillRule="nonzero"/>

  </IconContainer>
);

AutohedgeIcon.iconId = 'autohedge';


export default AutohedgeIcon;
