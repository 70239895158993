import React, { useEffect, useMemo, useState } from 'react';
import { isSameDay } from 'date-fns';

import { normalizeDate } from '../utils';


const useDisplayedDate = (value) => {
  const valueAsDate = useMemo(() => normalizeDate(value) ?? new Date(), [value]);
  const [ displayedDate, setDisplayedDate ] = useState(valueAsDate);

  useEffect(() => {
    if (valueAsDate && !isSameDay(displayedDate, valueAsDate)) {
      setDisplayedDate(valueAsDate);
    }
  }, [valueAsDate.toISOString()]);


  return [displayedDate, setDisplayedDate];
};


export default useDisplayedDate;
