import React from 'react';
import IconContainer from '../IconContainer';


const ArrowLeftSmallDoubleIcon = (props) => (
  <IconContainer {...props} viewBox="-4 -8 24 24">
      <mask id="a" fill="#fff">
        <path d="m0 0h24v24h-24z" fill="#fff" fillRule="evenodd"/>
      </mask>
      <path d="m16.01 8 3.99 4-3.99 4v-2.006l1.99-1.994-1.99-1.994zm-4 0 3.99 4-3.99 4v-3h-7.01v-2h7.01z"
            fill="currentColor"
            mask="url(#a)" transform="matrix(-1 0 0 1 20 -8)"/>
  </IconContainer>
);

ArrowLeftSmallDoubleIcon.iconId = 'arrowLeftSmallDouble';


export default ArrowLeftSmallDoubleIcon;
