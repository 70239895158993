import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useEffect, useMemo } from "react";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";
import { useAuth } from "collection/graphql/auth";
import { getAllPeople } from "collection/graphql/people/queries";
import getCurrentSubscription from "collection/graphql/subscription/getCurrentSubscription";
import useBackboneModel from "hooks/useBackboneModel";
import useConnectivityChange from "hooks/useConnectivityChange";
import useForceUpdate from "hooks/useForceUpdate";
import useMarketingAdvisor from "hooks/useMarketingAdvisor";
import useReleaseFeature from "hooks/useReleaseFeature";
import useRestQuery from "hooks/useRestQuery";
import Config from "lib/config";
import EventChannel from "lib/events";
import { BUSINESS_TRIAL } from "model/Subscription/constants";

import { ColorPalette } from "components/fl-ui/colors";

const usePrimaryBanner = () => {
  const isOnline = useConnectivityChange();
  const feature = useReleaseFeature("announcement-banner") || {};
  const maintenance = useReleaseFeature("maintenance-mode") || {};
  const forceUpdate = useForceUpdate();
  const { currentUser } = useAuth();
  const { isMarketingAdvisor } = useMarketingAdvisor();

  //TODO: This "Config" thing is literally only used in this file,
  // if we can remove it, we could delete that file completely and be a big step closer to removing Backbone
  // I created a ticket for this: https://bushel.atlassian.net/browse/FARM-9806
  useBackboneModel(Config);

  const offlineMessage = {
    id: "offline",
    priority: 0,
    message: "You are currently offline. Some actions, such as saving data, will be unavailable.",
  };

  const { data } = useRestQuery(getAllPeople);
  const oldestAdminName = useMemo(() => {
    const admins = _.filter(data?.people, { role: "admin" });
    const oldestAdmin = _.sortBy(admins, "created").shift();

    return oldestAdmin?.name ?? "--";
  }, [data]);

  const advisorConfig = useFarmAdvisorConfig().data;
  const customerListUrl = advisorConfig.isEnabled ? advisorConfig.urls.customerManagement : "";
  const viewingUser = {
    backgroundColor: ColorPalette.blue,
    id: "viewing-user",
    priority: 3,
    message: `Viewing User: **${oldestAdminName}** &#x2022; [Return to Customer List](${customerListUrl}/customers)`,
  };

  const { data: subscriptionData } = useQuery(getCurrentSubscription);
  const currentSubscription = subscriptionData?.subscription;
  const daysRemaining = currentSubscription?.daysRemaining;
  const trialDurationPhrase = () => {
    if (daysRemaining < 1) {
      return "today";
    } else if (daysRemaining === 1) {
      return "in 1 day";
    } else {
      return `in ${daysRemaining} days`;
    }
  };
  const businessTrialMessage = `Your free trial ends ${trialDurationPhrase()}. [Click here](/#/billing) to choose which subscription is right for you.`;
  const businessTrial = {
    backgroundColor: ColorPalette.blue,
    id: "business-trial",
    priority: 4,
    message: businessTrialMessage,
  };
  const shouldShowBusinessTrialBanner = currentSubscription?.currentPlan?.id === BUSINESS_TRIAL && daysRemaining <= 7;

  const bannerProps = _.chain([offlineMessage, feature, maintenance, viewingUser, businessTrial])
    .filter(({ id }) => id)
    .reject(({ id }) => id === "offline" && isOnline)
    .reject(({ id }) => id === "viewing-user" && !currentUser)
    .reject(({ id }) => id === "viewing-user" && !!currentUser && !isMarketingAdvisor)
    .reject(({ id }) => id === "business-trial" && !shouldShowBusinessTrialBanner)
    .reject(({ dismissKey }) => dismissKey && !!Config.get(dismissKey))
    .sortBy("priority")
    .head()
    .value();

  useEffect(() => {
    const modalChannel = EventChannel.getChannel("modal");
    const callbacks = [modalChannel.on("open", forceUpdate), modalChannel.on("close", forceUpdate)];

    return () => {
      callbacks.forEach((callback) => callback());
    };
  }, []);

  return {
    backgroundColor: bannerProps?.backgroundColor || ColorPalette["dark-blue"],
    bannerProps: bannerProps || null,
    handleClose: () => Config.set(bannerProps.dismissKey, true).save(),
    showClose: !!bannerProps?.dismissKey,
  };
};

export default usePrimaryBanner;
