"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const EnterpriseTypePolicy = {
    fields: {
        options: {
            read(cachedValue) {
                return cachedValue || {};
            },
        },
    },
};
exports.default = EnterpriseTypePolicy;
