import PropTypes from 'prop-types';
import styled from 'styled-components';


const FileAttachmentContainer = styled.div`
  --cell-size: ${props => props.cellSize}px;
  display: flex;
  flex-flow: row wrap;

  > * {
    margin: 0.5rem;

    :first-child {
      margin-left: 0;
    }
  }
`;

FileAttachmentContainer.displayName = 'FileAttachmentContainer';
FileAttachmentContainer.propTypes = {
  cellSize: PropTypes.number.isRequired
};


export default FileAttachmentContainer;
