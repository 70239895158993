import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { Capacitor, CapacitorCookies } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Geolocation } from "@capacitor/geolocation";
import { Preferences } from "@capacitor/preferences";
import { Share } from "@capacitor/share";
import { SplashScreen } from "@capacitor/splash-screen";
import { getAppRedirectUrl } from "app/getAppRedirectUrl";
import { oneSignalConfigure, oneSignalLogIn, oneSignalLogOut, oneSignalOptIn } from "mobile/mobileNotifications";
import semverGte from "semver/functions/gte";

export const appAddResumeListener = (callback) => {
  return App.addListener("resume", () => {
    callback();
  });
};

export const appState = async () => {
  return App.getState();
};

export const appVersion = async () => {
  if (await isFullWeb()) {
    const appInfo = await App.getInfo();
    return appInfo.version;
  } else {
    // old capacitor versions can all return 4.0.0
    return "4.0.0";
  }
};

export const browserOpenUrl = async (url) => {
  return Browser.open({ url: url });
};

export const cookiesClearAll = async () => {
  await CapacitorCookies.clearAllCookies();
};

export const geolocationClearWatch = async (callbackId) => {
  return Geolocation.clearWatch({ id: callbackId });
};

export const geolocationWatchPosition = async (options, callback) => {
  return Geolocation.watchPosition(options, callback);
};

export const deviceGetInfo = async () => {
  return Device.getInfo();
};

export const directoryCache = () => {
  return Directory.Cache;
};

export const fileRmdir = async (options) => {
  return Filesystem.rmdir(options);
};

export const fileWrite = async (options) => {
  return Filesystem.writeFile(options);
};

/**
 * @return {"android"|"ios"|"web"}
 */
export const getPlatform = () => {
  return Capacitor.getPlatform();
};

export const isFullWeb = async () => {
  // App version which removed custom bushel farm plugin in favor of using all official plugins
  const fullWebVersion = "7.1.0";

  try {
    const appInfo = await App.getInfo();
    return semverGte(appInfo.version, fullWebVersion);
  } catch {
    // old capacitor 2 version
    return false;
  }
};

export const isNative = () => Capacitor.isNativePlatform();

export const logoutUser = async () => {
  oneSignalLogOut();
  await CapacitorCookies.clearAllCookies();
  Preferences.clear();
};

export const mobileAppConfigureOnLaunch = async () => {
  if (isNative()) {
    oneSignalConfigure();
    SplashScreen.hide();
    await setupDeepLinkListeners();
  }
};

const setupDeepLinkListeners = async () => {
  App.addListener("appUrlOpen", (data) => {
    const appRedirectUrl = getAppRedirectUrl(data.url);
    if (appRedirectUrl) {
      window.location.replace(appRedirectUrl);
    }
  });
};

export const mobileNotificationLogIn = async (currentUser) => {
  if (!isNative()) {
    throw new Error("Not implemented on web");
  }

  return oneSignalLogIn(currentUser);
};

export const requestNotificationPermission = async () => {
  oneSignalOptIn();
};

export const share = async (options) => {
  Share.share(options);
};
