import { BaseElement } from "components/fl-ui/common";
import styledPropTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { grid } from "styled-system";

import { Provider as FormGroupContextProvider } from "components/fl-ui/Form/hooks/useFormGroupContext";
import { IconContainer } from "components/fl-ui/Icons";
import Tooltip from "components/fl-ui/Tooltip";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

export const FormGroupError = styled.div`
  color: ${UIColors.red};
  font-size: ${Typography.sizes.mn};
`;

const FormGroupComponent = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  gap: ${(props) => (props.horizontal ? Spacing.regular : Spacing.xsmall)};
  // TODO remove margin and instead rely on the container for vertical spacing
  margin: 0 0 ${Spacing.medium};

  ${(props) => props.horizontal && "align-items: center;"}
  ${(props) => props.stretch && "grid-column: 1 / -1;"}

  @media only screen and (max-width: 62.5rem) {
    flex-direction: column;
  }

  ${grid}
`;

const Header = styled(({ className, inputId, label, optional, tip }) => {
  const renderLabelTag = typeof label === "string";
  const tag = renderLabelTag ? "label" : "div";
  const htmlFor = renderLabelTag && inputId ? inputId : undefined;

  return (
    <div className={className}>
      <BaseElement as={tag} htmlFor={htmlFor}>
        {label}
      </BaseElement>

      {optional && <span className="optional">Optional</span>}
      {tip && (
        <Tooltip message={tip}>
          <IconContainer icon="help" size="regular" />
        </Tooltip>
      )}
    </div>
  );
})`
  align-items: center;
  display: flex;
  font-size: ${Typography.sizes.rg};
  gap: ${Spacing.xxsmall};

  > :first-child {
    color: ${Typography.colors.primary};
    font-weight: ${Typography.weights.medium};
    line-height: 1.375;
  }

  > :last-child:not(:first-child):not(.optional) {
    display: flex;
  }

  > .optional {
    color: ${UIColors.medium};
    font-weight: ${Typography.weights.light};
    font-size: 0.92em;
  }
`;

const FormGroup = ({ children, error, inputId, label, optional, tip, ...rest }) => {
  const formGroupContext = { error, hasError: !!error };

  return (
    <FormGroupContextProvider value={formGroupContext}>
      <FormGroupComponent {...rest}>
        {label && <Header inputId={inputId} label={label} optional={optional} tip={tip} />}
        {children}
        {error && <FormGroupError>{error}</FormGroupError>}
      </FormGroupComponent>
    </FormGroupContextProvider>
  );
};

FormGroup.displayName = "FormGroup";
FormGroup.propTypes = {
  error: PropTypes.any,
  horizontal: PropTypes.bool,
  inputId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  optional: PropTypes.bool,
  stretch: PropTypes.bool,
  tip: PropTypes.string,
  ...styledPropTypes.grid,
};
FormGroup.defaultProps = {
  horizontal: false,
  optional: false,
  stretch: false,
};

export default FormGroup;
