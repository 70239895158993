import React from 'react';
import IconContainer from '../IconContainer';


const RemoveIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1012.000000, -89.000000)">
        <g transform="translate(523.000000, 89.000000)">
          <g transform="translate(12.000000, 0.000000)">
            <g transform="translate(477.000000, 0.000000)">
              <polygon points="0 0 24 0 24 24 0 24"/>
              <circle fill="#FFFFFF" cx="12" cy="12" r="12"/>
              <path
                d="M9.17157288,7.75735931 L7.75735931,9.17157288 L14.8284271,16.2426407 L16.2426407,14.8284271 L9.17157288,7.75735931 Z M7.75735931,14.8284271 L9.17157288,16.2426407 L16.2426407,9.17157288 L14.8284271,7.75735931 L7.75735931,14.8284271 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z"
                fill="currentColor" fillRule="nonzero"/>
            </g>
          </g>
        </g>
      </g>
    </g>

  </IconContainer>
);

RemoveIcon.iconId = 'remove';


export default RemoveIcon;
