import React from 'react';
import IconContainer from '../IconContainer';


const TuneIcon = (props) => (
  <IconContainer {...props}>
    <g id="tune" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M3,17 L3,19 L9,19 L9,17 L3,17 Z M3,5 L3,7 L13,7 L13,5 L3,5 Z M13,21 L13,19 L21,19 L21,17 L13,17 L13,15 L11,15 L11,21 L13,21 Z M7,9 L7,11 L3,11 L3,13 L7,13 L7,15 L9,15 L9,9 L7,9 Z M21,13 L21,11 L11,11 L11,13 L21,13 Z M15,9 L17,9 L17,7 L21,7 L21,5 L17,5 L17,3 L15,3 L15,9 Z"
          id="Shape" fill="currentColor" fillRule="nonzero"></path>
      </g>
    </g>

  </IconContainer>
);

TuneIcon.iconId = 'tune';


export default TuneIcon;
