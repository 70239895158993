import InventoryCollectionsLoader from "inventory/components/InventoryCollectionsLoader";
import Loads from "loads/index";
import React from "react";

export default [
  {
    path: "/loads",
    element: (
      <InventoryCollectionsLoader>
        <Loads />
      </InventoryCollectionsLoader>
    ),
  },
];
