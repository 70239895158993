"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const getUserNotifications = (0, client_1.gql) `
  query getUserNotifications {
    notifications: getUserNotifications {
      body
      created
      data
      id
      title
      type
      user {
        id
      }
    }
  }
`;
exports.default = getUserNotifications;
