"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_BUSINESS_TRIAL = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_BUSINESS_TRIAL = (0, client_1.gql) `
  mutation createBusinessTrial {
    createBusinessTrial(input: {})
      @rest(method: "POST", path: "/subscriptions/subscription/start_trial") {
      noResponseExpected
    }
  }
`;
