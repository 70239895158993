import { datadogLogs } from "@datadog/browser-logs";
import _ from "lodash";
import MobileForcedAppUpdateGuard from "mobile/MobileForcedAppUpdateGuard";
import React, { useEffect, useRef } from "react";
import { useAuth as useSSOAuth } from "react-oidc-context";
import { Outlet } from "react-router-dom";

import { useAuth } from "collection/graphql/auth";
import GlobalContextProvider from "context/GlobalContextProvider";
import { SSOContextProvider } from "context/SSOContext";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import EventChannel from "lib/events";

const OldApplicationLogicWrapper = () => {
  const auth = useSSOAuth();
  const { isLoggedIn, isLoggingOut, loading: authIsLoading } = useAuth();

  // legacy auth enterprise
  const { currentEnterprise, loading: enterpriseLoading } = useCurrentEnterprise();
  const lastEnterpriseId = useRef();
  const hasEnterpriseChanged = !enterpriseLoading && lastEnterpriseId.current !== currentEnterprise?.id;

  if (hasEnterpriseChanged) {
    lastEnterpriseId.current = currentEnterprise?.id;
  }

  const isSyncing = [authIsLoading, isLoggingOut, enterpriseLoading && !lastEnterpriseId.current].some((x) => x);

  useEffect(() => {
    if (!auth.isLoading) {
      if (auth.isAuthenticated) {
        // TODO move this to useAnalyticsProvider
        if (_.isEqual({}, datadogLogs.getUser())) {
          datadogLogs.setUser({
            id: auth.user.profile.bushel_id,
            name: auth.user.profile.name,
            email: auth.user.profile.email,
          });
        }
      }
      EventChannel.getChannel("app").fire("ready");
    }
  }, [auth.isAuthenticated, auth.isLoading, isSyncing, isLoggedIn]);

  return <Outlet />;
};

const Application = () => {
  return (
    <GlobalContextProvider>
      <MobileForcedAppUpdateGuard>
        <SSOContextProvider>
          <OldApplicationLogicWrapper />
        </SSOContextProvider>
      </MobileForcedAppUpdateGuard>
    </GlobalContextProvider>
  );
};

export default Application;
