import { css } from "aphrodite/no-important";
import { SalesEmailLink, SalesPhoneLink } from "billing/lib";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "components/advertisements/styles";
import { Button } from "components/fl-ui";

const PayGateCommonBlankSlateBanner = (props) => {
  const { actionButtonText, secondaryText, children, imagePath, size, title } = props;

  const navigate = useNavigate();
  const onClick = () => navigate("/billing");

  const SupportText = () => (
    <p className={css(styles.promo_text, styles.centered_on_mobile)}>
      Need help? Contact our support team at <SalesPhoneLink />, or email us at <SalesEmailLink />.
    </p>
  );

  return (
    <div className={css(styles.banner_container)}>
      <div className={css(styles.banner_content)}>
        <div className={css(styles.banner_textContainer, size === "sm" && styles.banner_textContainer_sm)}>
          <h1 className={css(styles.promo_h2, styles.centered_on_mobile)}>{title}</h1>

          {children}

          <Button className={css(styles.promo_cta, styles.centered_on_mobile)} color="primary" onClick={onClick}>
            {actionButtonText}
          </Button>
          {secondaryText && <p className={css(styles.secondaryText)}>{secondaryText}</p>}

          {size === "lg" && <SupportText />}
        </div>

        <div className={css(styles.banner_imgContainer, size === "sm" && styles.banner_imgContainer_sm)}>
          <img className={css(styles.banner_img)} src={imagePath} />
        </div>
      </div>
    </div>
  );
};

PayGateCommonBlankSlateBanner.propTypes = {
  actionButtonText: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  imagePath: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  size: PropTypes.oneOf(["lg", "sm"]),
  title: PropTypes.string.isRequired,
};

PayGateCommonBlankSlateBanner.defaultProps = {
  size: "lg",
};

export default PayGateCommonBlankSlateBanner;
