import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button from '../Button';
import Calendar from './Calendar';
import CalendarHeader from './CalendarHeader';

const ButtonContainer = styled.div`
  button {
    justify-content: center;
    margin-top: 5px;
    width: 100%;
  }
`;

const FullCalendar = ({ displayedDate, hideCalendar, max, min, onChange, setDisplayedDate, value }) => {
  const handleCalendarSelect = (date) => {
    hideCalendar();
    onChange(date);
  };
  
  const handleClearDate = () => {
   hideCalendar();
   onChange(undefined);
  }

  return (
    <div>
      <CalendarHeader
        date={displayedDate}
        onChange={setDisplayedDate}
      />

      <Calendar
        displayedDate={displayedDate}
        max={max}
        min={min}
        onChange={handleCalendarSelect}
        selectedDate={value}
      />

      <ButtonContainer>
          {value && (
              <Button variant="secondary" type="button" onClick={handleClearDate}>
                Clear
              </Button>
            )
          }
      </ButtonContainer>
    </div>
  );
};

FullCalendar.propTypes = {
  displayedDate: PropTypes.instanceOf(Date).isRequired,
  hideCalendar: PropTypes.func.isRequired,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  setDisplayedDate: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date)
};

export default FullCalendar;
