import React from 'react';
import IconContainer from '../IconContainer';


const AerialIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(4.000000, 4.000000)">
        <path
          d="M9.83333333,9.34676212 L9.83333333,12.0833333 L11.1666667,13.0833333 L11.1666667,14.9961983 L8.33333333,14.1866745 L5.5,14.9961983 L5.5,13.0833333 L6.83333333,12.0833333 L6.83333333,9.34676212 L1.5,11.0134288 L1.5,8.72287618 L6.83333333,5.38954285 L6.83333333,2 C6.83333333,1.17052429 7.50385763,0.5 8.33333333,0.5 C9.16280904,0.5 9.83333333,1.17052429 9.83333333,2 L9.83333333,5.38954285 L15.1666667,8.72287618 L15.1666667,11.0134288 L9.83333333,9.34676212 Z"
          id="Path" stroke="none" fill="currentColor"></path>
        <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
      </g>
    </g>

  </IconContainer>
);

AerialIcon.iconId = 'aerial';


export default AerialIcon;
