import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { marketingClient } from "collection/graphql/client";
import { fetch } from "lib/ajax";
import { LOGIN_LOGIN } from "lib/metrics/events";

/**
 *
 * @param {String} email
 * @param {String} password
 * @return {Promise<Response>}
 */
export default function login(email, password) {
  const options = {
    body: JSON.stringify({ email, password }),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  };

  return fetch("/v2.0/users/login", options).then(async (response) => {
    const { error } = await response.json();
    if (!response.ok) {
      return Promise.reject(new Error(error));
    }

    /*
     * We already have the user data in the response to the login request, but it's not camel-cased.
     * Making the GET_CURRENT_USER query here allows us to avoid doubling up on camel-casing the
     * user (restQuery is already handling this automatically).
     * But it's worth considering manually setting the GET_CURRENT_USER response here if
     * we determine it would have a positive impact on performance.
     */
    const { data } = await marketingClient.query({ query: GET_CURRENT_USER, fetchPolicy: "network-only" });
    const { currentUser } = data;
    LOGIN_LOGIN.track({ email: currentUser.email, outcome: "success" });

    return currentUser;
  });
}
