import { DatePicker, BaseElement } from "components/fl-ui/common";
import PurchaseInputChooser from "activity/components/choosers/PurchaseInputChooser";
import PropTypes from "prop-types";
import React from "react";

import useViewType from "hooks/useViewType";

import { Form, FormGroup, Input } from "components/fl-ui/Form";

const ProductPurchaseForm = (props) => {
  const { formFields, formId, onChange, onFocus, onSubmit } = props;
  const { date, id, inputs, vendor } = formFields;
  const isEdit = !!id;
  const isMobile = useViewType() === "mobile";
  const changeFor = (fieldName) => (value) => onChange({ ...formFields, [fieldName]: value });

  return (
    <Form id={formId} onFocus={onFocus} onSubmit={() => onSubmit(formFields)} noValidate preventDefault>
      <BaseElement display="grid" gridTemplateColumns={`repeat(${isMobile ? 1 : 2}, 1fr)`}>
        <FormGroup label="Purchase date">
          <DatePicker onChange={changeFor("date")} value={date} />
        </FormGroup>

        <FormGroup label="Vendor (optional)">
          <Input name="vendor" onChange={(event, { vendor }) => onChange({ ...formFields, vendor })} value={vendor} />
        </FormGroup>
      </BaseElement>

      {isEdit && <PurchaseInputChooser inputs={inputs} onChange={changeFor("inputs")} isEdit />}

      {!isEdit && (
        <FormGroup label="Input(s)">
          <PurchaseInputChooser inputs={inputs} onChange={changeFor("inputs")} />
        </FormGroup>
      )}
    </Form>
  );
};

ProductPurchaseForm.propTypes = {
  formFields: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

ProductPurchaseForm.defaultProps = {
  onFocus: () => {},
};

export default ProductPurchaseForm;
