import React from 'react';
import IconContainer from '../IconContainer';


const ExpandIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <polygon points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M12,5.83 L15.17,9 L16.58,7.59 L12,3 L7.41,7.59 L8.83,9 L12,5.83 Z M12,18.17 L8.83,15 L7.42,16.41 L12,21 L16.59,16.41 L15.17,15 L12,18.17 Z"
          fill="currentColor" fillRule="nonzero"></path>
      </g>
    </g>

  </IconContainer>
);

ExpandIcon.iconId = 'expand';


export default ExpandIcon;
