import React from "react";

import ImageryDashboard from "modules/imagery/pages/ImageryDashboard";

export default [
  {
    path: "/imagery",
    element: <ImageryDashboard />,
  },
];
