import { DatePicker } from "components/fl-ui/common";
import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import { upperFirst } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import ReactSelect from "react-select";
import { Col, Row } from "react-styled-flexboxgrid";
import { CheckboxLabel, ImportDescription } from "settings/styledComponents";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useReleaseFeature from "hooks/useReleaseFeature";

import { Checkbox, ClearAllFormGroup, TagSelectGroup } from "components/fl-ui/Form";

const getCategoriesData = (data) =>
  data?.reduce(
    (categoryData, { category, enabled }) => {
      const option = { category, label: category, value: category };
      categoryData[0].push(option);
      if (!enabled) {
        categoryData[1].push(option);
      }
      return categoryData;
    },
    [[], []]
  );

const getFieldsData = (data) =>
  data?.reduce(
    (fieldData, { enabled, field }) => {
      fieldData[0].push(field);
      if (!enabled) {
        fieldData[1].push(field);
      }
      return fieldData;
    },
    [[], []]
  );

const ImportConfig = ({
  automaticFieldImportText,
  automaticModalCopy,
  data,
  isAutomatic,
  manualFieldImportText,
  updateData,
}) => {
  const { formDefaults } = data;
  const { activityTypeCategories, fields, ...defaults } = formDefaults;
  const [categoryOptions, excludedCategories] = useMemo(
    () => getCategoriesData(activityTypeCategories),
    [activityTypeCategories]
  );
  const [fieldOptions, excludedFields] = useMemo(() => getFieldsData(fields), [fields]);
  const [formData, setFormData] = useState({
    ...defaults,
    categories: excludedCategories,
    selectedFields: excludedFields,
  });
  const {
    categories,
    enableAutomaticFieldImport,
    enableAutomaticImport,
    endDate,
    importActivities,
    importField,
    selectedFields,
    startDate,
  } = formData;
  const handleChange = (newData) => setFormData((formData) => ({ ...formData, ...newData }));
  const hasManualImportFeature = useReleaseFeature("manual-import");
  const hasMachineData = useEnterpriseFeature("machine_data");

  useEffect(() => {
    const activityTypeCategories = categoryOptions.map(({ category }) => ({
      category,
      enabled: !categories.some(({ value }) => value === category),
    }));
    const fields = fieldOptions.map(({ id }) => ({ enabled: !selectedFields?.some((field) => id === field.id), id }));
    const formFields = isAutomatic
      ? { activityTypeCategories, enableAutomaticFieldImport, enableAutomaticImport, fields, id: formDefaults.id }
      : { endDate, startDate, importField, importActivities };

    updateData({ ...data, formFields });
  }, [formData]);

  return (
    <>
      {isAutomatic && (
        <Col>
          {hasManualImportFeature && hasMachineData && (
            <Row>
              <CheckboxLabel htmlFor="enableAutomaticFieldImport">
                <Checkbox
                  checked={!!enableAutomaticFieldImport}
                  id="enableAutomaticFieldImport"
                  name="enableAutomaticFieldImport"
                  onChange={(e, data) => handleChange(data)}
                />

                <strong>Automatically import new field boundaries</strong>
              </CheckboxLabel>

              <ImportDescription>{automaticFieldImportText}</ImportDescription>
            </Row>
          )}

          <Row>
            <CheckboxLabel htmlFor="enableAutomaticImport">
              <Checkbox
                checked={!!enableAutomaticImport}
                id="enableAutomaticImport"
                name="enableAutomaticImport"
                onChange={(e, data) => handleChange(data)}
              />

              <strong>Automatically import new activities</strong>
            </CheckboxLabel>

            <ImportDescription>{automaticModalCopy}</ImportDescription>
          </Row>

          <Row style={{ lineHeight: 1.3, marginBottom: "1rem", marginLeft: "0.8rem" }}>
            <ClearAllFormGroup
              label="Never import activity data for these fields"
              onClear={() => handleChange({ selectedFields: [] })}
            >
              <ReactSelect
                closeMenuOnSelect={false}
                getOptionLabel={({ name }) => upperFirst(name)}
                getOptionValue={({ id }) => id}
                isMulti
                onChange={(selectedFields) => handleChange({ selectedFields })}
                options={fieldOptions}
                placeholder="Type to search..."
                styles={chooserStyles}
                value={selectedFields}
              />
            </ClearAllFormGroup>
            <TagSelectGroup
              id="activityTypes"
              isMulti
              label="Never import data for these activity types"
              onChange={(categories) => handleChange({ categories })}
              options={categoryOptions}
              preserveOptions
              showBulkSelect
              value={categories}
            />
          </Row>
        </Col>
      )}

      {!isAutomatic && (
        <Col>
          <Row>
            <CheckboxLabel htmlFor="importField">
              <Checkbox
                checked={!!importField}
                id="importField"
                name="importField"
                onChange={(e, data) => handleChange(data)}
              />

              <strong>Import field boundaries</strong>
            </CheckboxLabel>

            <ImportDescription>{manualFieldImportText}</ImportDescription>
          </Row>

          <Row>
            <CheckboxLabel htmlFor="importActivities">
              <Checkbox
                checked={!!importActivities}
                id="importActivities"
                name="importActivities"
                onChange={(e, data) => handleChange(data)}
              />

              <strong>Import activities from a selected data range</strong>
            </CheckboxLabel>
          </Row>
          <Row style={{ marginLeft: "0.15rem" }}>
            <Col xs={12} md={6}>
              <ClearAllFormGroup label="Start date" onClear={() => handleChange({ startDate: null })}>
                <Row>
                  <Col xs={4} md={8}>
                    <DatePicker
                      max={endDate ?? new Date()}
                      onChange={(startDate) => handleChange({ startDate })}
                      value={startDate}
                    />
                  </Col>
                </Row>
              </ClearAllFormGroup>
            </Col>

            <Col xs={12} md={6}>
              <ClearAllFormGroup label="End date" onClear={() => handleChange({ endDate: null })}>
                <Row>
                  <Col xs={4} md={8}>
                    <DatePicker
                      max={new Date()}
                      min={startDate}
                      onChange={(endDate) => handleChange({ endDate })}
                      value={endDate}
                    />
                  </Col>
                </Row>
              </ClearAllFormGroup>
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
};

export default ImportConfig;
