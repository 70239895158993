import _ from 'lodash';
import { isDate, parse, parseISO, isValid } from 'date-fns';


/**
 * Converts a value into an instance of Date. If the value is invalid null is returned.
 * @param {*} value
 * @return {Date|null}
 */
export const normalizeDate = (value) => {
  let date = parseISO(value);

  const datePattern = /^\d{1,2}\/\d{1,2}\/(\d{2,4})$/;  // month/day/year, short or long versions. extracts the year.
  if (datePattern.test(value)) {
    const year = datePattern.exec(value)[1];
    const format = 'MM/dd/' + _.repeat('y', _.size(year));
    date = parse(value, format, new Date());
  } else if (isDate(value)) {
    return value;
  }

  return isValid(date) ? date : null;
};


/**
 * Returns true if the current browser/environment supports the date input.
 * @return {Boolean}
 */
export const supportsDateInput = () => {
  const input = document.createElement('input');
  const value = 'a';
  input.setAttribute('type', 'date');
  input.setAttribute('value', value);
  return (input.value !== value);
};
