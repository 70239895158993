import React from 'react';
import IconContainer from '../IconContainer';


const AddIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M13.25 11.75h7a.75.75 0 1 1 0 1.5h-7v7a.75.75 0 1 1-1.5 0v-7h-7a.75.75 0 0 1 0-1.5h7v-7a.75.75 0 0 1 1.5 0v7z"
      fill="currentColor"
      fillRule="nonzero"
    />

  </IconContainer>
);

AddIcon.iconId = 'add';


export default AddIcon;
