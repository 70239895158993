import React from 'react';
import IconContainer from '../IconContainer';


const FieldsIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M4.46 5.386v2.86h7.48l.744-2.86H4.459zm-.664-1.325A.76.76 0 0 1 4.095 4h16.114c.216 0 .41.09.544.23a.677.677 0 0 1 .247.52l-.005 14.632c-.012.105-.069.23-.167.448-.217.176-.217.176-.308.205-.11.03-.11.03-.15.036l-.093.009-.16.006-.532.009c-.436.004-1.067.007-1.878.009-1.424.003-3.37.001-5.708-.003a3975.802 3975.802 0 0 1-7.042-.021l-.903-.003-.327-.002c-.402-.001-.727-.311-.727-.693V4.751c0-.383.327-.693.73-.693.022 0 .044 0 .066.003zM19.54 5.386h-1.928l-1.56 13.333 1.651-.001c.79-.002 1.407-.005 1.837-.01V5.387zm-4.956 13.332l1.56-13.332h-1.958l-3.47 13.325a3390.317 3390.317 0 0 0 3.868.007zm-5.37-.01l1.104-4.24h-5.86v4.224a10115.92 10115.92 0 0 0 4.756.016zM4.46 13.082h6.22l.9-3.45h-7.12v3.45z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

FieldsIcon.iconId = 'fields';


export default FieldsIcon;
