import React from 'react';
import IconContainer from '../IconContainer';


const CircleRemoveIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-1.28 0a6.72 6.72 0 1 0-13.438 0 6.72 6.72 0 0 0 13.437 0zm-3.516-.731a.64.64 0 0 1 0 1.28h-6.59a.64.64 0 0 1 0-1.28h6.59z"
      fill="currentColor" fillRule="nonzero"/>

  </IconContainer>
);

CircleRemoveIcon.iconId = 'circleRemove';


export default CircleRemoveIcon;
