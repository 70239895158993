import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { border } from 'styled-system';


const FileAttachmentCell = styled(({ children, ...rest }) => {
  return (
    <div {...rest}>
      <div>
        {children}
      </div>
    </div>
  )
})`
  height: var(--cell-size);
  min-height: var(--cell-size);
  min-width: var(--cell-size);
  position: relative;
  text-align: center;
  width: var(--cell-size);
  
  > * {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  
  ${border}
`;


FileAttachmentCell.displayName = 'FileAttachmentCell';
FileAttachmentCell.propTypes = {
  children: PropTypes.any.isRequired
};


export default FileAttachmentCell;
