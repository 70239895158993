import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import context from './context';
import datePickerConfig from './DatePicker/config';


const defaultConfig = {
  datePicker: datePickerConfig
};

const ConfigProvider = (props) => {
  const { children, config: customizedConfig, theme } = props;
  const config = useMemo(() => _.defaultsDeep({}, customizedConfig, defaultConfig), [customizedConfig]);

  return (
    <ThemeProvider theme={theme}>
      <context.Provider value={config}>
        {children}
      </context.Provider>
    </ThemeProvider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.any.isRequired,
  config: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};


export default ConfigProvider;
