import Backbone from "backbone";
import $ from "jquery";
import Modal from "ui/modal";
import _ from "underscore";

import render from "layout/templates";
import EventChannel from "lib/events";
import { page } from "lib/segment";

const toastChannel = EventChannel.getChannel("toast");

const AppInstance = new (Backbone.View.extend({
  /**
   * @param {String} message
   */
  notify: (message) => {
    //Would be really nice to be able to have a useToast() hook instead of this
    // I do see a couple instances of App.notify that are tightly coupled to backbone though.
    toastChannel.fire("warn", { message });
  },

  //This is only used in the bins page one time, considering just adding it over there
  dialog(view, options) {
    if (options == null) {
      options = {};
    }
    if (options.screenName) {
      page(options.screenName);
    }

    options.content = view.el;
    const modal = new Modal(options);
    modal.render();

    // Forward the events
    view.listenTo(modal, "all", (type, options) => view.trigger(type, options));

    modal.listenTo(view, "safeclose", modal.setSafeClose);
    modal.listenTo(view, "complete", modal.close);
    view.remove = _.bind(view.remove, view);
    $("form input:text:first").focus();

    view.once("finish removed", function () {
      view.trigger("cancel finish");
      window.removeEventListener("hashchange", view.remove);
      return modal.remove();
    });

    _.defer(() => window.addEventListener("hashchange", view.remove));

    return view;
  },

  confirm(initialData) {
    const data = _.defaults(initialData, {
      title: "Are you sure?",
      confirm: "OK",
      cancel: "Cancel",
      warning: true,
    });

    const action = $.Deferred();

    const $conf = $(
      render(
        "confirm",
        _.defaults(data, {
          title: "Hey there!",
          confirm: "OK",
          message: "",
          warning: false,
        })
      )
    );

    const modal = new Modal({
      content: $conf[0],
    });

    $conf.on("click", ".btn.js-ok", function () {
      action.resolve();
      return modal.remove();
    });

    $conf.on("click", ".btn.js-cancel", function () {
      action.reject();
      return modal.remove();
    });

    modal.render();
    return action.promise();
  },
}))();

export default AppInstance;
