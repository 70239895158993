import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { border, typography } from 'styled-system';

import FileAttachmentCell from './FileAttachmentCell';


const FileName = styled.span`
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${typography}
`;

const NonPreviewableCell = styled(({ className, fileName, onClick }) => {
  return (
    <FileAttachmentCell className={className} onClick={onClick}>
      <svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="m0 0h32v32h-32z"/>
          <path d="m18.6666667 2.66666667h-10.6666667c-1.46666667 0-2.65333333 1.2-2.65333333 2.66666666l-.01333334 21.33333337c0 1.4666666 1.18666667 2.6666666 2.65333334 2.6666666h16.01333333c1.4666667 0 2.6666667-1.2 2.6666667-2.6666666v-16zm-10.6666667 24.00000003v-21.33333337h9.3333333v6.66666667h6.6666667v14.6666667z"
                fill="#134b84" fillRule="nonzero"/>
        </g>
      </svg>
      <FileName fontSize="sm" title={fileName}>{fileName}</FileName>
    </FileAttachmentCell>
  )
})`
  > *  > * {
    display: block;
    margin: 0 auto;
  }
  .remove {
    top: -37px;
  }
  ${border}
`;

NonPreviewableCell.displayName = 'NonPreviewableCell';
NonPreviewableCell.propTypes = {
  fileName: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

NonPreviewableCell.defaultProps = {
  onClick: () => {}
};


export default NonPreviewableCell;
