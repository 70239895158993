import { authHeaders as generateAuthHeaders } from "collection/graphql/client/common";

export const authLink = (operation, forward) => {
  const authHeaders = generateAuthHeaders();

  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      ...authHeaders,
    },
  }));

  return forward(operation);
};
