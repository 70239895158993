"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SSORoutes = {
    bushelContracts: "SUCCESSFULLY_CONNECTED_TO_BUSHEL_CONTRACTS",
    confirmFarmName: "CONFIRM_FARM_NAME",
    connectToContracts: "CONNECT_TO_CONTRACTS",
    completeSSOFlow: "COMPLETE_SSO_FLOW",
    selectEnterprise: "SELECT_ENTERPRISE",
    signUp: "SIGN_UP",
    userInformation: "USER_INFORMATION",
};
const navigateThroughSSOFlow = ({ enterprises, firstAndLastNameExist, hasBeenPromptedToConnectContracts, hasFarmName, isContractsConnected, isUserAdmin, isUserInBushelNetwork, selectedEnterprise, successfullyConnectedToContracts, }) => {
    if ((enterprises === null || enterprises === void 0 ? void 0 : enterprises.length) === 0) {
        return SSORoutes.signUp;
    }
    if (!selectedEnterprise) {
        return SSORoutes.selectEnterprise;
    }
    if (!firstAndLastNameExist) {
        return SSORoutes.userInformation;
    }
    if (!isUserAdmin) {
        return SSORoutes.completeSSOFlow;
    }
    if (!hasFarmName) {
        return SSORoutes.confirmFarmName;
    }
    if (!isUserInBushelNetwork) {
        return SSORoutes.completeSSOFlow;
    }
    if (isContractsConnected) {
        return SSORoutes.completeSSOFlow;
    }
    if (!hasBeenPromptedToConnectContracts) {
        return SSORoutes.connectToContracts;
    }
    if (successfullyConnectedToContracts) {
        return SSORoutes.bushelContracts;
    }
    return SSORoutes.completeSSOFlow;
};
exports.default = navigateThroughSSOFlow;
