import _ from 'lodash';
import { getClientInstance } from './client';


/**
 * Opens the FileStack picker.
 *
 * @param {Object} [options] a subset of [FileStack's file picker options]{@link https://filestack.github.io/filestack-js/interfaces/pickeroptions.html}
 * @param {String} [options.accept] limits the file types allowed (use `image/*` to allow images only, for example) ([docs]{@link https://filestack.github.io/filestack-js/interfaces/pickeroptions.html#accept}).
 * @param {String} [options.fromSources] sets the acceptable upload sources ([docs]{@link https://filestack.github.io/filestack-js/interfaces/pickeroptions.html#fromsources}).
 * @param {Number} [options.maxFiles=10] sets the maximum number of files to be accepted ([docs]{@link https://filestack.github.io/filestack-js/interfaces/pickeroptions.html#maxfiles}).
 * @return {Promise<Object[]>} resolves with an array of the files that were uploaded.
 */
export default function selectFiles(options = {}) {
  return new Promise((resolve) => {
    options = {
      fromSources: ['local_file_system', 'url'],
      maxFiles: 10,
      ...options,
      onCancel: () => resolve([]),
      onUploadDone: ({ filesUploaded }) => resolve(filesUploaded)
    };

    getClientInstance().picker(options).open();
  });
};
