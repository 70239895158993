import PropTypes from 'prop-types';
import React from 'react';

import { default as icons } from './icons';


const Icon = ({ icon, size, ...props }) => {
  const IconImpl = icons[icon];
  if (!IconImpl) {
    throw new Error(`Invalid icon id: ${icon}`);
  }

  const sizes = {
    xl: 40,
    lg: 32,
    md: 24,
    sm: 16
  };
  props.height = props.height ?? sizes[size];
  props.width = props.width ?? sizes[size];


  return <IconImpl {...props} />;
};


Icon.iconIds = Object.keys(icons);

Icon.propTypes = {
  icon: PropTypes.oneOf(Icon.iconIds).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl'])
};

Icon.defaultProps = {
  size: 'md'
};


export default Icon;
