import React from 'react';
import IconContainer from '../IconContainer';


const CandlestickIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-322.000000, -309.000000)">
        <g transform="translate(0.000000, 80.000000)">
          <g>
            <g transform="translate(16.000000, 226.000000)">
              <g transform="translate(264.000000, 0.000000)">
                <g
                  transform="translate(52.000000, 12.000000) rotate(-270.000000) translate(-52.000000, -12.000000) translate(40.000000, 0.000000)">
                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M11,20 L11,22 L17,22 L17,20 L11,20 Z M11,16 L11,18 L17,18 L17,16 L11,16 Z M18,22 L18,20 L21,20 L21,18 L18,18 L18,16 L16,16 L16,22 L18,22 Z M7,9 L7,11 L3,11 L3,13 L7,13 L7,15 L9,15 L9,9 L7,9 Z M10,16 L10,18 L3,18 L3,20 L10,20 L10,22 L12,22 L12,16 L10,16 Z M5,2 L5,4 L3,4 L3,6 L5,6 L5,8 L7,8 L7,2 L5,2 Z M14,15 L14,13 L21,13 L21,11 L14,11 L14,9 L12,9 L12,15 L14,15 Z M13,15 L13,13 L8,13 L8,15 L13,15 Z M13,11 L13,9 L8,9 L8,11 L13,11 Z M15,8 L17,8 L17,6 L21,6 L21,4 L17,4 L17,2 L15,2 L15,8 Z M16,4 L16,2 L6,2 L6,4 L16,4 Z M16,8 L16,6 L6,6 L6,8 L16,8 Z"
                    fill="currentColor" fillRule="nonzero"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>

  </IconContainer>
);

CandlestickIcon.iconId = 'candlestick';


export default CandlestickIcon;
