import React from 'react';
import IconContainer from '../IconContainer';


const PlansIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M20.416 22.698v-.003.003zm-7.433-10.734v2.448c2.417.54 3.483 1.342 3.483 2.888 0 1.46-1.393 2.25-3.483 2.43v.799c0 .41-.343.74-.767.74a.755.755 0 0 1-.768-.74v-.777c-1.472-.097-2.712-.574-3.607-1.298a.724.724 0 0 1-.095-1.044.786.786 0 0 1 1.081-.092c.625.507 1.52.858 2.621.948v-2.647c-2.206-.461-3.528-1.186-3.528-2.529 0-1.568 1.577-2.49 3.528-2.644v-1.08c0-.409.344-.74.768-.74.424 0 .767.331.767.74v1.106c1.037.106 2.06.378 2.9.783a.73.73 0 0 1 .345.994.78.78 0 0 1-1.03.332c-.63-.303-1.412-.52-2.215-.617zm-1.535-.028c-1.171.123-1.993.606-1.993 1.154 0 .301.726.697 1.993 1.005v-2.16zm1.535 6.306c1.256-.13 1.948-.51 1.948-.942 0-.608-.474-1.007-1.948-1.367v2.309zM20.465 6.06h-1.24a.755.755 0 0 1-.767-.741c0-.41.343-.741.767-.741h1.324c.802 0 1.451.627 1.451 1.403l-.05 16.713c.006.72-.596 1.305-1.342 1.305H3.343c-.738 0-1.338-.576-1.343-1.295l.048-16.686c.002-.796.67-1.44 1.496-1.44h1.315c.424 0 .767.332.767.74 0 .41-.343.742-.767.742H3.583l-.048 16.457h16.882l.048-16.457zm-5.362-2.53c.346.224.692.462 1.018.707.149.111.29.222.423.331.561.461 1.027 1.123 1.141 1.726.188.994-.531 1.67-1.75 1.67h-7.82c-1.22 0-1.94-.676-1.751-1.67.114-.603.58-1.265 1.141-1.725.133-.11.275-.22.424-.332a18.075 18.075 0 0 1 1.153-.794C9.294 2.061 10.527 1 12.019 1c1.503 0 2.736 1.061 2.949 2.444l.135.087zM8.115 6.483h7.82c.094 0 .165-.004.215-.01-.083-.225-.325-.548-.6-.775a10.617 10.617 0 0 0-.37-.29 16.574 16.574 0 0 0-1.35-.908l-.365-.217v-.414c0-.767-.642-1.387-1.434-1.387-.805 0-1.447.62-1.447 1.387v.414l-.364.217a16.574 16.574 0 0 0-1.35.908 10.62 10.62 0 0 0-.37.29c-.276.227-.518.55-.601.775.05.006.122.01.216.01z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

PlansIcon.iconId = 'plans';


export default PlansIcon;
