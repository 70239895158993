import { useMutation, useQuery } from "@apollo/client";
import { DatePicker } from "components/fl-ui/common";
import { yupResolver } from "@hookform/resolvers/yup";
import Big from "big.js";
import { PRICELINE_STATUSES } from "contracts/constants/pricelineStatuses";
import PriceLinesFormSchema from "contracts/form/validation/PriceLinesFormSchema";
import PricelineLocationsDropdown from "contracts/modals/PricingAndDeliveryModal/PricelineLocationsDropdown";
import _ from "lodash";
import formatCurrency from "marketing/cards/utils/formatCurrency";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import styled from "styled-components";

import createPriceline from "collection/graphql/contracts/mutations/createPriceline";
import updatePriceline from "collection/graphql/contracts/mutations/updatePriceline";
import getCashContractDetails from "collection/graphql/contracts/queries/getCashContractDetails";
import getContractUnderlyings from "collection/graphql/contracts/queries/getContractUnderlyings";
import getPricelines from "collection/graphql/contracts/queries/getPricelines";
import App from "layout/app";

import { Button } from "components/fl-ui";
import { chooserStyles } from "components/fl-ui/Chooser";
import { ControlledFormGroup, Form, NumericInput } from "components/fl-ui/Form";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import { Spacing } from "components/fl-ui/constants";
import ContractUnit from "components/units/ContractUnit";

const getCashPricePlaceholderText = (values) => {
  const { basis, futuresPrice } = PriceLinesFormSchema.cast(
    {
      basis: values.basis,
      futuresPrice: values.futuresPrice,
    },
    { assert: false }
  );

  if (![basis, futuresPrice].some(isNaN)) {
    const suggestedCashPrice = Big(futuresPrice ?? 0)
      .add(basis ?? 0)
      .toNumber();
    if (!isNaN(suggestedCashPrice)) {
      return `Suggested cash price: $${suggestedCashPrice.toFixed(2)}`;
    }
  }

  return "0.00";
};

const PricingAndDeliveryModal = styled(({ className, contract, onClose, priceline }) => {
  const editMode = !!priceline;
  const isImportedContract = contract.dataSource === "PARTNER";

  const { data: contractData, loading } = useQuery(getContractUnderlyings, {
    variables: {
      id: contract.id,
    },
  });
  const options = useMemo(() => {
    return _.map(contractData?.underlyings?.symbols, (value) => ({
      label: value,
      value,
    }));
  }, [contractData]);

  const methods = useForm({
    defaultValues: PriceLinesFormSchema.cast(
      {
        quantityUnit: contract.quantityUnit,
        ...(priceline || {}),
        basis: priceline?.basisStatus === PRICELINE_STATUSES.estimated || !priceline?.basis ? null : priceline.basis, // override basis
        cashPrice:
          priceline?.cashPriceStatus && priceline.cashPriceStatus === PRICELINE_STATUSES.final
            ? priceline.cashPrice
            : null, // override cash price
        deliveryLocationId: priceline?.deliveryLocation?.id || null,
        futuresPrice:
          priceline?.futuresPriceStatus && priceline.futuresPriceStatus === PRICELINE_STATUSES.final
            ? priceline.futuresPrice
            : null, // override futures price
      },
      { assert: false, stripUnknown: true }
    ),
    mode: "onTouched",
    resolver: yupResolver(PriceLinesFormSchema),
  });

  const [deliveryEndDate, deliveryStartDate] = methods.watch(["deliveryEndDate", "deliveryStartDate"]);
  useEffect(() => {
    if (deliveryEndDate && deliveryStartDate) {
      methods.trigger(["deliveryStartDate", "deliveryEndDate"]);
    }
  }, [deliveryEndDate, deliveryStartDate]);

  const { refetch } = useQuery(getPricelines, {
    variables: {
      id: contract.id,
    },
  });

  const [create] = useMutation(createPriceline);
  const [update] = useMutation(updatePriceline);

  const createNewPriceline = async (data) => {
    try {
      await create({
        variables: {
          input: {
            ...data,
            contractId: contract.id,
          },
        },
        refetchQueries: [
          {
            query: getCashContractDetails,
            variables: {
              id: contract.id,
            },
          },
        ],
      });

      await refetch();
      onClose();
    } catch (error) {
      App.notify("An error with creating the priceline occurred.");
      throw error;
    }
  };
  const editPriceline = async (data) => {
    try {
      await update({
        variables: {
          input: data,
        },
        refetchQueries: [
          {
            query: getCashContractDetails,
            variables: {
              id: contract.id,
            },
          },
        ],
      });

      await refetch();
      onClose();
    } catch (error) {
      App.notify("An error with editing the priceline occurred.");
      throw error;
    }
  };

  const onSubmit = async (data) => {
    if (editMode) {
      await editPriceline(data);
    } else {
      await createNewPriceline(data);
    }
  };

  let modalTitle = "Add Pricing & Delivery";
  if (editMode) {
    modalTitle = "Pricing Details";
    if (contract.contractNumber) {
      modalTitle += ` - ${contract.contractNumber}`;
    }
  }

  return (
    <Modal className={className} width={600}>
      <ModalHeader>
        <ModalTitle>{modalTitle}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>
      <ModalBody>
        <FormProvider {...methods} schema={PriceLinesFormSchema}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <ControlledFormGroup
              name="deliveryStartDate"
              render={({ field }) => <DatePicker {...field} disabled={isImportedContract} />}
            />

            <ControlledFormGroup
              name="deliveryEndDate"
              render={({ field }) => <DatePicker {...field} disabled={isImportedContract} />}
            />

            <ControlledFormGroup
              name="deliveryLocationId"
              render={({ field }) => (
                <PricelineLocationsDropdown
                  {...field}
                  disabled={isImportedContract}
                  label="Location"
                  placeholder="Enter location name"
                />
              )}
            />

            <ControlledFormGroup
              name="quantityPriced"
              render={({ field }) => (
                <NumericInput
                  {...field}
                  disabled={isImportedContract}
                  min={1}
                  size="large"
                  suffix={<ContractUnit contract={contract} />}
                />
              )}
            />

            <ControlledFormGroup
              name="futuresPrice"
              render={({ field }) => (
                <NumericInput
                  {...field}
                  disabled={isImportedContract}
                  min={0}
                  placeholder="0.00"
                  prefix="$"
                  size="large"
                  step={0.0025}
                  type="float"
                />
              )}
            />

            <ControlledFormGroup
              name="adjustments"
              render={({ field }) => (
                <NumericInput
                  {...field}
                  disabled={isImportedContract}
                  inputMode="text"
                  placeholder="0.00"
                  step={0.01}
                  suffix={<ContractUnit contract={contract} per="unit" />}
                  type="currency"
                />
              )}
            />

            {/* only display if cargill contract */}
            {contract.buyer === "Cargill" && (
              <ControlledFormGroup
                name="additionalAdjustments"
                render={({ field }) => (
                  <NumericInput
                    {...field}
                    disabled={isImportedContract}
                    inputMode="text"
                    placeholder="0.00"
                    step={0.01}
                    suffix={<ContractUnit contract={contract} per="unit" />}
                    type="currency"
                  />
                )}
              />
            )}

            <ControlledFormGroup
              name="basis"
              render={({ field }) => (
                <NumericInput
                  {...field}
                  disabled={isImportedContract}
                  inputMode="text"
                  placeholder={
                    priceline?.basis && priceline?.basisStatus === PRICELINE_STATUSES.estimated
                      ? `Estimated Basis: ${formatCurrency(priceline?.basis)}`
                      : "0.00"
                  }
                  step={0.01}
                  suffix={<ContractUnit contract={contract} per="unit" />}
                  type="currency"
                />
              )}
            />

            <ControlledFormGroup
              name="cashPrice"
              render={({ field }) => {
                const [basis, futuresPrice] = methods.watch(["basis", "futuresPrice"]);
                return (
                  <NumericInput
                    {...field}
                    disabled={isImportedContract}
                    min={0}
                    placeholder={getCashPricePlaceholderText({ basis, futuresPrice })}
                    prefix="$"
                    size="large"
                    step={0.0025}
                    type="float"
                  />
                );
              }}
            />

            <ControlledFormGroup
              name="underlying"
              render={({ field: { onChange, value } }) => (
                <ReactSelect
                  isClearable
                  isDisabled={loading}
                  isLoading={loading}
                  onChange={(option) => onChange(option?.value ?? null)}
                  options={options}
                  placeholder="Select a futures month"
                  styles={chooserStyles}
                  value={options.find((c) => c.value === value)}
                />
              )}
            />

            <ModalFooter>
              <Button color="default" onClick={onClose} type="button">
                {editMode ? "Cancel" : "Not now"}
              </Button>

              <Button
                className="save-button"
                color="primary"
                disabled={!_.isEmpty(methods.formState.errors)}
                type="submit"
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
})`
  .modal-header-text {
    display: inline-block;
    margin: 0;
  }
  .modal-header-icon {
    margin-left: ${Spacing.small};
    display: inline-block;
  }
  .save-button {
    margin-left: ${Spacing.xsmall};
  }
`;

export default PricingAndDeliveryModal;

PricingAndDeliveryModal.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
  priceline: PropTypes.object,
};
