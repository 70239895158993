import React from 'react';
import IconContainer from '../IconContainer';


const CircleCheckIcon = (props) => (
  <IconContainer {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z"/>
      <path
        d="M15.672 8.464L10.4 13.736l-2.872-2.864L6.4 12l4 4 6.4-6.4-1.128-1.136zM12 4c-4.416 0-8 3.584-8 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm0 14.4A6.398 6.398 0 015.6 12c0-3.536 2.864-6.4 6.4-6.4 3.536 0 6.4 2.864 6.4 6.4 0 3.536-2.864 6.4-6.4 6.4z"
        fill="currentColor" fillRule="nonzero"/>
    </g>

  </IconContainer>
);

CircleCheckIcon.iconId = 'circleCheck';


export default CircleCheckIcon;
