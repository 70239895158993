import { SalesEmailLink } from "billing/lib";
import React from "react";
import styled from "styled-components";

import { getCurrentSubscription } from "collection/graphql/subscription";
import useRestQuery from "hooks/useRestQuery";

import { IconContainer } from "components/fl-ui/Icons";
import { ColorPalette } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const AddonPrompt = styled(({ className }) => {
  const addons = useRestQuery(getCurrentSubscription).data?.subscription?.addons ?? [];
  const addonCopy = addons.map(({ name }) => `"${name}"`).join(", ");

  return addons.length === 0 ? null : (
    <div className={className}>
      <IconContainer size="xxxlarge" icon="warning" />

      <p>
        You have the following add on(s) {addonCopy}. Please contact <SalesEmailLink /> if you would like to keep your
        add on(s) and purchase a plan. Please note, selecting a plan and completing your purchase here will remove your
        add on(s).
      </p>
    </div>
  );
})`
  align-items: center;
  display: flex;
  font-weight: ${Typography.weights.bold};
  gap: ${Spacing.regular};
  padding: ${Spacing.large};

  > :first-child {
    color: ${ColorPalette["dark-red"]};
  }

  p {
    line-height: 1.5;
    margin: 0;
  }
`;

export default AddonPrompt;
