import { css } from "aphrodite/no-important";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import BackLink from "components/fl-ui/Layout/BackLink";
import Toolbar from "components/fl-ui/Layout/Navigation/Toolbar";
import styles from "components/fl-ui/Layout/styles/headerStyles";

const Header = ({
  title,
  description,
  backLink = false,
  noBorder = false,
  children,
  style = undefined,
  className = "",
  alignChildrenLeft = false,
  mobile = false,
  fullWidth = false,
}) => (
  <Row className={css(styles.headerContainer, mobile && styles.noPaddingBottom)}>
    <Col xs>
      <div className={css(styles.headerBorder, noBorder && styles.noBorder, mobile && styles.noPaddingBottom)}>
        <div className={css(styles.headerContent, mobile && styles.inlineChildren)}>
          {title && (
            <h1 className={classNames(css(styles.h1, backLink && styles.h1_wBackLink), className)} style={style}>
              {backLink && <BackLink backLink={backLink} />}
              {title && <span className={css(styles.h1_fieldName)}>{title}</span>}
              {description && (
                <div className={`info ${mobile ? "heading-info-new-line" : ""}`}>
                  {Array.isArray(description) ? (
                    <span>
                      {" "}
                      <span className="heading-info-first-section">{description[0]}</span>{" "}
                      {description[1] && <span> - {description[1]}</span>}{" "}
                    </span>
                  ) : (
                    description
                  )}
                </div>
              )}
            </h1>
          )}
          <div
            className={css(
              styles.headerChildren,
              alignChildrenLeft && styles.headerChildren_alignLeft,
              fullWidth && styles.headerChildren_fullWidth
            )}
          >
            {children}
          </div>
        </div>
        <div
          className={css(
            styles.toolbarContainer,
            (!children || alignChildrenLeft) && styles.toolbarContainer_noChildren
          )}
        >
          <Toolbar />
        </div>
      </div>
    </Col>
  </Row>
);

Header.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  backLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  noBorder: PropTypes.bool,
  alignChildrenLeft: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default Header;
