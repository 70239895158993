/* eslint-disable react/display-name */
import { Section } from "demo/components";
import ProductPurchaseForm from "products/forms/ProductPurchaseForm";
import usePurchaseFormModal from "products/forms/usePurchaseFormModal";
import React from "react";

const PurchaseFormsDemo = () => {
  const props = usePurchaseFormModal({
    cropYear: new Date().getFullYear(),
    onDeleteSuccess: () => {},
    onSaveSuccess: () => {},
  });

  return (
    <Section title="Purchase forms demos">
      <div>
        <h4>Create/Edit single purchase</h4>
        <ProductPurchaseForm {...props} />
      </div>
    </Section>
  );
};

PurchaseFormsDemo.propTypes = {};

PurchaseFormsDemo.defaultProps = {};

export default (props) => <PurchaseFormsDemo {...props} />;
