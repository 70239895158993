import React from 'react';
import IconContainer from '../IconContainer';


const CloseIcon = (props) => (
  <IconContainer {...props}>
    <g transform="translate(5 5)" fill="none" fillRule="evenodd">
      <mask id="b" fill="none">
        <path
          d="M7.971 6.91l5.63-5.63a.75.75 0 0 0-1.06-1.06L6.91 5.85 1.28.22A.75.75 0 1 0 .22 1.281l5.63 5.63L.22 12.54a.75.75 0 0 0 1.06 1.06l5.63-5.629 5.63 5.63a.75.75 0 0 0 1.062-1.06l-5.63-5.63z"/>
      </mask>
      <path
        d="M7.971 6.91l5.63-5.63a.75.75 0 0 0-1.06-1.06L6.91 5.85 1.28.22A.75.75 0 1 0 .22 1.281l5.63 5.63L.22 12.54a.75.75 0 0 0 1.06 1.06l5.63-5.629 5.63 5.63a.75.75 0 0 0 1.062-1.06l-5.63-5.63z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <g mask="url(#b)" fill="none">
        <path d="M-5-4h23v22H-5z"/>
      </g>
    </g>

  </IconContainer>
);

CloseIcon.iconId = 'close';


export default CloseIcon;
