import Backbone from "backbone";

import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { restClient } from "collection/graphql/client";

const Config = Backbone.Model.extend({
  url: "/config",
  // Public API
  user_id: null,

  initialize() {
    const hasAuthenticated = !!restClient.readQuery({ query: GET_CURRENT_USER });
    if (hasAuthenticated) {
      this.hydrate();
    } else {
      const subscription = restClient
        .watchQuery({ query: GET_CURRENT_USER, fetchPolicy: "cache-only" })
        .subscribe(({ data }) => {
          if (data.currentUser) {
            subscription?.unsubscribe();
            this.hydrate();
          }
        });
    }
  },

  async hydrate() {
    const { currentUser } = restClient.readQuery({ query: GET_CURRENT_USER });
    this.user_id = currentUser.id;

    await this.fetch({ silent: true });
  },
});

export default new Config();
