import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';


const RemoveButton = styled((props) => {
  const { className, onClick } = props;
  const handleClick = (event) => {
    event.preventDefault();
    onClick(event);
  };

  return (
    <a className={className + ' remove'} href="#" onClick={handleClick}>
      <svg height={24} width={24} viewBox="0 0 24 24" version={1.1}>
        <circle cx={12} cy={12} r={8} />
        <path
          d="M20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-1.28 0a6.72 6.72 0 1 0-13.438 0 6.72 6.72 0 0 0 13.437 0zm-3.516-.731a.64.64 0 0 1 0 1.28h-6.59a.64.64 0 0 1 0-1.28h6.59z"
          fill="currentColor" fillRule="nonzero"/>
      </svg>
    </a>
  );
})`
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  right: -12px;
  top: -12px;
  z-index: 1;
  
  circle {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

RemoveButton.displayName = 'RemoveButton';
RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired
};


export default RemoveButton;
