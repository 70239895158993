import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import { PayGateAdBanner } from "components/advertisements/banners";
import PayGateCommonBlankStateModal from "components/advertisements/modals/PayGateCommonBlankStateModal";

const PayGatedScoutingNotes = ({ fieldId, children }) => {
  const [payGate, setPayGate] = useState(false);
  const hasScoutingFeature = useEnterpriseFeature("scouting");
  const togglePayGate = () => setPayGate((payGateStatus) => !payGateStatus);

  const navigate = useNavigate();
  const handleNavigate = () => {
    if (hasScoutingFeature) {
      navigate(`/scouting/add?field_id=${fieldId}`);
    } else {
      togglePayGate();
    }
  };

  return (
    <>
      {children(handleNavigate)}
      {payGate && <PayGateCommonBlankStateModal onClose={togglePayGate} banner={PayGateAdBanner} />}
    </>
  );
};

PayGatedScoutingNotes.propTypes = {
  fieldId: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
};

export default PayGatedScoutingNotes;
