import React from 'react';
import IconContainer from '../IconContainer';


const MapIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M8.521 4.87L4.398 6.999v10.986l4.123-2.127V4.871zm6.431 1.002l5.033-2.596C20.45 3.036 21 3.38 21 3.91v12.582a.713.713 0 0 1-.383.634l-5.52 2.849a.687.687 0 0 1-.777-.098l-.013.026-5.097-2.806-5.195 2.68C3.55 20.017 3 19.673 3 19.143V6.56c0-.268.148-.513.383-.635l5.52-2.848a.681.681 0 0 1 .626-.004l.011-.022 5.412 2.82zm-.871 1.144L9.919 4.848V15.87l4.162 2.29V7.017zm5.52-1.948L15.48 7.196v10.986l4.123-2.127V5.068z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

MapIcon.iconId = 'map';


export default MapIcon;
