import React from 'react';
import styled, { withTheme } from 'styled-components';
import { variant } from 'styled-system';


const arrowCommon = (arrowId, { placement, theme }) => {
  const { arrow, body } = theme.popover;

  return `
    border-color: ${arrowId === 'after' ? body.backgroundColor : arrow.borderColor};
    border-style: solid;
    border-width: ${arrow.borderWidth};
    content: "";
    position: absolute;
    z-index: 1;
    
    ${(() => {
      const rules = [];
      
      if (placement.match(/^(top|bottom)/)) {
        rules.push(
          'border-left-color: transparent;',
          'border-right-color: transparent;'
        );
        
        placement.match(/^(top|bottom)$/) && rules.push(
          `left: calc(50% - (${arrow.borderWidth} / 2));`
        );

        placement.startsWith('top') && rules.push(
          'border-bottom: none;',
          'border-top-width: 8px;',
          `bottom: ${arrowId === 'after' ? '-1px' : '-2px'};`
        );
        
        placement.startsWith('bottom') && rules.push(
          'border-top: none;',
          'border-bottom-width: 8px;',
          `top: ${arrowId === 'after' ? '0' : '-1px'};`
        );
        
        placement.endsWith('-end') && rules.push(
          `right: calc(${arrow.borderWidth} / 2);`
        );
      } else if (placement.match(/^(left|right)/)) {
        rules.push(
          'border-bottom-color: transparent;',
          'border-top-color: transparent;'
        );

        placement.match(/^(left|right)$/) && rules.push(
          `top: calc(50% - (${arrow.borderWidth} / 2));`
        );

        placement.startsWith('left') && rules.push(
          'border-right: none;',
          'border-left-width: 8px;',
          `right: ${arrowId === 'after' ? '-1px' : '-3px'};`
        );

        placement.startsWith('right') && rules.push(
          'border-left: none;',
          'border-right-width: 8px;',
          `left: ${arrowId === 'after' ? '-1px' : '-3px'};`
        );
        
        placement.endsWith('-start') && rules.push(
          `top: ${arrowId === 'after' ? '4px' : '5px'};`
        );
        
        placement.endsWith('-end') && rules.push(
          `bottom: 8px;`
        );
      }
      
      
      return rules.join("\r");
    })()}
    
  `;
};


const TooltipBody = withTheme(styled.div`
  ${variant({ scale: 'popover' })}
  :after {
    ${arrowCommon.bind(this, 'after')}
  }
  :before {
    ${arrowCommon.bind(this, 'before')}
  }
`);


TooltipBody.defaultProps = {
  variant: 'body'
};


export default TooltipBody;
