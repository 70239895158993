/* eslint-disable react/jsx-no-target-blank */
import { css } from "aphrodite/no-important";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useContactInfo from "hooks/useContactInfo";
import { styles } from "modules/billing/lib/styles";

export { styles as styles };

const getLinkColor = (color) => {
  return css(color === "blue" && styles.link_blue, color === "white" && styles.link_white);
};

export const SupportEmailLink = ({ children = null, color = "blue", mailtoFields = {} }) => {
  const { supportEmail } = useContactInfo();

  let href = `mailto:${supportEmail}`;
  if (!_.isEmpty(mailtoFields)) {
    const fields = _.keys(mailtoFields).map((field) => [field, encodeURIComponent(mailtoFields[field])].join("="));
    href += "?" + fields.join("&");
  }

  return (
    <a className={getLinkColor(color)} href={href}>
      {children || supportEmail}
    </a>
  );
};

SupportEmailLink.propTypes = {
  children: PropTypes.string,
  color: PropTypes.oneOf(["blue", "white"]),
  mailtoFields: PropTypes.object,
};

export const SalesEmailLink = ({ children = null, color = "blue" }) => {
  const { salesEmail } = useContactInfo();

  return (
    <a className={getLinkColor(color)} href={`mailto:${salesEmail}`}>
      {children || salesEmail}
    </a>
  );
};

SalesEmailLink.propTypes = {
  children: PropTypes.string,
  color: PropTypes.oneOf(["blue", "white"]),
};

export const SalesPhoneLink = ({ color = "blue" }) => {
  const { salesPhone, salesPhoneFormatted } = useContactInfo();

  return (
    <a className={getLinkColor(color)} href={`tel:${salesPhone}`}>
      {salesPhoneFormatted}
    </a>
  );
};

SalesPhoneLink.propTypes = {
  color: PropTypes.oneOf(["blue", "white"]),
};

export const SupportPhoneLink = ({ color = "blue" }) => {
  const { supportPhone, supportPhoneFormatted } = useContactInfo();

  return (
    <a className={getLinkColor(color)} href={`tel:${supportPhone}`}>
      {supportPhoneFormatted}
    </a>
  );
};

SupportPhoneLink.propTypes = {
  color: PropTypes.oneOf(["blue", "white"]),
};

export const PrivacyLink = ({ children, color = "blue" }) => {
  const { privacyUrl } = useContactInfo();

  return (
    <a className={getLinkColor(color)} href={privacyUrl} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
};

PrivacyLink.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["blue", "white"]),
};

export const SupportLink = ({ children, color = "blue" }) => {
  const { supportUrl } = useContactInfo();

  return (
    <a className={getLinkColor(color)} href={supportUrl} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
};

SupportLink.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["blue", "white"]),
};
