import React from 'react';
import IconContainer from '../IconContainer';


const EquipmentIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M8.126 16.78a3.381 3.381 0 1 0-6.762-.001 3.381 3.381 0 0 0 6.762 0zm9.064 2.608h-5.534a.681.681 0 0 1-.681-.681v-1.59c0-2.017-.914-3.86-2.354-5.044a.677.677 0 0 1-.226-.177 5.86 5.86 0 0 0-2.747-1.126 6.195 6.195 0 0 0-3.404.5.681.681 0 1 1-.566-1.24 7.484 7.484 0 0 1 2.184-.62V4.663C3.862 3.744 4.605 3 5.523 3h7.916l1.582-.039a.681.681 0 0 1 .033 1.362l-.902.023.832 3.51a64357.729 64357.729 0 0 0 3.21.95c1.86.552 3.114.922 3.477 1.027 1.249.363 1.963 1.411 1.796 2.749-.045.364-.14.89-.274 1.542a80.059 80.059 0 0 1-.373 1.715 3.469 3.469 0 1 1-5.63 3.549zm-.106-1.363a3.47 3.47 0 0 1 4.498-2.888 76.84 76.84 0 0 0 .276-1.285c.127-.62.217-1.118.257-1.439.083-.664-.21-1.093-.824-1.271-.366-.106-1.62-.476-3.494-1.031a7847.245 7847.245 0 0 1-2.9-.86c-.543 1.66-2.285 2.855-4.18 3.049a7.955 7.955 0 0 1 1.62 4.818v.907h4.747zm-7.808-7.172c1.957.5 4.186-.741 4.413-2.402a.688.688 0 0 1 .016-.083l-.949-4.005H8.791v5.881a.68.68 0 0 1-.043.24c.181.115.357.238.528.369zm-1.847-1.03v-5.46H5.523a.299.299 0 0 0-.299.299v4.701a7.148 7.148 0 0 1 2.204.46zm2.06 6.956a4.744 4.744 0 1 1-9.49 0 4.744 4.744 0 0 1 9.49 0zm13.144 1.663a2.106 2.106 0 1 0-4.213 0 2.106 2.106 0 0 0 4.213 0z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

EquipmentIcon.iconId = 'equipment';


export default EquipmentIcon;
