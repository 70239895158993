import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PickerOverlay } from 'filestack-react';
import { saveAs } from 'file-saver';
import { space } from 'styled-system';

import { getPreviewableFiles, getImagePreviewUrl, getUrl, isFilePreviewable } from '../fileClient';
import { ContextProvider } from './lib';
import { AddFileCell, FileAttachmentContainer, FileAttachmentPreview } from './components';


const normalizeFile = (file) => _.pick(file, ['filepickerId', 's3Id', 'size', 'name', 'url']);

const getDefaultState = (props) => ({
  ..._.pick(props, ['canAdd', 'canDelete', 'cellSize', 'maxFiles', 'webcam']),
  accept: [props.accept].flat(Infinity).filter(x => x),
  files: _.map(props.files, normalizeFile),
  totalFiles: _.size(props.files),
});

const sanitiseFile = ({ filename, handle, key, size }) => ({ filepickerId: handle, name: filename, s3Id: key, size });


const Attachments = (props) => {
  const { apiKey, cellSize, children, maxFiles, onCarouselInit, onChange, onError, pickerOptions } = props;
  const [state, setState] = useState(getDefaultState(props));
  const [showFilePicker, setShowFilePicker] = useState(false);
  const files = _.map(props.files, normalizeFile);

  useEffect(() => {
    if (!_.isEqual(state.files, files)) {
      onChange(state.files);
    }
  }, [state.files]);

  const showAddCell = state.canAdd && state.totalFiles < maxFiles;
  const updateState = changes => setState(state => ({ ...state, ...changes }));

  const context = {
    ...state,
    updateState,
    files,

    deleteFile: (filepickerId) => {
      const updatedFiles = _.reject(files, { filepickerId });
      if (updatedFiles.length !== files.length) {
        updateState({ files: updatedFiles, totalFiles: updatedFiles.length });
      }
    },

    onFileClick: async (file, fileData) => {
      if (!file && fileData) {
        saveAs(fileData.url, fileData.name);
      } else if (!onCarouselInit || !isFilePreviewable(file)) {
        saveAs(getUrl(file.filepickerId), file.fileName);
      } else if (onCarouselInit) {
        const previewableFiles = await getPreviewableFiles(_.map(files, 'filepickerId'));
        const matchedFile = _.find(previewableFiles, { filepickerId: file.filepickerId });

        onCarouselInit({
          index: previewableFiles.indexOf(matchedFile),
          files: previewableFiles.map((file) => ({
            ...file,
            previewUrl: getImagePreviewUrl(file.filepickerId),
            url: getUrl(file.filepickerId)
          }))
        });
      }
    }
  };

  const processUploadedFiles = (uploadedFiles) => {
    let totalFiles = files.length;
    if (uploadedFiles.length > 0) {
      totalFiles += uploadedFiles.length;
      setState(state => ({ ...state, files: [...state.files, ...uploadedFiles.map(sanitiseFile)]}));
    }

    updateState({ totalFiles });
  };


  return (
    <ContextProvider value={context}>
      <FileAttachmentContainer cellSize={cellSize}>
        {_.map(files, ({ filepickerId, name, url }) => {
          return <FileAttachmentPreview key={filepickerId} fileId={filepickerId} name={name} url={url} />
        })}

        {showAddCell && (
          <AddFileCell
            accept={state.accept}
            canAdd={state.canAdd}
            children={children}
            setShowFilePicker={setShowFilePicker}
          />
        )}

        {showFilePicker && (
          <PickerOverlay
            apikey={apiKey}
            onError={onError}
            onSuccess={({ filesUploaded }) => processUploadedFiles(filesUploaded)}
            pickerOptions={{
              ..._.pick(props, ['accept', 'maxFiles']),
              customText: {
                'Drag and Drop, Copy and Paste Files': 'Attach Files',
              },
              fromSources: ['local_file_system', 'url', 'webcam'],
              onClose: () => setShowFilePicker(false),
              ...pickerOptions,
            }}
          >
          </PickerOverlay>
        )}
      </FileAttachmentContainer>
    </ContextProvider>
  );
};

const FileAttachments = styled(Attachments)`${space}`;

FileAttachments.displayName = 'FileAttachments';
FileAttachments.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  apiKey: PropTypes.string.isRequired,
  canAdd: PropTypes.bool,
  canDelete: PropTypes.bool,
  cellSize: PropTypes.number,
  files: PropTypes.arrayOf(PropTypes.shape({
    filepickerId: PropTypes.string,
    s3Id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  })),
  maxFiles: PropTypes.number,
  native: PropTypes.bool,
  onCarouselInit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  webcam: PropTypes.bool
};

FileAttachments.defaultProps = {
  canAdd: true,
  canDelete: true,
  cellSize: 120,
  files: [],
  maxFiles: 1,
  native: false,
  webcam: true
};


export default FileAttachments;
