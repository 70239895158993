import React from 'react';
import IconContainer from '../IconContainer';


const ImageryIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="currentColor" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M16.454 11.48l-.992-.645M14.5 15.486l2.9 1.883 4.82-7.422-2.9-1.883zM20.897 11.705l-2.69-1.747M19.688 13.568l-2.691-1.748M18.478 15.43l-2.69-1.747M10.315 7.493l.992.644"/>
      <g>
        <path
          d="M4.55 9.024l2.9 1.883 4.82-7.421-2.9-1.884zM5.872 7.267l2.691 1.747M7.082 5.404l2.69 1.748M8.292 3.542l2.69 1.747M11.613 12.746l-.514-.334a2 2 0 0 1-.588-2.765l1.852-2.852a2 2 0 0 1 2.765-.588l.514.334a2 2 0 0 1 .588 2.766l-1.852 2.851a2 2 0 0 1-2.765.588zM11.128 12.93l-.81 1.246M10.507 15.658a.965.965 0 1 1-1.619-1.052.965.965 0 0 1 1.619 1.052zM11.537 17.863c-1.06.733-2.5.801-3.648.055a3.278 3.278 0 0 1-1.435-3.356"/>
        <path d="M12.038 20.22a5.572 5.572 0 0 1-5.404-.37A5.572 5.572 0 0 1 4.1 15.063"/>
      </g>
    </g>

  </IconContainer>
);

ImageryIcon.iconId = 'imagery';


export default ImageryIcon;
