import React from 'react';
import IconContainer from '../IconContainer';


const ArrowRightSmallIcon = (props) => (
  <IconContainer {...props} viewBox="-4 -8 24 24">
      <mask id="a" fill="#fff">
        <path d="m0 0h24v24h-24z" fill="#fff" fillRule="evenodd"/>
      </mask>
      <path d="m10.99 16v-3h7.01v-2h-7.01v-3l-3.99 4z" fill="currentColor" mask="url(#a)"
            transform="matrix(-1 0 0 -1 18 16)"/>
  </IconContainer>
);

ArrowRightSmallIcon.iconId = 'arrowRightSmall';


export default ArrowRightSmallIcon;
