import { BaseElement } from "components/fl-ui/common";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Icon from "components/fl-ui/Icons/Icon";
import IconManifest from "components/fl-ui/Icons/IconManifest";
import { Spacing } from "components/fl-ui/constants";

const size = ({ size }) => Spacing[size] ?? Spacing.large;

const IconContainer = styled(({ children, icon, size, ...props }) => (
  <BaseElement {...props}>
    {icon && <Icon icon={icon} />}
    {!icon && children}
  </BaseElement>
))`
  display: inline-block;
  max-height: ${size};
  max-width: ${size};
  width: ${size};
`;

IconContainer.propTypes = {
  icon: PropTypes.oneOf(IconManifest),
  size: PropTypes.oneOf([
    "xxsmall",
    "xsmall",
    "small",
    "minor",
    "regular",
    "medium",
    "large",
    "xlarge",
    "xxlarge",
    "xxxlarge",
  ]),
};

IconContainer.defaultProps = {
  size: "large",
};

export default IconContainer;
