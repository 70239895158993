import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';


const config = {
  defaultNS: 'translations',
  fallbackLng: 'en',
  resources: {
    en
  }
};


const i18nInstance = i18n.use(initReactI18next);
i18nInstance.languages = ['en'];
i18nInstance.init(config);
