import Application from "Application";
import authRoutes from "auth/routes";
import React from "react";
import { createHashRouter } from "react-router-dom";

import BaseRouteRedirect from "layout/BaseRouteRedirect";
import DefaultLayout from "layout/DefaultLayout";
import NoMatchRouteLayout from "layout/NoMatchRouteLayout";
import ProtectedRouteLayout from "layout/ProtectedRouteLayout";
import activityRoutes from "modules/activity/routes";
import agreementsRoutes from "modules/agreements/routes";
import billingRoutes from "modules/billing/routes";
import carbonEnrollmentRoutes from "modules/carbon_enrollment/routes";
import contractsRoutes from "modules/contracts/routes";
import demoRoutes from "modules/demo/routes";
import elevatePlusRoutes from "modules/elevate_plus/routes";
import equipmentRoutes from "modules/equipment/routes";
import fieldsRoutes from "modules/fields/routes";
import imageryRoutes from "modules/imagery/routes";
import inventoryRoutes from "modules/inventory/routes";
import loadsRoutes from "modules/loads/routes";
import mapRoutes from "modules/map/routes";
import marketingRoutes from "modules/marketing/routes";
import notesRoutes from "modules/notes/routes";
import notificationsRoutes from "modules/notifications/routes";
import pricesRoutes from "modules/prices/routes";
import productsRoutes from "modules/products/routes";
import profitAndLossRoutes from "modules/profit_and_loss/routes";
import programEnrollmentRoutes from "modules/program_enrollment/routes";
import reportsRoutes from "modules/reports/routes";
import settingsRoutes from "modules/settings/routes";
import ssoRoutes from "modules/sso/routes";

/* kicks off both of these variables */

const router = createHashRouter([
  {
    element: <Application />,
    children: [
      {
        path: "/",
        element: <BaseRouteRedirect />,
      },

      {
        path: "*",
        element: <NoMatchRouteLayout />,
      },

      {
        ...authRoutes,
      },

      {
        element: <ProtectedRouteLayout />,
        children: [
          ...billingRoutes,
          ...carbonEnrollmentRoutes,
          {
            element: <DefaultLayout />,
            children: [
              ...activityRoutes,
              ...agreementsRoutes,
              ...contractsRoutes,
              ...demoRoutes,
              ...elevatePlusRoutes,
              ...equipmentRoutes,
              ...fieldsRoutes,
              ...imageryRoutes,
              ...inventoryRoutes,
              ...loadsRoutes,
              ...mapRoutes,
              ...marketingRoutes,
              ...notesRoutes,
              ...notificationsRoutes,
              ...productsRoutes,
              ...pricesRoutes,
              ...profitAndLossRoutes,
              ...programEnrollmentRoutes,
              ...reportsRoutes,
              ...settingsRoutes,
            ],
          },
        ],
      },

      ...ssoRoutes,
    ],
  },
]);

export default router;
