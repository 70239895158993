import React from 'react';
import IconContainer from '../IconContainer';


const PlayIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1217.000000, -516.000000)">
        <g transform="translate(932.000000, 110.000000)">
          <g transform="translate(0.000000, 29.000000)">
            <g transform="translate(258.000000, 350.000000)">
              <g transform="translate(25.000000, 25.000000)">
                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,16.5 L10,7.5 L16,12 L10,16.5 Z"
                  fill="currentColor" fillRule="nonzero"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>

  </IconContainer>
);

PlayIcon.iconId = 'play';


export default PlayIcon;
