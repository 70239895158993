import React from 'react';
import IconContainer from '../IconContainer';


const ArrowRightIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M18.58 11.226L14.6 7.154a.77.77 0 0 1 0-1.072.73.73 0 0 1 1.048 0l5.244 5.366a.77.77 0 0 1 0 1.073l-5.244 5.366a.73.73 0 0 1-1.048 0 .77.77 0 0 1 0-1.072l3.98-4.072H3.817c-.374 0-.678-.34-.678-.758 0-.419.304-.759.678-.759H18.58z"
      fill="currentColor" fillRule="nonzero"/>

  </IconContainer>
);

ArrowRightIcon.iconId = 'arrowRight';


export default ArrowRightIcon;
