import React from 'react';
import IconContainer from '../IconContainer';


const MarketingIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M19.657 16.033v1.575c1.872.393 2.702.985 2.702 2.172 0 1.362-.822 1.782-2.702 1.857v.701a.625.625 0 1 1-1.25 0v-.71c-1.07-.084-1.976-.422-2.638-.931a.625.625 0 0 1 .762-.991c.45.346 1.09.591 1.877.668v-1.731c-1.652-.341-2.614-.866-2.614-1.89 0-1.455.89-1.925 2.613-1.999v-.915a.625.625 0 0 1 1.25 0v.938c.792.074 1.575.27 2.218.564a.625.625 0 1 1-.52 1.137 5.733 5.733 0 0 0-1.698-.445zm-1.25-.028c-1.01.047-1.363.25-1.363.747 0 .04.116.16.443.31.243.11.554.21.92.298v-1.355zm1.25 4.382c1.121-.045 1.452-.217 1.452-.607 0-.374-.35-.643-1.452-.895v1.502zM3.502 2.501l.028 17.048h10.007a.75.75 0 1 1 0 1.5H2.78a.75.75 0 0 1-.75-.749L2 1.751A.75.75 0 0 1 2.75 1l16.782.016a.75.75 0 0 1 .749.75v9.765a.75.75 0 1 1-1.5 0V2.515l-15.28-.014zm3.48 7.558a.75.75 0 1 1 0-1.5h7.874a.75.75 0 1 1 0 1.5H6.98zm0-3.212a.75.75 0 0 1 0-1.5h7.874a.75.75 0 1 1 0 1.5H6.98zm0 6.296a.75.75 0 1 1 0-1.5h4.624a.75.75 0 1 1 0 1.5H6.98z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

MarketingIcon.iconId = 'marketing';


export default MarketingIcon;
