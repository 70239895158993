"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNEXPECTED_ERROR = exports.NETWORK_ERROR = void 0;
exports.NETWORK_ERROR = "NETWORK_ERROR";
exports.UNEXPECTED_ERROR = "UNEXPECTED_ERROR";
const SSOErrorIds = {
    NETWORK_ERROR: exports.NETWORK_ERROR,
    UNEXPECTED_ERROR: exports.UNEXPECTED_ERROR,
};
class SSOAuthError extends Error {
    constructor(message, errorId, options) {
        // @ts-ignore
        super(message, options);
        this.name = "SSOAuthError";
        this.errorId = errorId;
    }
}
exports.default = SSOAuthError;
