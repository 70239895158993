/* eslint-disable react/display-name */
import { useMutation } from "@apollo/client";
import _ from "lodash";
import React, { useState } from "react";

import useCropYears from "collection/graphql/fields/hooks/useCropYears";
import { BULK_CREATE_FIELD_CROPS, DELETE_FIELD_CROP, EDIT_FIELD_CROP } from "collection/graphql/fields/mutations";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import App from "layout/app";

import CropFormModal from "components/crop/CropFormModal";
import useFieldData from "fields/hooks/useFieldData";

const defaultState = () => ({
  additionalProps: {},
  crop: null,
  fieldIds: null,
  modalVisible: false,
  resolve: null,
});

const formatIds = (ids) => {
  if (ids) {
    return Array.isArray(ids) ? ids : [ids];
  }

  return [];
};

const withCropMutation = (WrappedComponent) => (props) => {
  const [cropYear, setCropYear] = useCurrentCropYear();
  const cropYears = useCropYears();
  const { fields: allFields } = useFieldData();
  const [state, setState] = useState(defaultState());
  const { additionalProps, crop, fieldIds, modalVisible } = state;
  const fields = allFields?.filter(({ id }) => formatIds(fieldIds).includes(id));
  const refetchQueries = ["GetFieldCrops", "getFieldCropChooserData", "getFieldCropsWithYield"];
  const [bulkCreateFieldCrops, { loading: isCreating }] = useMutation(BULK_CREATE_FIELD_CROPS, { refetchQueries });
  const [deleteFieldCrop, { loading: isDeleting }] = useMutation(DELETE_FIELD_CROP, { refetchQueries });
  const [editFieldCrop, { loading: isUpdating }] = useMutation(EDIT_FIELD_CROP);
  const allYears = _.map(cropYears, ({ year }) => ({ label: year, value: year }));

  const createCrop = (fieldIds, cropDefaults, additionalProps = {}) => {
    /**
     * Initiates the crop creation process.
     *
     * @param {Number} [fieldIds=null | integer | Array<number>]
     * The id(s) of the field(s) with which to associate the new crop. If null/undefined, the modal will start with
     * an empty list of fields.
     * @param {Object} [cropDefaults={ acreage: (acreage of associated field), cropYear: (current year) }]
     * @param {Object} [additionalProps={}] additional props for CropFormModal
     */

    const crop = _.defaults({}, cropDefaults, { cropYear });
    setState({ additionalProps, crop, fieldIds: formatIds(fieldIds), modalVisible: true });
  };

  const editCrop = (crop, additionalProps = {}) => {
    /**
     * Initiates the crop editing process.
     *
     * @param {Number} crop The crop object to edit.
     */

    setState({ additionalProps, crop, fieldIds: [crop.field.id], modalVisible: true });
  };

  const handleModalClose = () => {
    const { onClose } = additionalProps ?? {};
    setState(defaultState());
    onClose && onClose();
  };

  const onDelete = (cropId) => {
    const confirm = "DELETE";
    const message = "This will delete this crop and all of its associated data.";
    App.confirm({ confirm, message }).then(async () => {
      try {
        await deleteFieldCrop({ variables: { cropId } });
        handleModalClose();
      } catch (e) {
        App.notify("An unexpected error occurred");
      }
    });
  };

  const onSave = async ({ fields, ...crop }) => {
    try {
      const { onUpdate } = additionalProps ?? {};

      if (crop.id) {
        await editFieldCrop({ variables: { crop } });
      } else {
        const crops = fields.map(({ id }) => ({ ...crop, fieldId: id }));
        const { data } = await bulkCreateFieldCrops({ variables: { crops } });
        onUpdate && onUpdate(data?.bulkCreateFieldCrops?.crops[0]);
      }

      handleModalClose();
      setCropYear(crop.cropYear);
    } catch (e) {
      App.notify("An unexpected error occurred");
    }
  };

  const newProps = { ...props, createCrop, editCrop };

  return (
    <>
      <WrappedComponent {...newProps} />
      {modalVisible && allFields && (
        <CropFormModal
          allYears={allYears}
          crop={crop}
          disabled={isCreating || isDeleting || isUpdating}
          fields={fields}
          onClose={handleModalClose}
          onDelete={onDelete}
          onSave={onSave}
          {...additionalProps}
        />
      )}
    </>
  );
};

export default withCropMutation;
