import * as yup from "yup";

const UpdateEnterpriseInputSchema = yup.object().shape({
  acreage: yup
    .number("Acreage must be a number")
    .positive("Acreage must be a positive number")
    .integer("Acreage must be a whole number")
    .nullable(),
  address: yup.string().max(100, "Address must be less than 100 characters").nullable(),
  address2: yup.string().max(100, "Address must be less than 100 characters").nullable(),
  city: yup.string().max(100, "City must be less than 100 characters").nullable(),
  country: yup.string().max(2).nullable(),
  name: yup.string().max(100, "Farm Name must be less than 100 characters").nullable(),
  options: yup.object().notRequired().default({}),
  phone: yup.string().max(100, "Phone number must be less than 100 characters").nullable(),
  postalCode: yup.string().max(10, "Zipcode must be less than 10 characters").nullable(),
  state: yup.string().max(2).ensure().nullable(),
});

export default UpdateEnterpriseInputSchema;
