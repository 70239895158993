/* eslint-disable react/display-name */
import { useSuspenseQuery } from "@apollo/client";
import { BasicInput } from "agreements/forms/components";
import MicroLabel from "agreements/forms/components/MicroLabel";
import { css } from "aphrodite";
import _ from "lodash";
import { LeftButton } from "profit_and_loss/styles";
import React, { useReducer } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";

import { withField, withUpdateField } from "collection/graphql/agreements";
import { GET_FIELD } from "collection/graphql/fields/queries";
import useCommodities from "hooks/useCommodities";
import { withPermissions } from "hooks/usePermissions";
import App from "layout/app";
import { PER_ACRE, TOTAL, UPDATE_FIELD_INPUT } from "modules/agreements/constants";

import CommodityChooser from "components/commodity/CommodityChooser";
import { Button } from "components/fl-ui";
import { GroupedButtons } from "components/fl-ui/Buttons";
import Dialog from "components/fl-ui/Dialog";
import { FormGroup, Input, RateSelector } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";
import ModalActionButtonGroup from "components/fl-ui/Modal/ModalActionButtonGroup";
import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";
import { styles } from "fields/common";
import FieldGroupChooser from "fields/components/FieldGroupChooser";
import useFieldData from "fields/hooks/useFieldData";
import { validate } from "fields/validators/fieldEditFormValidator";

const CommodityContainer = styled.div`
  @media only screen and (max-width: 62.5rem) {
    margin-bottom: ${Spacing.regular};
  }
`;

const generateProductionHistoryKey = () => _.uniqueId("productionHistoryKey-");

const withCommodities = (Component) => (props) => {
  const { commodities } = useCommodities();
  return <Component {...props} commodities={commodities} />;
};

const withFieldDelete = (Component) => (props) => {
  const { executeFieldDelete } = useFieldData();
  return <Component {...props} onDelete={executeFieldDelete} />;
};

const withFieldOperations = (Component) => withUpdateField(withField(withFieldDelete(Component)));

const FieldEditModal = withSuspenseWrapper(
  ({ canRead, commodities, createGroup, fieldId, onClose, onDelete, updateField }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const {
      data: { field },
    } = useSuspenseQuery(GET_FIELD, {
      variables: {
        fieldId,
      },
    });

    const reducer = (state, { payload, type }) => {
      switch (type) {
        case "ADD_PRODUCTION_HISTORY_ITEM":
          return {
            ...state,
            productionHistory: _.cloneDeep(state.productionHistory).concat({
              commodityId: "",
              key: generateProductionHistoryKey(),
              yieldPerAcre: "",
            }),
          };

        case "BEGIN_DELETE":
          return {
            ...state,
            isSaving: true,
            saveError: null,
          };

        case "BEGIN_SAVE":
          return {
            ...state,
            errors: {},
            isSaving: true,
            saveError: null,
          };

        case "DELETE_ERROR":
          return {
            ...state,
            isSaving: false,
            saveError: payload.error,
          };

        case "MERGE_FORM_FIELD":
          return {
            ...state,
            ...payload,
          };

        case "REMOVE_PRODUCTION_HISTORY_ITEM": {
          const { itemIndex } = payload;
          const productionHistory = _.cloneDeep(state.productionHistory);

          return {
            ...state,
            productionHistory: _.reject(productionHistory, (__, index) => index === itemIndex),
          };
        }

        case "REQUEST_DELETE_CONFIRMATION":
          return {
            ...state,
            confirmDelete: true,
          };

        case "SAVE_ERROR":
          return {
            ...state,
            errors: payload.errors,
            isSaving: false,
          };

        case "UPDATE_PRODUCTION_HISTORY_ITEM": {
          const { fields, itemIndex } = payload;
          const productionHistory = _.cloneDeep(state.productionHistory);
          Object.assign(productionHistory[itemIndex], fields);

          return {
            ...state,
            productionHistory,
          };
        }
      }
    };

    const updateFormField = (payload) => {
      dispatch({
        payload,
        type: "MERGE_FORM_FIELD",
      });
    };

    const [state, dispatch] = useReducer(reducer, {}, () => ({
      ...field,
      confirmDelete: false,
      isSaving: false,
      errors: {},
      landValue: field.landValueRate === TOTAL ? field.landValueTotal : field.landValuePerAcre,
      landValueRate: field.landValueRate || PER_ACRE,
      productionHistory: _.map(field.productionHistory, ({ commodity, yieldPerAcre }) => ({
        commodityId: commodity.id,
        key: generateProductionHistoryKey(),
        yieldPerAcre,
      })),
      saveError: null,
    }));

    const getBooleanOptions = (trueLabel, falseLabel) => {
      return [true, false].map((option) => ({
        id: `${trueLabel}-${option}`,
        label: _.upperFirst(option ? trueLabel : falseLabel),
        value: option,
      }));
    };

    const handleDelete = async () => {
      try {
        dispatch({ type: "BEGIN_DELETE" });
        await onDelete([fieldId]);
        if (location.pathname !== "/fields") {
          navigate("/fields", { replace: true });
        } else {
          onClose();
        }
      } catch (error) {
        dispatch({
          payload: {
            error,
          },
          type: "DELETE_ERROR",
        });
        App.notify("An unexpected error occurred");
      }
    };

    const handleSave = async () => {
      const { group, ...data } = state;
      dispatch({ type: "BEGIN_SAVE" });

      if (!_.isEmpty(group)) {
        if (group.id) {
          data.fieldGroupId = group.id;
        } else {
          const result = await createGroup(group.name);
          data.fieldGroupId = result.id;
        }
      } else if (_.isNull(group)) {
        data.fieldGroupId = null;
      }

      const fieldData = _.pick(data, UPDATE_FIELD_INPUT);
      fieldData.landValue = fieldData.landValue || null;
      fieldData.landValueRate = _.isNull(fieldData.landValue) ? null : fieldData.landValueRate;
      fieldData.productionHistory = _.pick(fieldData.productionHistory, ["commodityId", "yieldPerAcre"]);

      const errors = validate(fieldData);
      if (!_.isEmpty(errors)) {
        dispatch({
          payload: {
            errors,
          },
          type: "SAVE_ERROR",
        });
      } else {
        await updateField(fieldData);
        onClose();
      }
    };

    const {
      confirmDelete,
      acreage,
      errors,
      fsaFarm,
      fsaTract,
      group,
      isSaving,
      landValue,
      landValueRate,
      name,
      productionHistory,
    } = state;

    const irrigatedOptions = getBooleanOptions("irrigated", "not irrigated");
    const rentedOptions = getBooleanOptions("rented", "owned");
    const tileOptions = getBooleanOptions("drainage tile", "no drainage tile");

    const BooleanButtons = ({ attribute, footer, options, title }) => (
      <Col xs={12} md={6}>
        <GroupedButtons
          onClick={({ value }) => updateFormField({ [attribute]: value })}
          options={options}
          selected={options.find((option) => option.value === !!state[attribute])}
          style={{ borderBottom: "none", marginBottom: Spacing.xxsmall }}
          title={title}
          titleStyle={{ marginBottom: ".4rem" }}
        />

        {footer}
      </Col>
    );

    return confirmDelete ? (
      <Dialog
        dialogBody={`By deleting the field "${name}" you will delete all data associated with the field including crops and activities`}
        dialogControls={
          <>
            <LeftButton color="primary" link onClick={onClose}>
              Cancel
            </LeftButton>
            <Button color="danger" disabled={isSaving} onClick={handleDelete}>
              Confirm
            </Button>
          </>
        }
        dialogHeading="Are you sure?"
        onClose={onClose}
      />
    ) : (
      <Modal width={640}>
        <ModalHeader>
          <ModalTitle>Edit field details</ModalTitle>
          <CloseX onClick={onClose} />
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup label="Name">
                <Input
                  defaultValue={name || ""}
                  hasError={!!errors.name}
                  name="name"
                  onChange={(e, data) => updateFormField(data)}
                  size="large"
                  value={name}
                />
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup label="Group (optional)">
                <FieldGroupChooser onChange={(group) => updateFormField({ group })} value={group?.id} />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <FormGroup label="Acreage">
                <Input
                  defaultValue={_.isNil(acreage) ? "" : (+acreage).toFixed(1)}
                  hasError={!!errors.acreage}
                  name="acreage"
                  onChange={(e, data) => updateFormField(data)}
                  size="large"
                  suffix="ac"
                  type="number"
                />
              </FormGroup>
            </Col>

            <BooleanButtons attribute="isIrrigated" options={irrigatedOptions} title="Is this field irrigated?" />
          </Row>

          <Row>
            <BooleanButtons attribute="isTiled" options={tileOptions} title="Does this field have drainage tile?" />
            <Col xs={12} md={6}>
              <FormGroup label="FSA tract (optional)">
                <Input
                  defaultValue={fsaTract || ""}
                  name="fsaTract"
                  onChange={(e, data) => updateFormField(data)}
                  size="large"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <FormGroup label="FSA farm (optional)">
                <Input
                  defaultValue={fsaFarm || ""}
                  name="fsaFarm"
                  onChange={(e, data) => updateFormField(data)}
                  size="large"
                />
              </FormGroup>
            </Col>

            <BooleanButtons
              attribute="isRented"
              footer={
                <Link className={css(styles.linkText)} to="/land_costs">
                  Add more details with land costs
                </Link>
              }
              options={rentedOptions}
              title="Ownership"
            />
          </Row>

          {canRead("agreements") && (
            <>
              <Row>
                <Col xs md={6}>
                  <FormGroup label="Estimated land value (optional)">
                    <Input
                      display="block"
                      hasError={!!errors.landValue}
                      name="landValue"
                      onChange={({ target }) => updateFormField({ landValue: parseFloat(target.value) || null })}
                      prefix="$"
                      size="large"
                      suffix={
                        <RateSelector
                          onClick={(landValueRate) => updateFormField({ landValueRate })}
                          selected={landValueRate}
                        />
                      }
                      type="number"
                      value={!landValue ? "" : landValue}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup label="Actual production history (optional)">
                {productionHistory.map((history, index) => {
                  const selected = _.find(commodities, ({ id }) => +id === +history.commodityId);

                  return (
                    <Row className={css(styles.aphRow)} key={`${history.key}`}>
                      <Col xs={10} md={11}>
                        <Row>
                          <Col xs={12} md={8}>
                            <CommodityContainer>
                              <CommodityChooser
                                commodityId={+history.commodityId}
                                onChange={({ id }) => {
                                  dispatch({
                                    payload: {
                                      fields: {
                                        commodityId: id,
                                      },
                                      itemIndex: index,
                                    },
                                    type: "UPDATE_PRODUCTION_HISTORY_ITEM",
                                  });
                                }}
                                placeholder="Select commodity"
                                sanitizer={(item) => ({
                                  ...item,
                                  isDisabled: productionHistory.some(({ commodityId }) => +commodityId === +item.id),
                                  value: item.id,
                                })}
                              />
                            </CommodityContainer>
                          </Col>

                          <Col xs={12} md={4}>
                            <MicroLabel
                              className={css(
                                styles.blank_content,
                                (errors.productionHistory || []).includes(index) && styles.blank_contentError
                              )}
                              label="APH"
                            >
                              <BasicInput
                                name="yieldPerAcre"
                                onChange={(event, { yieldPerAcre }) => {
                                  dispatch({
                                    payload: {
                                      fields: {
                                        yieldPerAcre,
                                      },
                                      itemIndex: index,
                                    },
                                    type: "UPDATE_PRODUCTION_HISTORY_ITEM",
                                  });
                                }}
                                suffix={<CommodityYieldUnit commodity={selected} per="acre" />}
                                type="integer"
                                value={history.yieldPerAcre}
                              />
                            </MicroLabel>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={2} md={1} className={css(styles.centerVertically)}>
                        <CloseX
                          color={UIColors.red}
                          onClick={() => {
                            dispatch({
                              payload: {
                                itemIndex: index,
                              },
                              type: "REMOVE_PRODUCTION_HISTORY_ITEM",
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  );
                })}

                <Button
                  className={css(styles.aphButton)}
                  color="secondary"
                  disabled={commodities?.length <= productionHistory.length}
                  onClick={() => {
                    dispatch({
                      type: "ADD_PRODUCTION_HISTORY_ITEM",
                    });
                  }}
                >
                  {`Add APH ${_.isEmpty(productionHistory) ? "" : "for another commodity"}`}
                </Button>
              </FormGroup>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <ModalActionButtonGroup
            apply={{
              action: handleSave,
              buttonText: isSaving ? "Saving..." : "Save field details",
              disabled: isSaving,
            }}
            cancel={{ action: onClose, buttonText: "Cancel" }}
            danger={{
              action: () => dispatch({ type: "REQUEST_DELETE_CONFIRMATION" }),
              buttonText: "Delete",
              disabled: isSaving,
            }}
          />
        </ModalFooter>
      </Modal>
    );
  }
);

export default withPermissions(withFieldOperations(withCommodities(FieldEditModal)));
