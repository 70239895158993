const toRem = (sizeInPixels) => [sizeInPixels / 16, 'rem'].join('');
export const fontSizes = {
  '4xl': toRem(32),
  '3xl': toRem(28),
  '2xl': toRem(24),
  'xl': toRem(20),
  'lg': toRem(16),
  'md': toRem(14),
  'mn': toRem(13),
  'sm': toRem(12),
  'xs': toRem(10),
  '2xs': toRem(8),
};


export const fontWeights = {
  black: 900,
  extrabold: 800,
  bold: 700,
  semibold: 600,
  medium: 500,
  normal: 400,
  light: 300,
  thin: 200,
  hairline: 100,
};
