import chroma from 'chroma-js';

const hsl = (hue, saturation, lightness) => chroma.hsl(hue, saturation, lightness).toString();


const colors = {
  /* blacks */
  black: hsl(.62, .10, .20),              // #2e3138
  darkBlack: hsl(0, 0, 0),                // #000000
  lightBlack: hsl(.61, .10, .30),         // #454a54

  /* blues */
  blue: hsl(210, .70, .50),               // #267fd9
  darkBlue: hsl(210, .70, .30),           // #174c82
  lightBlue: hsl(210, .70, .70),          // #7db2e8
  lightestBlue: hsl(210, 1, .96),         // #ebf5ff

  /* greens */
  green: hsl(135, .40, .45),              // #41a259

  /* reds */
  red: hsl(.03, .73, .59),                // #e3694c

  /* oranges */
  orange: hsl(30, .9, .6),

  /* whites */
  white: hsl(0, 0, 1),                    // #ffffff

  /* grays */
  midGray: hsl(.60, .11, .91),            // #e6e8eb
  darkGray: hsl(.61, .10, .40),           // #5c6370
  lightGray: hsl(0, 0, .80),              // #cccccc

  smoke10: hsl(.61, .10, .10),            // #17191c
  smoke20: hsl(.61, .10, .20),            // #2e3138
  smoke30: hsl(.61, .10, .30),            // #454a54
  smoke40: hsl(.61, .10, .40),            // #5c6370
  smoke50: hsl(220, .1, .50),             // #737b8c
  smoke60: hsl(220, .1, .60),             // #8f96a3
  smoke70: hsl(220, .1, .70),             // #abb0ba
  smoke80: hsl(220, .1, .80),             // #c7cad1
  smoke90: hsl(220, .1, .90),             // #e3e5e8
  smoke94: hsl(220, .1, .94),             // #eeeff1
  smoke97: hsl(220, .1, .97),             // #f7f7f8
  smoke98: hsl(220, .1, .98),             // #fafafa
  smoke99: hsl(220, .1, .99),             // #fdfdfd
};

colors.brand = {
  bushelDarkBlue: '#2d3748'
};


/* input */
colors.input = {
  borderColor: colors.smoke80,
  disabledBackground: colors.smoke94,
  focusBorderColor: colors.blue,
  placeholderColor: colors.lightGray,
};


/* date picker */
colors.datePicker = {
  /* day defaults */
  dayBackgroundHover: colors.smoke97,
  dayBorderColor: colors.smoke97,
  dayBorderColorHover: colors.darkBlue,

  /* day selected */
  dayBackgroundSelected: colors.darkBlue,
  dayBorderColorSelected: colors.lightBlue,
  dayColorSelected: colors.white,

  /* header buttons */
  headerButtonBackground: colors.white,
  headerButtonBorderColor: colors.midGray,
  headerButtonColor: colors.blue,
  headerButtonHoverBackground: colors.midGray,
  headerButtonHoverColor: colors.blue,

  /* header selects */
  headerSelectBorderColor: colors.midGray,
};


export default Object.freeze(colors);
