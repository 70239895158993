import { BaseElement } from "components/fl-ui/common";
import { isNative } from "mobile/mobileManager";
import React from "react";
import ReactMarkdown from "react-markdown";
import WarningBanner from "settings/components/WarningBanner";
import styled from "styled-components";

import useReleaseFeature from "hooks/useReleaseFeature";
import useWindow from "hooks/useWindow";

import { useBannerMarkdownProps } from "components/banners/PrimaryBannerMessage";

const DisabledCookiesMessage = () => (
  <>
    <p>Bushel Farm uses browser cookies to keep others from accessing your private data.</p>
    <p>
      {"To continue using Bushel Farm, please "}
      <a
        href="https://www.wikihow.com/Enable-Cookies-in-Your-Internet-Web-Browser"
        rel="noopener noreferrer"
        target="_blank"
      >
        enable cookies in your browser
      </a>
      {" and refresh this page."}
    </p>
  </>
);

const MaintenanceMessage = ({ config, message }) => {
  const markdownProps = useBannerMarkdownProps({ config, message });

  return <ReactMarkdown {...markdownProps} />;
};

const AuthFormBanner = styled((props) => {
  const showCookiesMessage = !useWindow().navigator.cookieEnabled && !isNative();
  const maintenanceModeProps = useReleaseFeature("maintenance-mode");
  let warningBannerId;
  if (maintenanceModeProps?.id) {
    warningBannerId = "maintenanceMode";
  } else if (showCookiesMessage) {
    warningBannerId = "cookieRequirement";
  }

  return !warningBannerId ? null : (
    <BaseElement {...props}>
      {warningBannerId === "maintenanceMode" && (
        <WarningBanner message={<MaintenanceMessage {...maintenanceModeProps} />} />
      )}
      {warningBannerId === "cookieRequirement" && <WarningBanner message={<DisabledCookiesMessage />} />}
    </BaseElement>
  );
})`
  p:last-child {
    margin-bottom: 0;
  }
`;

export default AuthFormBanner;
