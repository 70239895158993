import React from "react";
import { redirect } from "react-router-dom";
import SettingsPage from "settings/pages/SettingsPage";

export default [
  {
    path: "/settings/activities",
    element: <SettingsPage activeRoute="activities" />,
  },

  {
    path: "/settings/alerts",
    element: <SettingsPage activeRoute="alerts" />,
  },

  {
    path: "/settings/billing",
    element: <SettingsPage activeRoute="billing" />,
  },

  {
    path: "/settings/commodities",
    element: <SettingsPage activeRoute="commodities" />,
  },

  {
    path: "/settings/connections",
    element: <SettingsPage activeRoute="connections" />,
  },

  {
    path: "/settings/data",
    element: <SettingsPage activeRoute="data" />,
  },

  {
    path: "/settings/data/fieldBoundaries",
    element: <SettingsPage activeRoute="data" subroute="fieldBoundaries" />,
  },

  {
    path: "/settings/data/yieldData",
    element: <SettingsPage activeRoute="data" subroute="yieldData" />,
  },

  {
    path: "/settings/expense_and_revenue",
    element: <SettingsPage activeRoute="expense_and_revenue" />,
  },

  {
    path: "/settings/farm",
    element: <SettingsPage activeRoute="farm" />,
  },

  {
    path: "/settings/inputs",
    element: <SettingsPage activeRoute="inputs" />,
  },

  //Remove settings/personal as part of FARM-9187 Auth Teardown rather than just hiding stuff on the page
  {
    path: "/settings/personal",
    element: <SettingsPage activeRoute="personal" />,
  },

  {
    path: "/settings/users",
    element: <SettingsPage activeRoute="users" />,
  },

  /*
   * Redirects
   */
  {
    path: "/settings",
    loader: () => redirect("/settings/personal"),
  },

  {
    path: "/settings/data_integrations",
    loader: () => redirect("/settings/connections"),
  },

  {
    path: "/settings/notifications",
    loader: () => redirect("/settings/alerts"),
  },

  {
    path: "/settings/import/yield",
    loader: () => redirect("/settings/data/yieldData"),
  },

  {
    path: "/settings/integrations",
    loader: () => redirect("/settings/connections"),
  },

  {
    path: "/settings/subscriptions",
    loader: () => redirect("/settings/billing"),
  },
];
