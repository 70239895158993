import BinDetailWrapper from "inventory/BinDetailWrapper";
import InventoryWrapper from "inventory/InventoryWrapper";
import InventoryCollectionsLoader from "inventory/components/InventoryCollectionsLoader";
import React from "react";

export default [
  {
    path: "/inventory",
    element: (
      <InventoryCollectionsLoader>
        <InventoryWrapper />
      </InventoryCollectionsLoader>
    ),
  },

  {
    path: "/inventory/bin/:id",
    element: (
      <InventoryCollectionsLoader>
        <BinDetailWrapper />
      </InventoryCollectionsLoader>
    ),
  },
];
