import Backbone from "backbone";

/**
 * @class Collection
 * @extends Backbone.Collection
 * @event sync:start
 * @event sync:change
 * @event sync:complete
 */
export default Backbone.Collection.extend({
  constructor() {
    Backbone.Collection.apply(this, arguments);

    let primeHasBeenCalled = false;
    this.prime = () => {
      if (!primeHasBeenCalled) {
        primeHasBeenCalled = true;
        return this.fetch();
      }

      return Promise.resolve();
    };
  },

  /**
   * @abstract
   */
  name: "",
});
