import React from 'react';
import IconContainer from '../IconContainer';


const ChevronRightIcon = (props) => (
  <IconContainer {...props}>
    <g
      transform="rotate(90 12 12)"
      fill="none"
      fillRule="evenodd"
    >
      <g id="chevron-right" fillRule="nonzero" fill="currentColor">
        <path
          d="M12.108,9.8105881 L18.935706,16.6373661 C19.2286191,16.9302394 19.7034928,16.9302072 19.9963661,16.637294 C20.2892394,16.3443809 20.2892072,15.8695072 19.996294,15.5766339 L12.638294,8.21963388 C12.3454091,7.92678871 11.8705909,7.92678871 11.577706,8.21963388 L4.21970596,15.5766339 C3.92679283,15.8695072 3.92676056,16.3443809 4.21963388,16.637294 C4.51250719,16.9302072 4.98738092,16.9302394 5.28029404,16.6373661 L12.108,9.8105881 Z"
          id="caret-up"
        />
      </g>
    </g>

  </IconContainer>
);

ChevronRightIcon.iconId = 'chevronRight';


export default ChevronRightIcon;
