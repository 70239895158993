"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const queries_1 = require("collection/graphql/enterprise/queries");
const useMarketingAdvisor = () => {
    const { currentMembership } = (0, client_1.useSuspenseQuery)(queries_1.getCurrentUserMembership).data;
    const role = currentMembership.role.name;
    const isMarketingAdvisor = ["marketing_advisor", "cargill_admin"].includes(role);
    return {
        isMarketingAdvisor,
    };
};
exports.default = useMarketingAdvisor;
