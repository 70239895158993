"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const oidc_client_ts_1 = require("oidc-client-ts");
const storageKey = `oidc.user:${process.env.KEYCLOAK_AUTH_ISSUER}:${process.env.KEYCLOAK_CLIENT_ID}`;
const getInitialValue = () => {
    const oidcStorage = localStorage.getItem(storageKey);
    return oidcStorage ? oidc_client_ts_1.User.fromStorageString(oidcStorage) : null;
};
const ssoUserVar = (0, client_1.makeVar)(getInitialValue());
const persist = (user) => {
    if (!user) {
        localStorage.removeItem(storageKey);
    }
    else {
        localStorage.setItem(storageKey, JSON.stringify(user));
    }
    ssoUserVar.onNextChange(persist);
};
ssoUserVar.onNextChange(persist);
/*
 * When other tabs
 */
window.addEventListener("storage", (event) => __awaiter(void 0, void 0, void 0, function* () {
    if (event.key === storageKey) {
        const { newValue } = event;
        ssoUserVar(newValue ? oidc_client_ts_1.User.fromStorageString(newValue) : null);
    }
}));
exports.default = ssoUserVar;
