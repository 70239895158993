"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
exports.default = (0, client_1.gql) `
  mutation createUserMembership($input: CreateUserMembershipInput!) {
    userMembership: createUserMembership(input: $input) @rest(type: "UserMembership", path: "/users/register_from_sso", method: "POST") {
      id
    }
  }
`;
