import themeColors from './colors';
import * as typography from './typography';


/*
 * Globals
 */
const colors = {
  ...themeColors,
};


/*
 * Borders
 */
const borders = {
  basic: `1px solid ${colors.smoke80}`,
  dashed: `1px dashed ${colors.smoke80}`,
  none: 'none'
};

/*
 * Breakpoints
 * @see https://styled-system.com/theme-specification/#media-queries
 */
const breakpoints = [
  '360px',    /* iPhone 5 */
  '375px',    /* iPhone 6/7/8 */
  '62.5rem',  /* desktop */
];
breakpoints.small = breakpoints[0];
breakpoints.medium = breakpoints[1];
breakpoints.desktop = breakpoints[2];


/*
 * Space
 */
const space = ['2px', '5px', '8px', '12px'];


/*
 * Button variants
 */
const commonButtons = {
  border: borders.basic,
  borderRadius: 3,
  '&:focus': {
    outline: `1px dotted ${colors.midGray}`
  }
};

const buttons = {
  danger: {
    ...commonButtons,
    backgroundColor: colors.red,
    borderColor: colors.red,
    color: colors.white
  },

  dark: {
    ...commonButtons,
    backgroundColor: colors.lightBlack,
    color: colors.white
  },

  light: {
    ...commonButtons,
    backgroundColor: colors.white,
    color: colors.lightBlack
  },

  link: {
    ...commonButtons,
    backgroundColor: colors.white,
    border: borders.none,
    color: colors.blue,
    '&:focus': {
      outline: 'none'
    }
  },

  primary: {
    ...commonButtons,
    backgroundColor: colors.blue,
    color: colors.white
  },

  secondary: {
    ...commonButtons,
    backgroundColor: colors.smoke99,
    color: colors.blue
  }
};


/*
 * File attachments
 */
const attachments = {
  previewHeight: 100,
  previewWidth: 100
};


/*
 * Overlay variants
 */
const popover = {
  arrow: {
    borderColor: colors.smoke97,
    borderWidth: '8px',
    px: '8px',
    py: '12px'
  },
  body: {
    backgroundColor: colors.white,
    borderRadius: '4px',
    boxShadow: `0 2px 4px 0 rgba(0 ,0, 0, 0.15), inset 0 0 0 1px ${colors.smoke97}`,
    padding: '8px 12px',
    minWidth: '240px'
  }
};


const tooltip = {
  arrow: {
    borderColor: colors.black,
    borderStyle: 'solid',
    borderWidth: '5px',
    opacity: .75,
  },
  body: {
    backgroundColor: colors.black,
    borderRadius: '3px',
    color: colors.white,
    opacity: .75,
    padding: '8px 12px',
    textAlign: 'center'
  }
};


export default {
  attachments,
  borders,
  breakpoints,
  buttons,
  colors,
  fontSizes: typography.fontSizes,
  fontWeights: typography.fontWeights,
  popover,
  space,
  tooltip
};
