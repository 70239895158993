import React from 'react';
import IconContainer from '../IconContainer';


const CollapseIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <polygon points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M7.41,18.59 L8.83,20 L12,16.83 L15.17,20 L16.58,18.59 L12,14 L7.41,18.59 Z M16.59,5.41 L15.17,4 L12,7.17 L8.83,4 L7.41,5.41 L12,10 L16.59,5.41 Z"
          fill="currentColor" fillRule="nonzero"></path>
      </g>
    </g>

  </IconContainer>
);

CollapseIcon.iconId = 'collapse';


export default CollapseIcon;
