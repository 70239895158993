import React from 'react';
import IconContainer from '../IconContainer';


const RainfallIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="currentColor" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M7.711 5.525h.033s1.16.05 1.16 0c0-2.038 1.315-3.89 3.66-3.89 2.404 0 4.074 1.544 4.074 3.925a3.139 3.139 0 0 1 2.804 3.114 3.15 3.15 0 0 1-3.149 3.149H7.711a3.149 3.149 0 1 1 0-6.298z"
        strokeWidth="1.5"/>
      <path
        d="M16.222 14.591l-.272.784M12.24 14.556l-.3.773M8.244 14.556l-.3.773M14.874 18.471l-.284.819M10.892 18.435l-.313.81M6.895 18.435l-.312.809M9.614 22.11l-.316.823"
        strokeWidth="2"/>
    </g>

  </IconContainer>
);

RainfallIcon.iconId = 'rainfall';


export default RainfallIcon;
