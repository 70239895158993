import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import SeedCommodityChooser from "resources/containers/SeedCommodityChooser";
import { ValidationError } from "yup";

import useProductMutations from "collection/graphql/products/hooks/useProductMutations";
import ChemicalProductSchema from "collection/graphql/products/schemas/ChemicalProductSchema";
import FertilizerProductSchema, {
  PercentagesSchema,
} from "collection/graphql/products/schemas/FertilizerProductSchema";
import SeedProductSchema from "collection/graphql/products/schemas/SeedProductSchema";
import App from "layout/app";
import { CHEMICAL, COUNT, FERTILIZER, SEED, VOLUME, WEIGHT } from "lib/constants";

import { Button } from "components/fl-ui";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";

function connectValue(name, cb) {
  return (e) => cb({ [name]: e.target.value });
}

function connectBool(name, cb) {
  return (e) => {
    const { value } = e.target;
    cb({
      // this is stupid because the input sends a string value and it can be nullish.
      [name]: value === "true" || value === "false" ? value === "true" : null,
    });
  };
}

const ControlGroup = ({ label, error, children }) => {
  return (
    <div className="control-group">
      <label className="control-label">{label}</label>
      <div className="controls">
        {children}
        {error && <div className="help-inline"> {error} </div>}
      </div>
    </div>
  );
};

const BasicDetails = (props) => {
  const { type, data, onChange, error } = props;

  return (
    <div>
      <ControlGroup error={error.name} label={`${type} Name`}>
        <input name="name" onChange={connectValue("name", onChange)} type="text" value={data.name} />
      </ControlGroup>
      <ControlGroup label="Brand">
        <input name="brand" onChange={connectValue("brand", onChange)} type="text" value={data.brand} />
      </ControlGroup>
    </div>
  );
};

const SeedDetails = (props) => {
  const { data, onChange, error } = props;

  return (
    <div>
      <BasicDetails type="Seed" {...props} />
      <ControlGroup error={error.commodityId} label="Commodity">
        <SeedCommodityChooser
          commodityId={data.commodityId}
          inputId="seedCommodityId"
          onChange={({ id, seedUnitType }) =>
            onChange({
              commodityId: id,
              unitType: seedUnitType.replace(/_/gi, "-").toLowerCase(),
            })
          }
        />
      </ControlGroup>
      <ControlGroup label="Generic?">
        <div className="select-wrapper">
          <select name="generic" onChange={connectBool("generic", onChange)} value={data.generic}>
            <option value>Yes, Generic</option>
            <option value={false}>No, Not Generic</option>
          </select>
        </div>
      </ControlGroup>
      <ControlGroup error={error.relativeMaturity} label="Relative Maturity">
        <input
          name="relativeMaturity"
          onChange={connectValue("relativeMaturity", onChange)}
          type="number"
          value={data.relativeMaturity}
        />
      </ControlGroup>
      <ControlGroup label="Notes">
        <textarea name="notes" onChange={connectValue("notes", onChange)} value={data.notes} />
      </ControlGroup>
    </div>
  );
};

const FertChemDetails = (props) => {
  const { constraints, data, onChange, error } = props;
  useEffect(() => {
    if (constraints.unitType) {
      const unitType = _.toUpper(constraints.unitType);
      onChange({ unitType });
    }
  }, []);

  return (
    <div>
      <ControlGroup error={error.unitType} label="Unit Type">
        <div className="select-wrapper">
          <select
            disabled={!!constraints.unitType}
            name="unitType"
            onChange={connectValue("unitType", onChange)}
            value={data.unitType}
          >
            <option>Choose Type...</option>
            <option value="VOLUME">Volume</option>
            <option value="WEIGHT">Weight</option>
          </select>
        </div>
      </ControlGroup>
      <ControlGroup error={error.density} label="Density">
        <input name="density" onChange={connectValue("density", onChange)} type="number" value={data.density} />
      </ControlGroup>
    </div>
  );
};

const FertilizerDetails = (props) => {
  const { data, onChange, error } = props;

  return (
    <div>
      <BasicDetails type="Fertilizer" {...props} />
      <FertChemDetails {...props} />

      <ControlGroup error={error.percentNitrogen || error.percentages} label="Percent Nitrogen (N)">
        <div className="input-append">
          <input
            className="input-70"
            name="percentNitrogen"
            onChange={connectValue("percentNitrogen", onChange)}
            type="text"
            value={data.percentNitrogen}
          />
          <span className="add-on">%</span>
        </div>
      </ControlGroup>
      <ControlGroup error={error.percentPhosphate} label="Percent Phosphate (P)">
        <div className="input-append">
          <input
            className="input-70"
            name="percentPhosphate"
            onChange={connectValue("percentPhosphate", onChange)}
            type="text"
            value={data.percentPhosphate}
          />
          <span className="add-on">%</span>
        </div>
      </ControlGroup>
      <ControlGroup error={error.percentPotash} label="Percent Potash/Potassium (K)">
        <div className="input-append">
          <input
            className="input-70"
            name="percentPotash"
            onChange={connectValue("percentPotash", onChange)}
            type="text"
            value={data.percentPotash}
          />
          <span className="add-on">%</span>
        </div>
      </ControlGroup>
      <ControlGroup error={error.percentZinc} label="Percent Zinc (Zn)">
        <div className="input-append">
          <input
            className="input-70"
            name="percentZinc"
            onChange={connectValue("percentZinc", onChange)}
            type="text"
            value={data.percentZinc}
          />
          <span className="add-on">%</span>
        </div>
      </ControlGroup>
      <ControlGroup error={error.percentSulfate} label="Percent Sulfate">
        <div className="input-append">
          <input
            className="input-70"
            name="percentSulfate"
            onChange={connectValue("percentSulfate", onChange)}
            type="text"
            value={data.percentSulfate}
          />
          <span className="add-on">%</span>
        </div>
      </ControlGroup>
      <ControlGroup label="Notes">
        <textarea name="notes" onChange={connectValue("notes", onChange)} value={data.notes} />
      </ControlGroup>
    </div>
  );
};

const ChemicalDetails = (props) => {
  const { data, onChange, error } = props;

  return (
    <div>
      <BasicDetails type="Chemical" {...props} />
      <FertChemDetails {...props} />
      <ControlGroup error={error.chemicalType} label="Chemical Type">
        <div className="select-wrapper">
          <select
            name="chemicalType"
            onChange={connectValue("chemicalType", onChange)}
            value={_.toLower(data.chemicalType)}
          >
            <option>Choose Type...</option>
            <option value="adjuvant">Adjuvant</option>
            <option value="fungicide">Fungicide</option>
            <option value="herbicide">Herbicide</option>
            <option value="insecticide">Insecticide</option>
          </select>
        </div>
      </ControlGroup>
      <ControlGroup label="EPA Number">
        <input name="epaNumber" onChange={connectValue("epaNumber", onChange)} type="text" value={data.epaNumber} />
      </ControlGroup>
      <ControlGroup label="Restricted Use?">
        <div className="select-wrapper">
          <select name="restrictedUse" onChange={connectBool("restrictedUse", onChange)} value={data.restrictedUse}>
            <option>Select Option...</option>
            <option value>Yes, Restricted</option>
            <option value={false}>No, Not Restricted</option>
          </select>
        </div>
      </ControlGroup>
      <ControlGroup label="Notes">
        <textarea name="notes" onChange={connectValue("notes", onChange)} value={data.notes} />
      </ControlGroup>
    </div>
  );
};

const ProductDetails = (props) => {
  const {
    data: { type },
  } = props;

  switch (type) {
    case CHEMICAL:
      return <ChemicalDetails {...props} />;
    case FERTILIZER:
      return <FertilizerDetails {...props} />;
    case SEED:
      return <SeedDetails {...props} />;
    default:
      return <noscript />;
  }
};

const ProductTypeSelect = ({ unitTypeConstraint, ...props }) => {
  const options = [CHEMICAL, FERTILIZER, SEED];
  if (unitTypeConstraint === VOLUME) {
    options.pop();
  }

  return (
    <select {...props}>
      <option>Choose Type...</option>
      {options.map((type) => (
        <option key={type} value={type}>
          {_.startCase(_.toLower(type))}
        </option>
      ))}
    </select>
  );
};

ProductTypeSelect.propTypes = {
  unitTypeConstraint: PropTypes.oneOf([COUNT, VOLUME, WEIGHT]),
};

const ProductInputModal = ({ constraints, onClose, onFinish, product }) => {
  const [productType, setProductType] = useState(product?.type ?? "");
  const formId = useMemo(() => {
    const key = _.toUpper(productType);
    const formIds = {
      CHEMICAL: "input-chemical-form",
      FERTILIZER: "input-fertilizer-form",
      SEED: "input-seed-form",
    };
    return formIds[key] || "input-product-form";
  }, [productType]);

  const [data, dispatch] = useReducer(
    (state, { action, payload }) => {
      switch (action) {
        case "mergeData":
          return {
            ...state,
            ...payload,
          };

        case "resetData":
          return { ...payload };
      }
    },
    {},
    () => {
      const data = _.cloneDeep(product || {});
      if (product?.commodity?.id) {
        data.commodityId = data.commodity.id;
      }

      return data;
    }
  );

  const [saveProduct, deleteProduct] = useProductMutations();
  const [errors, setErrors] = useState({});
  const updateData = (newData) => {
    setErrors(_.omit(errors, Object.keys(newData)));
    dispatch({
      action: "mergeData",
      payload: newData,
    });
  };

  const schemas = new Map([
    ["CHEMICAL", ChemicalProductSchema],
    ["FERTILIZER", FertilizerProductSchema],
    ["SEED", SeedProductSchema],
  ]);

  const validate = () => {
    const schema = schemas.get(data.type.toUpperCase());
    const errors = {};
    let sanitizedValues;
    try {
      sanitizedValues = schema.validateSync(data, {
        abortEarly: false,
        stripUnknown: true,
      });

      if (sanitizedValues.type === "fertilizer") {
        PercentagesSchema.validateSync(sanitizedValues);
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        for (const { message, path } of error.inner) {
          errors[path] = message;
        }
        if (error.inner.length === 0) {
          errors[error.path] = error.message;
        }
      } else {
        throw error;
      }
    }

    if (_.isEmpty(errors)) {
      return [undefined, sanitizedValues];
    } else {
      return [errors, undefined];
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      onFinish();
    }
  };

  const handleDelete = () => {
    App.confirm({
      message: `This will delete ${data.name} and all of its associated data, including usage records and purchase history.`,
      confirm: "DELETE",
    }).then(async () => {
      await deleteProduct({
        variables: {
          id: product.id,
        },
      });
      onFinish();
    });
  };

  const handleProductTypeChange = (event) => {
    setProductType(event.target.value);
    dispatch({
      action: "resetData",
      payload: {
        type: event.target.value,
      },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const [errors, product] = validate();
    setErrors(errors || {});
    if (product) {
      const { data } = await saveProduct(product);
      onFinish(data.product);
    }
  };

  return (
    <Modal width={700}>
      <ModalHeader>
        <ModalTitle> {data.id ? "Edit" : "Add"} Product </ModalTitle>
        <CloseX onClick={handleClose} />
      </ModalHeader>

      <ModalBody>
        <form className="form-horizontal" id={formId} onSubmit={handleSubmit}>
          <ControlGroup label="Type">
            <div className="select-wrapper">
              <ProductTypeSelect
                disabled={!!productType}
                name="type"
                onChange={handleProductTypeChange}
                unitTypeConstraint={constraints.unitType}
                value={data.type}
              />
            </div>
          </ControlGroup>

          <ProductDetails constraints={constraints} data={data} error={errors} onChange={updateData} />
          {data.id && <input type="hidden" name="id" value={data.id} />}
        </form>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleClose}>Cancel</Button>
        &nbsp;
        <Button color="primary" disabled={!productType} form={formId} type="submit">
          Save {_.startCase(_.toLower(data?.type)) || "Product"}
        </Button>
        &nbsp;
        {data.id && (
          <Button color="danger" onClick={handleDelete}>
            Delete
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

ProductInputModal.propTypes = {
  constraints: PropTypes.shape({
    unitType: PropTypes.oneOf([COUNT, VOLUME, WEIGHT]),
  }),
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  product: PropTypes.shape,
};

ProductInputModal.defaultProps = {
  constraints: {},
};

export default ProductInputModal;
