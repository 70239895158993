/*
Crud View

Purpose:
All Views that perform CRUD share some fundamental similarities.  This view
provides methods for those situations.

Usage:
Declare the collection.
Call set_model().
Define a render and getData method.
Call core methods (ie save and cancel) at the appropriate times.

*/
import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";

import App from "layout/app";

const updateModel = function (e) {
  const {
    target: { name, type, checked, value },
  } = e;

  if (!name) {
    return;
  }

  const newValue = (() => {
    switch (type) {
      case "checkbox":
        return checked;
      case "radio":
        // If using radio buttons for boolean values
        if (value === "true") {
          return true;
        } else if (value === "false") {
          return false;
        } else {
          return value.trim();
        }
      case "number":
      case "hidden":
        return +value || value.trim();
      default:
        return value;
    }
  })();

  this.data.set(name, newValue);
};

export default Backbone.View.extend({
  // Events to be extended from the crud file
  events: {
    "input :input": updateModel,
    "change :input": updateModel,
    remove: "remove",
  },
  initialize() {
    if (this.collection == null) {
      this.collection = this.constructor.collection;
    }
    this.data = new Backbone.Model();

    if (this.model != null) {
      this.listenTo(this.model, "invalid", this.error);
      return this.listenTo(this.model, "change", function () {
        // notify changes without validation
        const rules = _.keys(this.model.validation);
        const attrs = _.keys(this.model.changed);
        const name = _.singularize(_.titleize(this.model.collection != null ? this.model.collection.name : undefined));

        return attrs.map(function (attr) {
          if (!_.contains(rules, attr)) {
            return console.warn(`Setting ${name} attribute \"${attr}\" without any validation rules.`);
          }
        });
      });
    }
  },
  /*
    Finish

    Complete the editing session.
    */
  finish() {
    return this.trigger("finish", this.model);
  },
  /*
    Cancel

    Abort any edits that were made.
    */
  cancel() {
    this.trigger("cancel");
    return this.finish();
  },
  /*
    Save

    Make the changes we have made permanent.
    */
  save() {
    this.model.set(this.data.toJSON());
    if (this.model.save()) {
      this.model.on("error", function () {
        App.notify("Save failed. There was a server error.");
        return this.collection != null ? this.collection.remove(this) : undefined;
      });

      if (!this.model.collection) {
        this.collection.add(this.model);
      }
      if (this.collection.comparator) {
        this.collection.sort();
      }
      this.trigger("save", this.model);
      return this.finish();
    }
  },
  /*
    Delete

    Destroy the model that is currently being edited.
    */
  delete() {
    const deferred = $.Deferred();

    const name = this.model.has("name")
      ? `\"${this.model.get("name")}\"`
      : `this ${_(this.collection.name).singularize().humanize(true).titleize().value()}`;

    App.confirm({
      message: `This will delete ${name} and all of its associated data.`,
      confirm: "DELETE",
    })
      .then(() => {
        this.model.destroy();
        App.notify(_(this.collection.name).singularize().humanize().value() + " deleted.");
        deferred.resolve();
        return this.finish();
      })
      .fail(deferred.reject);

    return deferred.promise();
  },
  /*
    Error

    Gracefully handle errors and inform the user of them.
    */
  error(model, errors) {
    return this.validation_error(model, errors);
  },
  /*
    Validation Error

    Display any validation errors that occurred.
    */
  validation_error(model, errors) {
    console.log([`invalid ${_.singularize(this.collection.name)}`, model, errors]);

    this.validation_clear();

    for (const attr in errors) {
      const msg = errors[attr];
      const errorText = msg || "Please correct this field";
      const $errorElem = this.$(`[name='${attr}']`).parents(".control-group");

      $errorElem.addClass("error");

      if ($errorElem.find(".js-help-inline").length) {
        $errorElem.find(".js-help-inline").text(errorText);
      }
    }

    if (this.$el.find(".error").length) {
      return this.$el.animate({
        scrollTop: this.$(".error").first().offset().top,
      });
    }
  },
  /*
    Clear Validation

    Clear any currently visible validation errors.
    */
  validation_clear() {
    this.$(".control-group").removeClass("error");
    return this.$(".js-help-inline").text("");
  },
  remove() {
    // Reset model if changes weren't saved
    if ((this.model != null ? this.model.id : undefined) && this.model.hasChangedSinceSync && !this.model.saving) {
      this.model.fetch();
    }

    return Backbone.View.prototype.remove.call(this);
  },
});
