import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';
import { addMonths, format, subMonths } from 'date-fns';

import Button from '../Button';


const CalendarHeaderContainer = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;

  button {
    background: ${themeGet('colors.datePicker.headerButtonBackground')};
    border: 1px solid ${themeGet('colors.datePicker.headerButtonBorderColor')};
    color: ${themeGet('colors.datePicker.headerButtonColor')};
    line-height: 1rem;
    padding: 2px 0 0 4px;
    text-align: center;
  }
  button:hover {
    background: ${themeGet('colors.datePicker.headerButtonHoverBackground')};
    color: ${themeGet('colors.datePicker.headerButtonHoverColor')};
  }
  select {
    border: 1px solid ${themeGet('colors.datePicker.headerSelectBorderColor')};
    font-size: ${themeGet('fontSizes.md')};
    font-weight: ${themeGet('fontWeights.bold')};
    padding: 5px;
  }
  select + select {
    margin-left: 4px;
  }
`;


const CalendarHeader = (props) => {
  const { date, onChange, yearLimitMax, yearLimitMin } = props;
  const month = date.getMonth();
  const year = date.getFullYear();
  const minYear = Math.min(year, new Date().getFullYear() - yearLimitMin);
  const maxYear = Math.max(year, new Date().getFullYear() + yearLimitMax);

  const monthRange = _.range(0,12);
  const nextMonth = () => onChange(addMonths(date, 1));
  const previousMonth = () => onChange(subMonths(date, 1));
  const updateMonth = (event) => onChange(new Date(date.getFullYear(), +event.target.value, date.getDate()));
  const updateYear = (event) => onChange(new Date(+event.target.value, date.getMonth(), date.getDate()));
  const yearRange = _.range(minYear, maxYear + 1);


  return (
    <CalendarHeaderContainer>
      <Button icon="arrowLeftSmall" variant={null} onClick={previousMonth} />

      <div>
        <select name="month" onChange={updateMonth} value={month}>
          {monthRange.map((value) => (
            <option key={`month${value}`} value={value}>
              {format(new Date(2000, value), 'MMMM')}
            </option>
          ))}
        </select>

        <select name="month" onChange={updateYear} value={year}>
          {yearRange.map((year) => (
            <option key={`year${year}`} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      <Button icon="arrowRightSmall" variant="link" onClick={nextMonth} />
    </CalendarHeaderContainer>
  );
};

CalendarHeader.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  yearLimitMax: PropTypes.number,
  yearLimitMin: PropTypes.number
};

CalendarHeader.defaultProps = {
  yearLimitMax: 2,
  yearLimitMin: 20
};


export default CalendarHeader;
