import { isEmpty } from "lodash";

const requiredAttributes = ["acreage", "name"];

export const isValid = (fields) => isEmpty(validate(fields));

export const validate = (fields, errors = {}) => {
  requiredAttributes.forEach((attribute) => {
    if (!fields[attribute]) {
      errors[attribute] = { value: fields[attribute] };
    }
  });

  if (fields.name && fields.name.trim().length === 0) {
    errors.name = { value: fields.name };
  }

  if (isNaN(fields.acreage) || fields.acreage <= 0) {
    errors.acreage = { value: fields.acreage };
  }

  if (fields.landValue && isNaN(fields.landValue)) {
    errors.landValue = { value: fields.landValue };
  }

  if (!isEmpty(fields.productionHistory)) {
    const invalidHistory = [];

    fields.productionHistory.forEach((history, index) => {
      if (isNaN(history.yieldPerAcre) || history.yieldPerAcre <= 0) {
        invalidHistory.push(index);
      }
    });

    if (!isEmpty(invalidHistory)) {
      errors.productionHistory = invalidHistory;
    }
  }

  return errors;
};
