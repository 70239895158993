import React from 'react';
import IconContainer from '../IconContainer';


const LoadsIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M3.554 19.008a1.576 1.576 0 1 0 2.721-.038l-2.721.038zm18.288-4.829h-4.754a.75.75 0 0 1-.75-.75V9.515a.75.75 0 0 1 .75-.75h4.466a3.259 3.259 0 0 0-2.97-1.917h-4.185v10.684h7.443V14.18zm0-1.5v-2.414h-4.004v2.414h4.004zm-7.443-7.33h4.185a4.758 4.758 0 0 1 4.758 4.757v8.176a.75.75 0 0 1-.75.75h-1.023a3.075 3.075 0 1 1-5.965 0H7.908a3.075 3.075 0 1 1-5.965 0H.75a.75.75 0 0 1-.75-.75V2.75A.75.75 0 0 1 .75 2h12.899a.75.75 0 0 1 .75.75v2.598zm2.802 13.683a1.575 1.575 0 1 0 2.77 0h-2.77zm-4.302-1.5V3.5H1.5v14.032h11.399z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

LoadsIcon.iconId = 'loads';


export default LoadsIcon;
