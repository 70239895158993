import { gql } from "@apollo/client";

const getFieldStats = gql`
  query GetFieldStats {
    fieldStats: getFieldStats {
      totalFields
      totalAcreage
    }
  }
`;

export default getFieldStats;
