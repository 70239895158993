import React from 'react';
import IconContainer from '../IconContainer';


const CalendarIcon = (props) => (
  <IconContainer {...props}>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="none">
        <path
          d="M12.554 17.382v2.497h2.521v-2.497h-2.521zm0-1.5h2.521v-2.453h-2.521v2.453zm6.47 1.5h-2.449v2.497h2.06a.39.39 0 0 0 .39-.391v-2.106zm0-1.5v-2.453h-2.449v2.453h2.45zm-7.97 1.5H8.533v2.497h2.521v-2.497zm0-1.5v-2.453H8.533v2.453h2.521zm-6.554 1.5v2.106c0 .215.175.39.39.39h2.143v-2.496H4.5zm0-1.5h2.533v-2.453H4.5v2.453zM16.577 5.764L16.583 8l-1.5.004-.006-2.24H8.536l.006 2.258-1.5.004-.006-2.262H4.89a.391.391 0 0 0-.391.39v5.775h14.525V6.154a.39.39 0 0 0-.39-.39h-2.058zm-.004-1.5h2.062a1.89 1.89 0 0 1 1.89 1.89v13.334a1.89 1.89 0 0 1-1.89 1.89H4.89A1.891 1.891 0 0 1 3 19.489V6.154c0-1.044.847-1.89 1.89-1.89h2.142l-.007-2.238 1.5-.004.007 2.242h6.541l-.006-2.26 1.5-.004.006 2.264z"/>
      </mask>
      <path
        d="M12.554 17.382v2.497h2.521v-2.497h-2.521zm0-1.5h2.521v-2.453h-2.521v2.453zm6.47 1.5h-2.449v2.497h2.06a.39.39 0 0 0 .39-.391v-2.106zm0-1.5v-2.453h-2.449v2.453h2.45zm-7.97 1.5H8.533v2.497h2.521v-2.497zm0-1.5v-2.453H8.533v2.453h2.521zm-6.554 1.5v2.106c0 .215.175.39.39.39h2.143v-2.496H4.5zm0-1.5h2.533v-2.453H4.5v2.453zM16.577 5.764L16.583 8l-1.5.004-.006-2.24H8.536l.006 2.258-1.5.004-.006-2.262H4.89a.391.391 0 0 0-.391.39v5.775h14.525V6.154a.39.39 0 0 0-.39-.39h-2.058zm-.004-1.5h2.062a1.89 1.89 0 0 1 1.89 1.89v13.334a1.89 1.89 0 0 1-1.89 1.89H4.89A1.891 1.891 0 0 1 3 19.489V6.154c0-1.044.847-1.89 1.89-1.89h2.142l-.007-2.238 1.5-.004.007 2.242h6.541l-.006-2.26 1.5-.004.006 2.264z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <g mask="url(#b)" fill="none">
        <path d="M0 0h24v24H0z"/>
      </g>
    </g>

  </IconContainer>
);

CalendarIcon.iconId = 'calendar';


export default CalendarIcon;
