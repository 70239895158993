"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SIGN_UP = exports.SELECT_MEMBERSHIP = exports.ERROR = exports.DONE = exports.CREATE_ENTERPRISE = exports.CONNECT_TO_CONTRACTS = exports.CONFIRM_FARM_NAME = exports.COLLECT_USER_DETAILS = exports.COLLECT_EMAIL = void 0;
exports.COLLECT_EMAIL = "COLLECT_EMAIL";
exports.COLLECT_USER_DETAILS = "COLLECT_USER_DETAILS";
exports.CONFIRM_FARM_NAME = "CONFIRM_FARM_NAME";
exports.CONNECT_TO_CONTRACTS = "CONNECT_TO_CONTRACTS";
exports.CREATE_ENTERPRISE = "CREATE_ENTERPRISE";
exports.DONE = "DONE";
exports.ERROR = "ERROR";
exports.SELECT_MEMBERSHIP = "SELECT_MEMBERSHIP";
exports.SIGN_UP = "SIGN_UP";
const SSOPossibleResults = {
    COLLECT_EMAIL: exports.COLLECT_EMAIL,
    COLLECT_USER_DETAILS: exports.COLLECT_USER_DETAILS,
    CONFIRM_FARM_NAME: exports.CONFIRM_FARM_NAME,
    CONNECT_TO_CONTRACTS: exports.CONNECT_TO_CONTRACTS,
    CREATE_ENTERPRISE: exports.CREATE_ENTERPRISE,
    DONE: exports.DONE,
    ERROR: exports.ERROR,
    SELECT_MEMBERSHIP: exports.SELECT_MEMBERSHIP,
    SIGN_UP: exports.SIGN_UP,
};
