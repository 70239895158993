"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
exports.default = (0, client_1.gql) `
  mutation ssoLookup($input: SSOLookupInput!) {
    strategy: ssoLookup(input: $input) @rest(type: "SSOLookup", path: "/lookup/sso_only", method: "POST") {
      ssoOnly
    }
  }
`;
