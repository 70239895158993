import { init as filestackInit } from 'filestack-js';


let instance;

/**
 * Initializes the FileStack client.
 * @param {String} apiKey
 * @see https://www.filestack.com/docs/uploads/pickers/web/#opening-file-picker
 */
export function init(apiKey) {
  if (!instance) {
    instance = filestackInit(apiKey);
  }
}


/**
 * Returns the FileStack client instance.
 * @return {Object}
 * @throws {Error} throws an error if the client has not been initialized.
 */
export function getClientInstance() {
  if (!instance) {
    throw new Error('File client has not been initialized');
  }

  return instance;
}
