import { css } from "aphrodite/no-important";
import React from "react";

import useReleaseFeature from "hooks/useReleaseFeature";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const ConnectionsBlankSlateBanner = (props) => {
  const noCCRequired = useReleaseFeature("no-cc-required-for-trial");
  const planDisclaimer = "Machine Data Connections requires a paid subscription.";

  props = {
    ...props,
    actionButtonText: noCCRequired ? "Upgrade Plan" : "Try Connections for Free",
    imagePath: "images/pay_gates/banners/connections.svg",
    title: "Connections",
    ...(noCCRequired && { secondaryText: planDisclaimer }),
  };

  return (
    <PayGateCommonBlankSlateBanner {...props} size="lg">
      <p className={css(styles.secondaryText)}>
        With Connections you can link your accounts with other data providers to Bushel Farm, automatically and manually
        synchronize data, and get the full power of Bushel Farm with less data entry.
      </p>
    </PayGateCommonBlankSlateBanner>
  );
};

export default ConnectionsBlankSlateBanner;
