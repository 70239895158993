import useDocumentTitle from "app/hooks/useDocumentTitle";
import React from "react";

import { useSSOContext } from "context/SSOContext";
import BaseSSOPageContainer from "modules/sso/components/BaseSSOPageContainer";
import ConfirmFarmNameForm from "modules/sso/forms/ConfirmFarmNameForm";

const SSOConfirmFarmName = ({ onComplete }) => {
  const { selectedMembership } = useSSOContext();
  useDocumentTitle("Confirm Farm Name");

  return (
    <BaseSSOPageContainer title={`Welcome ${selectedMembership.user?.name}`}>
      <ConfirmFarmNameForm onComplete={onComplete} />
    </BaseSSOPageContainer>
  );
};

export default SSOConfirmFarmName;
