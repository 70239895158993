import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useRootClose } from 'react-overlays';

import useVisibility from '../../hooks/useVisibility';


const useDatePickerOverlay = () => {
  const containerRef = useRef();
  const visibilityControl = useVisibility();
  const overlayRef = useRef();

  useRootClose(containerRef, (event) => {
    const isWithinOverlay = overlayRef.current.contains(event.target);
    if (!isWithinOverlay) {
      visibilityControl.hide();
    }
  }, { disabled: !visibilityControl.isVisible });


  return {
    containerRef,
    hideCalendar: visibilityControl.hide,
    isCalendarShown: visibilityControl.isVisible,
    overlayRef,
    popoverId: useMemo(() => _.uniqueId('flui-DatePicker'), []),
    showCalendar: visibilityControl.show
  };
};


export default useDatePickerOverlay;
