import _ from "lodash";
import { CARGILL_ELEVATE, SUMMARY } from "marketing/constants";
import ContractDetails from "marketing/routes/ContractDetails";
import MarketedCropDetail from "marketing/routes/MarketedCropDetail";
import MarketingCropDetailGuard from "marketing/routes/MarketingCropDetailGuard";
import { ADD_CONTRACT, EDIT_CONTRACT } from "marketing/utils/contractEnums";
import React from "react";
import { redirect } from "react-router-dom";

import MarketingDashboard from "modules/marketing/routes/MarketingDashboard";

export default [
  {
    path: "/marketing",
    element: <MarketingDashboard view={SUMMARY} />,
  },

  {
    path: "/marketing/advisor",
    element: <MarketingDashboard view={CARGILL_ELEVATE} />,
  },

  {
    path: "/marketing/cargillelevate",
    loader: () => redirect("/marketing/advisor"),
  },

  {
    element: <MarketingCropDetailGuard />,
    children: [
      {
        path: "/marketing/:marketedCropId",
        element: <MarketedCropDetail view={MarketedCropDetail.DASHBOARD} />,
      },

      {
        path: "/marketing/:marketedCropId/contracts",
        element: <MarketedCropDetail view={MarketedCropDetail.CONTRACTS} />,
      },

      {
        loader: ({ params }) => {
          const isValidContractType = ["cash", "futures", "options"].includes(_.toLower(params.contractType));
          return isValidContractType ? null : redirect(`/marketing/${params.marketedCropId}`);
        },

        children: [
          {
            path: "/marketing/:marketedCropId/contracts/:contractType/new",
            element: <ContractDetails action={ADD_CONTRACT} />,
          },

          {
            loader: ({ params: { contractType, marketedCropId } }) => {
              const path =
                contractType === "cash" ? `/marketing/${marketedCropId}` : `/marketing/${marketedCropId}/contracts`;
              return redirect(path);
            },

            path: "/marketing/:marketedCropId/contracts/:contractType",
            element: <div>View it</div>,
          },

          {
            path: "/marketing/:marketedCropId/contracts/:contractType/:contractId/edit",
            element: <ContractDetails action={EDIT_CONTRACT} />,
          },
        ],
      },
    ],
  },
];
