/* eslint-disable react/display-name */
import { css, StyleSheet } from "aphrodite/no-important";
import { useViewportSpy } from "beautiful-react-hooks";
import _, { remove, map, isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Bar, BarChart, ResponsiveContainer, XAxis } from "recharts";
import styled from "styled-components";

import useHistoricalRainfall from "collection/graphql/weather/hooks/useHistoricalRainfall";
import useRecentRainfall from "collection/graphql/weather/hooks/useRecentRainfall";
import { styles as commonStyles } from "modules/fields/common";
import FieldIndexContext from "modules/fields/components/context/FieldIndexContext";

import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography, Mixins } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  container: {
    position: "relative",
    height: Mixins.toRem(52),
    width: "100%",
  },
  background: {
    position: "absolute",
    zIndex: 1,
    background: `repeating-linear-gradient(-45deg, ${GreyColors.smoke98}, ${GreyColors.smoke98} 1px, ${UIColors.white} 1px, ${UIColors.white} 8px)`,
    border: Borders.regular,
    borderRadius: BorderRadius.small,
    height: Mixins.toRem(40),
    width: "100%",
  },
  inner_container: {
    position: "absolute",
    zIndex: 10,
    height: Mixins.toRem(60),
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    ":hover": {
      textDecoration: "none",
    },
  },
  graph_container: {
    width: "80%",
    height: "100%",
  },
  badgeContainer: {
    width: "20%",
  },
  emptyState: {
    margin: `${Spacing.regular} ${Spacing.small}`,
  },
  fallbackText: {
    alignItems: "center",
    display: "flex",
    height: Mixins.toRem(40),
    margin: `0 ${Spacing.small}`,
    fontWeight: Typography.weights.regular,
  },

  badge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: UIColors.secondary,
    borderRadius: `0 ${BorderRadius.small} ${BorderRadius.small} 0`,
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    height: Mixins.toRem(40),
    paddingLeft: Spacing.regular,
    paddingRight: Spacing.regular,
  },
  badgeIsRecent: {
    fontWeight: Typography.weights.medium,
    background: UIColors.blue,
    color: UIColors.white,
  },
});

const EmptyState = () => <h4 className={css(styles.fallbackText)}>No rainfall data is available</h4>;
const LoadingState = () => (
  <h4 className={css(styles.fallbackText, styles.emptyState)}>{"Loading rainfall data\u2026"}</h4>
);

const withLazyRender = (Component) => (props) => {
  const { loading: recentRainfallLoading, recentRainfallMap } = useRecentRainfall();
  const { historicalRainfallMap, loading: historicalRainfallLoading } = useHistoricalRainfall();
  const isLoading = recentRainfallLoading || historicalRainfallLoading;

  return isLoading ? (
    <LoadingState />
  ) : (
    <Component {...props} last24={recentRainfallMap} recent={historicalRainfallMap} />
  );
};

const RainChart = (props) => {
  const { chartData, isRecent } = props;
  const strategy = useContext(FieldIndexContext).renderStrategy.rainfall;
  const [shouldRender, setShouldRender] = useState(strategy === "standard");
  const ref = useRef();

  let rootMargin = "0px";
  if (strategy === "delayed") {
    rootMargin = "50px";
  } else if (strategy === "throttled") {
    rootMargin = "5px";
  }
  const visible = useViewportSpy(ref, { rootMargin });

  useEffect(() => {
    if (visible && !shouldRender) {
      setShouldRender(true);
    }
  }, [visible, strategy]);

  const commonXAxis = {
    axisLine: false,
    dataKey: "endTime",
    height: 18,
    padding: { left: 4, right: 4 },
    stroke: GreyColors.smoke91,
    tick: { fill: GreyColors.smoke50, fontSize: Typography.sizes.xs, lineHeight: Typography.sizes.xs },
  };

  const xAxisDays = useMemo(
    () => ({
      ...commonXAxis,
      orientation: "top",
      tickFormatter: (d) => moment(d).format("ddd").toUpperCase(),
      tickMargin: 0,
      ticks:
        isRecent &&
        map(chartData, (d) => {
          const hour = moment(d.endTime).format("h a");
          if (hour === "12 am") {
            return d.endTime;
          }
        }),
    }),
    [chartData]
  );

  const xAxisHours = useMemo(
    () => ({
      ...commonXAxis,
      minTickGap: 2,
      orientation: "bottom",
      tickFormatter: (d) => moment(d).format("h a").toUpperCase(),
      tickMargin: 4,
      ticks:
        isRecent &&
        remove(
          map(chartData, (d) => {
            const hour = moment(d.endTime).format("h");
            if (hour % 4 === 0) {
              return d.endTime;
            }
          }),
          (d) => !isEmpty(d)
        ),
    }),
    [chartData]
  );

  return (
    <div ref={ref}>
      {shouldRender && (
        <ResponsiveContainer height={56} width="100%">
          <BarChart data={chartData} margin={{ top: 0, bottom: 0 }}>
            <XAxis {...xAxisDays} xAxisId={0} />
            <Bar dataKey="amount" fill="#7bb2ea" xAxisId={0} barGap={4} />
            <XAxis {...xAxisHours} xAxisId={1} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

const RainFallErrorView = styled.div`
  font-size: ${Typography.sizes.rg};
  margin: ${Spacing.small};
`;

const RainfallHistogram = withLazyRender((props) => {
  const { fieldId, last24, recent } = props;
  const hourlyRainfall = last24.get(fieldId);
  const recentRainfall = recent.get(fieldId);
  if (!recent.has(fieldId) || !recentRainfall.amount) {
    return <EmptyState />;
  }

  const isRecent = recentRainfall.state === "recent";
  const isSignificant = isRecent && +recentRainfall.amount.toFixed(2) >= 0.1;
  const chartData = isRecent && hourlyRainfall ? hourlyRainfall.hourly : [];

  return (
    <>
      <h4 className={css(commonStyles.fieldCard_subHeader)}>{`Rainfall${isRecent ? " in the last 24 hours" : ""}`}</h4>

      <div className={css(styles.container)}>
        <div className={css(styles.background)} />
        <Link className={css(styles.inner_container)} to={`/fields/${fieldId}/rainfall`}>
          <ErrorBoundary fallback={() => <RainFallErrorView>Error processing rainfall data</RainFallErrorView>}>
            <div className={css(styles.graph_container)}>
              {isRecent ? (
                <RainChart chartData={chartData} isRecent={isRecent} />
              ) : (
                <h4 className={css(styles.fallbackText)}>
                  {`Last rainfall ${moment(recentRainfall.endTime).fromNow()}`}
                </h4>
              )}
            </div>
            <div className={css(styles.badgeContainer)}>
              <div className={css(styles.badge, isSignificant && styles.badgeIsRecent)}>
                {`${recentRainfall.amount.toFixed(2) ?? 0}"`}
              </div>
            </div>
          </ErrorBoundary>
        </Link>
      </div>
    </>
  );
});

RainfallHistogram.propTypes = {
  fieldId: PropTypes.number.isRequired,
};

export default RainfallHistogram;
