import { bool, number, object, string } from "yup";

export const LegacyPersonSchema = object({
  created: string(),
  email: string().label("Email").trim().email().required(),
  enterpriseId: number(),
  firstName: string().label("First Name").trim().required().min(1),
  icon: string().label("Icon").nullable(),
  id: number(),
  isArchived: bool(),
  isTransactionEmailEnabled: bool(),
  landingPage: string().default(null).nullable(),
  lastName: string().label("Last Name").trim().required().min(1),
  name: string(),
  notes: string().label("Notes").default(null).nullable(),
  phone: string().label("Phone").nullable().trim(),
  role: string().label("Role").oneOf(["admin", "contributor", "manager", "no_access"]),
  updated: string(),
});
