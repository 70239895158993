import { fileClient } from "components/fl-ui/common";
import styles from "activity/common/styles";
import Recording from "activity/components/recording_playback";
import { css } from "aphrodite";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import React, { useState } from "react";

import ImageCarousel from "components/fl-ui/Attachments/ImageCarousel";

const { getPreviewableFiles, getUrl } = fileClient;

const handleCarouselDownload = async (file) => {
  try {
    const [data] = await getPreviewableFiles([file.id]);
    saveAs(getUrl(file.id), data.fileName);
  } catch (err) {
    saveAs(file.previewUrl.split("/convert")[0]);
  }
};

const PreviewImage = ({ activity, image, previewSize }) => {
  const [showCarousel, setShowCarousel] = useState(false);

  /* Empty state */
  if (!image) {
    const attrs = {
      className: css(styles.verticallyCentered, styles.attachmentBlankslate),
      style: {
        minHeight: previewSize,
        minWidth: previewSize,
      },
    };

    return <div {...attrs}>No attachments</div>;
  }

  /* AAR */
  if (image.isRecording) {
    return (
      <Recording
        cropId={activity.crop.id}
        field={activity.field}
        recording={image.recording}
        recordingId={image.recording.id}
        previewUrl={image.previewUrl}
        size={previewSize}
      />
    );
  }

  /* Default attachment image */
  return (
    <>
      {showCarousel && (
        <ImageCarousel
          idx={0}
          images={[image]}
          onDownload={handleCarouselDownload}
          onHide={() => setShowCarousel(false)}
        />
      )}

      <img
        className={css(styles.roundedImage, styles.clickable)}
        height={previewSize}
        onClick={() => setShowCarousel(true)}
        src={image.previewUrl}
        width={previewSize}
      />
    </>
  );
};

PreviewImage.propTypes = {
  activity: PropTypes.object.isRequired,
  image: PropTypes.object,
  isRecording: PropTypes.bool,
  previewSize: PropTypes.number.isRequired,
};

export default PreviewImage;
