import _ from 'lodash';

import getMetadata from './getMetadata';
import isFilePreviewable from './isFilePreviewable';


/**
 * Given an array of file ids, fetch full file data and filter out non-previewable files.
 * @param {String[]} fileIds
 * @return {Promise<Object[]>}
 */
export default async function getPreviewableFiles(fileIds) {
  const results = await Promise.allSettled(fileIds.map(fileId => getMetadata(fileId)));
  return _.map(results, 'value').filter((file) => isFilePreviewable(file));
}
