import React from 'react';
import IconContainer from '../IconContainer';


const PeopleIcon = (props) => (
  <IconContainer {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z"/>
      <path
        d="M5.31 9.701c-.21-.464-.324-1.033-.324-1.678C4.986 6.204 5.881 5 7.322 5c1.44 0 2.334 1.204 2.334 3.023 0 .65-.114 1.222-.327 1.688.915.256 1.543.72 1.905 1.385a.455.455 0 0 1-.191.62.47.47 0 0 1-.632-.188c-.27-.498-.789-.838-1.659-1.011-.377.34-.86.528-1.43.528-.573 0-1.06-.19-1.439-.535-1.435.272-1.95.974-1.95 2.247 0 1.058.26 1.15 3.367 1.15.545 0 .885-.002 1.194-.008a.463.463 0 0 1 .477.449.462.462 0 0 1-.457.468c-.318.006-.662.009-1.214.009-3.6 0-4.3-.25-4.3-2.068 0-1.574.698-2.622 2.31-3.056zm12.38 2.157c2.324.535 3.31 1.939 3.31 4.116 0 2.366-.883 2.681-5.764 2.681-4.88 0-5.763-.315-5.763-2.68 0-2.179.987-3.583 3.31-4.117-.266-.581-.41-1.298-.41-2.117 0-2.268 1.1-3.748 2.863-3.748 1.764 0 2.864 1.48 2.864 3.748 0 .82-.144 1.536-.41 2.117zm-4.363.825c-2.132.38-2.92 1.429-2.92 3.291 0 1.607.441 1.764 4.83 1.764 4.388 0 4.83-.157 4.83-1.764 0-1.863-.789-2.91-2.921-3.291-.482.517-1.131.805-1.91.805-.778 0-1.427-.288-1.909-.805zm3.84-2.942c0-1.813-.757-2.83-1.93-2.83-1.174 0-1.93 1.017-1.93 2.83 0 1.812.756 2.83 1.93 2.83 1.173 0 1.93-1.018 1.93-2.83zM8.723 8.023c0-1.364-.551-2.106-1.401-2.106s-1.403.742-1.403 2.106c0 1.363.552 2.105 1.403 2.105.85 0 1.401-.742 1.401-2.105z"
        fill="currentColor" fillRule="nonzero"/>
    </g>
  </IconContainer>
);

PeopleIcon.iconId = 'people';


export default PeopleIcon;
