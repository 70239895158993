import { StyleSheet, css } from "aphrodite/no-important";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import App from "layout/app";
import withNotificationSettings from "modules/notifications/hoc/withNotificationSettings";

import { Button } from "components/fl-ui";
import { Form, Checkbox, Radio } from "components/fl-ui/Form";
import { Typography, Spacing, Borders } from "components/fl-ui/constants";

const AlertData = {
  rainfall: {
    category: "Rainfall",
    description: "Receive alerts when rainfall is detected on your fields.",
    emailAvailable: true,
    mobileAvailable: true,
  },
  "market-prices": {
    category: "Marketing",
    description: "Receive alerts about market price movements.",
    emailAvailable: false,
    mobileAvailable: true,
  },
  "work-orders": {
    category: "Activity Assignments",
    description: "Receive notifications when activities are assigned to you.",
    emailAvailable: true,
    mobileAvailable: false,
  },

  "elevate-plus-content-daily-flash": {
    category: "Cargill Elevate+",
    description: "Receive notifications when new **Daily Flash** commentary is posted.",
    emailAvailable: false,
    mobileAvailable: true,
  },
  "elevate-plus-content-market-update": {
    category: "Cargill Elevate+",
    description: "Receive notifications when **Market Update** podcasts are released.",
    emailAvailable: false,
    mobileAvailable: true,
  },
  "elevate-plus-content-weekly-reports": {
    category: "Cargill Elevate+",
    description: "Receive notifications when new **Weekly Reports** are posted.",
    emailAvailable: false,
    mobileAvailable: true,
  },
  "elevate-plus-content-market-reports": {
    category: "Cargill Elevate+",
    description: "Receive notifications when **USDA Market Updates** are posted.",
    emailAvailable: false,
    mobileAvailable: true,
  },
  "elevate-plus-content-other-commentary": {
    category: "Cargill Elevate+",
    description: "Receive notifications when all **Other** commentary is posted.",
    emailAvailable: false,
    mobileAvailable: true,
  },
  "elevate-plus-content-cash-recommendations": {
    category: "Cargill Elevate+",
    description: "Receive notifications when **Active Cash Recommendations** are updated.",
    emailAvailable: false,
    mobileAvailable: true,
  },
  "elevate-plus-crop-recommendations": {
    category: "Cargill Elevate+",
    description: "Receive notifications when **Crop Recommendations** are updated.",
    emailAvailable: false,
    mobileAvailable: true,
  },
};

const styles = StyleSheet.create({
  header: {
    fontSize: Typography.sizes.xl,
    lineHeight: 1.4,
    marginBottom: Spacing.regular,
  },
  alertName: {
    fontSize: Typography.sizes.lg,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.75,
    borderTop: Borders.light,
    paddingTop: Spacing.small,
  },

  alertDesc: {
    fontSize: Typography.sizes.rg,
    lineHeight: 2,
  },
  alertSection: {
    borderBottom: Borders.light,
    marginBottom: Spacing.regular,
    paddingBottom: Spacing.regular,
  },
  space: {
    display: "inline-block",
    width: Spacing.large,
  },
  marginTop_16: {
    marginTop: Spacing.regular,
  },
  marginBottom_16: {
    marginBottom: Spacing.large,
  },

  formActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const RainfallOptions = ({ options }) => {
  const yearRound = options.winter == "true" || options.winter === true;
  return (
    <>
      <p className={css(styles.alertDesc, styles.marginTop_16)}>Send rainfall alerts:</p>
      <Radio defaultChecked={yearRound} inline label="Year-round" name="winter" value="true" />
      <span className={css(styles.space)} />
      <Radio defaultChecked={!yearRound} inline label="In-season only" name="winter" value="false" />
    </>
  );
};

const Notifications = (props) => {
  const hasElevatePlus = useEnterpriseFeature("elevate_plus");
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);

  const handleChange = (type, value) => {
    const changedSubs = filteredSubscriptions.map((subscription) =>
      type === subscription.notificationType ? _.extend({}, subscription, value) : subscription
    );
    setFilteredSubscriptions(changedSubs);
  };

  const handleFormChange = ({ target: { name, value, checked } }) => {
    if (name === "winter") {
      handleChange("rainfall", {
        options: { winter: value == "true" },
      });
    } else {
      handleChange(name, {
        [value]: checked,
      });
    }
  };

  const handleSave = async () => {
    const promises = filteredSubscriptions.map((subscription) => props.setUserSubscription(subscription));
    await Promise.all(promises);
    App.notify("Changes saved.");
  };

  useEffect(() => {
    if (props.data.subscriptions) {
      setFilteredSubscriptions(
        props.data.subscriptions
          .filter(({ notificationType }) => {
            return _.keys(AlertData).includes(notificationType);
          })
          .sort((a, b) => _.keys(AlertData).indexOf(a.notificationType) - _.keys(AlertData).indexOf(b.notificationType))
      );
    }
  }, [props.data.subscriptions]);

  return (
    <Form onChange={handleFormChange.bind(this)} onSubmit={handleSave.bind(this)}>
      <h2 className={css(styles.header)}>Alerts</h2>
      <div className={css(styles.alertSection)}>
        {filteredSubscriptions.map((x, index) => {
          const prevCategory = index > 0 ? AlertData[filteredSubscriptions[index - 1].notificationType].category : "";
          if (!hasElevatePlus && AlertData[x.notificationType].category === "Cargill Elevate+") {
            return;
          }
          return (
            <div key={x.notificationType}>
              {AlertData[x.notificationType].category != prevCategory ? (
                <h3 className={css(styles.alertName)}>{AlertData[x.notificationType].category}</h3>
              ) : (
                <div className={css(styles.marginBottom_16)} />
              )}

              <ReactMarkdown className={css(styles.alertDesc)}>
                {AlertData[x.notificationType].description}
              </ReactMarkdown>

              {AlertData[x.notificationType].emailAvailable && (
                <>
                  <Checkbox defaultChecked={x.email} label="Email" name={x.notificationType} value="email" />
                  <span className={css(styles.space)} />
                </>
              )}

              {AlertData[x.notificationType].mobileAvailable && (
                <Checkbox defaultChecked={x.push} label="Mobile" name={x.notificationType} value="push" />
              )}
              {x.notificationType === "rainfall" && <RainfallOptions {...x} />}
            </div>
          );
        })}
      </div>

      <Button color="primary" type="submit">
        Save Changes
      </Button>
    </Form>
  );
};

const WrappedNotifications = withNotificationSettings(Notifications);
WrappedNotifications.tabId = "alerts";
WrappedNotifications.title = "Alert Settings";

Notifications.propTypes = {
  data: PropTypes.shape({
    subscriptions: PropTypes.arrayOf(PropTypes.object),
  }),
  setUserSubscription: PropTypes.func,
};

RainfallOptions.propTypes = {
  options: PropTypes.object,
};

export default WrappedNotifications;
