"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDownloadLink = createDownloadLink;
function createDownloadLink(blob, filename) {
    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(blob));
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    return link;
}
