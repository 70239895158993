import React from 'react';
import IconContainer from '../IconContainer';


const SettingsIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M18.57 9.059zm.176.15h2.171a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-.75.75h-2.172c-.066 0-.135.05-.167.126-.06.15-.047.234-.002.28l1.537 1.535a.75.75 0 0 1 0 1.061l-2.652 2.651a.75.75 0 0 1-1.06 0l-1.534-1.534a.19.19 0 0 0-.185-.04l-.054.025c-.12.05-.169.117-.169.183v2.171a.75.75 0 0 1-.75.75h-3.75a.75.75 0 0 1-.75-.75v-2.173c0-.064-.05-.134-.127-.166-.149-.06-.233-.047-.279-.002l-1.536 1.536a.75.75 0 0 1-1.06 0l-2.652-2.65a.75.75 0 0 1 0-1.061l1.534-1.534c.047-.048.06-.132.009-.257-.042-.102-.11-.151-.177-.151H2.75a.75.75 0 0 1-.75-.75v-3.75a.75.75 0 0 1 .75-.75h2.173c.065 0 .134-.05.166-.127.061-.15.048-.233.002-.28L3.555 7.266a.75.75 0 0 1 0-1.06l2.652-2.651a.75.75 0 0 1 1.06 0L8.803 5.09c.041.04.113.055.239.013.117-.049.167-.117.167-.183V2.75a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 .75.75v2.173c0 .065.05.134.168.183.113.043.193.03.238-.015L16.4 3.555a.75.75 0 0 1 1.06 0l2.652 2.65a.75.75 0 0 1 0 1.061l-1.536 1.536a.189.189 0 0 0-.04.183c.025.05.025.05.036.08.043.097.11.144.173.144zm1.421 1.5h-1.421c-.694 0-1.294-.433-1.57-1.096-.269-.646-.151-1.38.34-1.871l1.006-1.006-1.59-1.59-1.007 1.005c-.49.492-1.223.61-1.913.322a1.682 1.682 0 0 1-1.053-1.55V3.5h-2.25v1.421c0 .696-.435 1.297-1.1 1.571-.643.268-1.377.15-1.867-.342L6.737 5.146l-1.591 1.59 1.006 1.007a1.67 1.67 0 0 1 .36 1.814c-.017.048-.017.048-.026.069-.265.647-.867 1.083-1.563 1.083H3.5v2.25h1.421c.696 0 1.297.434 1.571 1.1.269.64.15 1.373-.342 1.867l-1.004 1.005 1.59 1.59 1.008-1.006a1.673 1.673 0 0 1 1.813-.36c.048.017.048.017.069.026.647.265 1.083.868 1.083 1.563v1.423h2.25v-1.421c0-.696.435-1.298 1.058-1.553.684-.285 1.416-.168 1.91.324l1.004 1.004 1.591-1.59-1.008-1.008a1.688 1.688 0 0 1-.331-1.887c.265-.643.866-1.077 1.562-1.077h1.422v-2.25zm-4.763 1.125a3.57 3.57 0 1 1-7.14 0 3.57 3.57 0 0 1 7.14 0zm-1.5 0a2.07 2.07 0 1 0-4.14 0 2.07 2.07 0 0 0 4.14 0z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

SettingsIcon.iconId = 'settings';


export default SettingsIcon;
