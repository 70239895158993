import _ from "lodash";
import React, { useEffect, useState } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

import useFieldGroups from "hooks/useFieldGroups";

import { Button } from "components/fl-ui";
import { chooserStyles } from "components/fl-ui/Chooser";
import { Cluster } from "components/fl-ui/Layout";
import wrapOccurrences from "components/fl-ui/util/wrapOccurrences";
import { MAX_FIELD_GROUP_NAME_LENGTH } from "fields/common/constants";

const FieldGroupChooser = ({ onChange, value: overrideValue }) => {
  const { fieldGroups } = useFieldGroups();
  const options = fieldGroups.map((group) => ({
    ...group,
    key: group.id,
    label: group.name,
    value: group.id,
  }));
  const [searchTerm, setSearchTerm] = useState("");
  const overrideValueOption = _.find(options, { id: overrideValue }) || null;
  const [value, setValue] = useState(overrideValueOption);

  useEffect(() => {
    if (overrideValue && overrideValue !== value?.id) {
      setValue(overrideValueOption);
    }
  }, [overrideValue]);

  const Option = (props) => {
    const { label, __isNew__ } = props.data;
    return (
      <components.Option {...props}>
        <Cluster between>
          <div>{wrapOccurrences(label, searchTerm)}</div>
          <Button>{__isNew__ ? "Create" : "Select"}</Button>
        </Cluster>
      </components.Option>
    );
  };

  const handleChange = (value) => {
    value = _.last(value) || null;
    if (value) {
      value.name = value.label;
    }
    onChange(value);
    setValue(value);
  };

  const handleInputChange = (inputValue) => {
    if (inputValue.trim().length === 0) {
      return;
    }

    const truncatedInput = inputValue.slice(0, MAX_FIELD_GROUP_NAME_LENGTH);
    setSearchTerm(truncatedInput);
    return truncatedInput;
  };

  return (
    <CreatableSelect
      components={{ Option }}
      isClearable
      isMulti
      onChange={handleChange}
      onInputChange={handleInputChange}
      onMenuClose={() => setSearchTerm("")}
      options={options}
      placeholder="Select a field group..."
      styles={chooserStyles}
      value={value}
    />
  );
};

export default FieldGroupChooser;
