import { gql } from "@apollo/client";

export default gql`
  fragment BasicField on Field {
    acreage
    centroid
    eligibleCarbonClients
    county {
      name
    }
    geometry
    geometricCircumference
    group {
      id
      name
      __typename
    }
    id
    isIrrigated
    name
    provider
    section {
      name
    }
    source
    township {
      name
      range
    }
    __typename
  }
`;
