import { useSuspenseQuery } from "@apollo/client";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import NoteDetails from "notes/components/NoteDetails";
import NoteMap from "notes/components/NoteMap";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAuth } from "collection/graphql/auth";
import { getNoteById, useNoteMutations } from "collection/graphql/notes";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import App from "layout/app";

const NoteContainer = () => {
  const [searchParams] = useSearchParams();
  const fieldId = +searchParams.get("field_id");
  const { actionOrId } = useParams();
  const noteId = actionOrId === "add" ? undefined : actionOrId;
  const isNew = !noteId;
  const { currentUser } = useAuth();
  const hasScoutingFeature = useEnterpriseFeature("scouting");
  const { currentEnterprise } = useCurrentEnterprise();
  useDocumentTitle("Scouting list");
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [locationMode, setLocationMode] = useState(false);

  const note = useSuspenseQuery(getNoteById, {
    skip: isNew,
    variables: { id: noteId },
  }).data?.note;

  const [saveNote, destroy] = useNoteMutations();

  const [data, setData] = useState(() => {
    return !isNew
      ? note
      : {
          enterpriseId: currentEnterprise.id,
          fieldId: fieldId ?? undefined,
          geometry: null,
          images: [],
          people: [currentUser.id],
        };
  });

  useEffect(() => {
    if (!hasScoutingFeature || (!isNew && !note)) {
      navigate("/scouting", { replace: true });
    }
  }, [hasScoutingFeature, isNew, note, navigate]);

  const handleChange = (newData) => setData({ ...data, ...newData });

  const handleDelete = () => {
    App.confirm({
      confirm: "DELETE",
      message: "This will delete this scouting note and all of its associated data.",
    }).then(async () => {
      await destroy({
        variables: { id: note.id },
      });
      navigate("/scouting");
    });
  };

  const handleSave = async () => {
    try {
      setErrors({});
      await saveNote(data);
      navigate("/scouting");
    } catch (error) {
      if (error.inner) {
        const errorMap = error.inner.reduce((output, { message, path }) => {
          return Object.assign(output, { [path]: message });
        }, {});
        setErrors(errorMap);
      } else {
        throw error;
      }
    }
  };

  return (
    <div>
      {locationMode ? (
        <NoteMap
          data={data}
          onChange={(data) => {
            handleChange(data);
            setLocationMode(false);
          }}
          onCancel={() => setLocationMode(false)}
        />
      ) : (
        <NoteDetails
          data={data}
          errors={errors}
          isNew={isNew}
          onCancel={() => navigate("/scouting")}
          onChange={handleChange}
          onDelete={handleDelete}
          onEditLocation={() => setLocationMode(true)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default NoteContainer;
