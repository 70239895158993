import React from 'react';
import IconContainer from '../IconContainer';


const DollarIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M13.568 7.28v3.873c3.822.854 5.509 2.122 5.509 4.568 0 2.31-2.203 3.56-5.51 3.844v1.263c0 .647-.543 1.172-1.213 1.172s-1.214-.525-1.214-1.172v-1.23c-2.33-.152-4.29-.906-5.706-2.052a1.145 1.145 0 0 1-.15-1.652 1.244 1.244 0 0 1 1.71-.144c.989.8 2.403 1.357 4.146 1.498v-4.186c-3.49-.73-5.58-1.876-5.58-4 0-2.48 2.494-3.94 5.58-4.182V3.172c0-.647.544-1.172 1.214-1.172.67 0 1.214.525 1.214 1.172v1.75c1.64.166 3.258.597 4.588 1.238.6.289.843.993.544 1.572-.3.58-1.028.814-1.628.525-.997-.48-2.235-.822-3.504-.976zm-2.428-.044c-1.853.194-3.152.959-3.152 1.826 0 .476 1.147 1.103 3.152 1.59V7.235zm2.428 9.974c1.987-.204 3.081-.806 3.081-1.49 0-.962-.75-1.592-3.081-2.16v3.65z"
      fill="currentColor"
      fillRule="nonzero"/>

  </IconContainer>
);

DollarIcon.iconId = 'dollar';


export default DollarIcon;
