import { FileAttachments, fileClient } from "components/fl-ui/common";
import { saveAs } from "file-saver";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import ImageCarousel from "components/fl-ui/Attachments/ImageCarousel";

const { getUrl } = fileClient;

const FileAttachmentsAdapter = (props) => {
  const { carousel, onCarouselDelete, ...rest } = props;
  rest.files = _.map(rest.files, (file) => ({
    filepickerId: file.filepicker_id || file.filepickerId,
    name: file.name,
    s3Id: file.s3_id || file.s3Id,
    url: file.url,
  }));

  const [carouselConfig, setCarouselConfig] = useState(null);
  if (carousel) {
    rest.onCarouselInit = setCarouselConfig;
  }

  const handleCarouselDownload = (file) => {
    saveAs(getUrl(file.filepickerId), file.fileName);
  };

  return (
    <div>
      <FileAttachments apiKey={process.env.FILEPICKER_IO_API_KEY} {...rest} />
      {carouselConfig && (
        <ImageCarousel
          idx={carouselConfig.index}
          images={carouselConfig.files}
          onDownload={handleCarouselDownload}
          onHide={() => setCarouselConfig(null)}
        />
      )}
    </div>
  );
};

const propTypes = _.omit(FileAttachments.propTypes, ["apiKey", "onCarouselInit"]);
FileAttachmentsAdapter.propTypes = {
  ...propTypes,
  carousel: PropTypes.bool,
};

const defaultProps = _.omit(FileAttachments.defaultProps, ["onCarouselInit"]);
FileAttachmentsAdapter.defaultProps = {
  ...defaultProps,
  carousel: false,
  maxFiles: 10,
};

export default FileAttachmentsAdapter;
