import React from 'react';
import IconContainer from '../IconContainer';


const NewWindowIcon = (props) => (
  <IconContainer {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <polygon points="0 0 24 0 24 24 0 24"/>
        <path
          d="M16.6666667,16.6666667 L7.33333333,16.6666667 L7.33333333,7.33333333 L12,7.33333333 L12,6 L7.33333333,6 C6.59333333,6 6,6.6 6,7.33333333 L6,16.6666667 C6,17.4 6.59333333,18 7.33333333,18 L16.6666667,18 C17.4,18 18,17.4 18,16.6666667 L18,12 L16.6666667,12 L16.6666667,16.6666667 Z M13.3333333,6 L13.3333333,7.33333333 L15.7266667,7.33333333 L9.17333333,13.8866667 L10.1133333,14.8266667 L16.6666667,8.27333333 L16.6666667,10.6666667 L18,10.6666667 L18,6 L13.3333333,6 Z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </g>

  </IconContainer>
);

NewWindowIcon.iconId = 'newWindow';


export default NewWindowIcon;
