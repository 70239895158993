import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import App from "layout/app";

import { Button } from "components/fl-ui";
import { ControlledFormGroup, Form, Input } from "components/fl-ui/Form";
import Toast from "components/fl-ui/Toast";

const email = yup.string().email().label("Email").required();

const EmailCheckFormSchema = yup.object().shape({
  email,
});

const EmailCheckForm = styled(({ className, setSSOUserEmail }) => {
  const methods = useForm({
    defaultValues: EmailCheckFormSchema.cast(
      {
        email: "",
      },
      { assert: false, stripUnknown: true }
    ),
    mode: "onTouched",
    resolver: yupResolver(EmailCheckFormSchema),
  });

  const onSubmit = async ({ email }) => {
    await setSSOUserEmail(email).catch((err) => {
      const statusCode = parseInt(err.message.split("status code")[1]);
      if (statusCode == 400) {
        App.notify("Unauthorized access");
      }
    });
  };

  return (
    <div className={className}>
      <FormProvider {...methods} schema={EmailCheckFormSchema}>
        <br />
        <p className="top-text">Please provide your email to login:</p>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledFormGroup
            name="email"
            render={({ field }) => <Input {...field} autoComplete="email" placeholder="your@email.com" type="email" />}
          />
          <br />
          <Button className="save-button" color="primary" type="submit">
            Next
          </Button>
        </Form>
        <Toast />
      </FormProvider>
    </div>
  );
})`
  .save-button {
    width: 100%;
  }

  .top-text {
    text-align: left;
  }
`;

export default EmailCheckForm;
