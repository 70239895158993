import { createGlobalStyle } from 'styled-components';
import theme from './index';


const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Rubik', 'Helvetica Neue', 'Helvetica', sans-serif;
  }
  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }
  body {
    color: ${theme.colors.black};
    font-size: 1.4rem;
    line-height: 1.5;
  }

  a,
  a:visited {
    color: ${theme.colors.blue};
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 1rem;
    padding: 1rem 0;
    font-weight: 500;
  }
`;


export default GlobalStyle;
