import React from 'react';
import IconContainer from '../IconContainer';


const AarIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M7.08156771,12.8478055 L11.0144898,13.1322775 C11.4200504,13.1616121 11.731232,13.4964286 11.722751,13.8943326 L11.6025572,19.533451 L16.9184323,11.1521945 L12.9855102,10.8677225 C12.5799496,10.8383879 12.268768,10.5035714 12.277249,10.1056674 L12.3974428,4.46654901 L7.08156771,12.8478055 Z M10.1783595,14.5728452 L5.70937885,14.2495995 C5.136341,14.2081511 4.81292094,13.5861965 5.11595675,13.1084161 L12.570517,1.35520885 C12.98082,0.708306057 14.001167,1.00432515 13.9849419,1.76555629 L13.8216405,9.42715481 L18.2906212,9.75040052 C18.863659,9.79184891 19.1870791,10.4138035 18.8840432,10.8915839 L11.429483,22.6447911 C11.01918,23.2916939 9.998833,22.9956748 10.0150581,22.2344437 L10.1783595,14.5728452 Z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

AarIcon.iconId = 'aar';


export default AarIcon;
