import { fileClient } from "components/fl-ui/common";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";

import FileAttachments from "components/fl-ui/Attachments/FileAttachments";
import { FormGroup } from "components/fl-ui/Form";
import { GreyColors } from "components/fl-ui/colors";

const { getMetadata } = fileClient;

const StyledContainer = styled.div`
  border: 1px solid ${GreyColors.smoke91};
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
`;

const EmptyState = () => {
  return (
    <StyledContainer>
      <h3>Document storage is limited to Business subscribers.</h3>
      <Link to="/billing">Manage your subscription</Link>
    </StyledContainer>
  );
};

const AttachmentsFieldset = ({ attachments, onChange }) => {
  const hasAccess = useEnterpriseFeature("file_storage");
  const { canDelete, canWrite } = usePermissions();

  const handleChange = async (files) => {
    const fileIds = _.map(files, "filepickerId");
    const results = await Promise.allSettled(fileIds.map((x) => getMetadata(x)));
    onChange({
      attachments: results.map(({ value }) => ({
        filepickerId: value.filepickerId,
        name: value.fileName,
      })),
    });
  };

  return (
    <FormGroup label="Attachments (optional)">
      {!hasAccess ? (
        <EmptyState />
      ) : (
        <FileAttachments
          canAdd={canWrite("agreements")}
          canDelete={canDelete("agreements")}
          carousel
          files={attachments}
          onChange={handleChange}
        />
      )}
    </FormGroup>
  );
};

export default AttachmentsFieldset;
