"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const yup_1 = require("yup");
const MobileForcedAppUpdatePlatformDetailsSchema = (0, yup_1.object)({
    deprecatedRange: (0, yup_1.string)().required(),
    expiredRange: (0, yup_1.string)().required(),
}).defined();
const MobileForcedAppUpdateConfigSchema = (0, yup_1.object)({
    android: MobileForcedAppUpdatePlatformDetailsSchema.required(),
    deprecatedAlertCoolDownSeconds: (0, yup_1.number)(),
    ios: MobileForcedAppUpdatePlatformDetailsSchema.required(),
}).defined();
exports.default = MobileForcedAppUpdateConfigSchema;
