import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import context from '../lib/context';
import ImageCell from './ImageCell';
import NonPreviewableCell from './NonPreviewableCell';
import RemoveButton from './RemoveButton';
import { isFilePreviewable, getMetadata } from '../../fileClient';


const PreviewContainer = styled.div`
  cursor: pointer;
  position: relative;
`;
PreviewContainer.displayName = 'PreviewContainer';


const FileAttachmentPreview = ({ fileId, name, url }) => {
  const [ fileMetadata, setFileMetadata ] = useState(null);
  const { canDelete, deleteFile, onFileClick } = useContext(context);
  const canPreview = fileMetadata ? isFilePreviewable(fileMetadata) : !!url;

  useEffect(() => {
    if (fileId) {
      getMetadata(fileId).then(setFileMetadata);
    }
  }, []);

  const childProps = {
    fileId,
    fileName: fileMetadata?.fileName ?? '',
    url,
    onClick: (event) => {
      event.preventDefault();
      onFileClick(fileMetadata, { name, url });
    }
  };


  return (!fileMetadata && !url) ? null : (
    <PreviewContainer>
      {canDelete && <RemoveButton onClick={() => deleteFile(fileId)} />}
      {canPreview ? <ImageCell {...childProps} /> : <NonPreviewableCell {...childProps} border="basic" />}
    </PreviewContainer>
  );
};

FileAttachmentPreview.propTypes = {
  fileId: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
};


export default FileAttachmentPreview;
