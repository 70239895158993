import React from 'react';
import IconContainer from '../IconContainer';


const CheckIcon = (props) => (
  <IconContainer {...props}>
    <path fill="currentColor" d="M8.6 15.6l-4.2-4.2L3 12.8l5.6 5.6 12-12L19.2 5z" fillRule="evenodd"/>

  </IconContainer>
);

CheckIcon.iconId = 'check';


export default CheckIcon;
