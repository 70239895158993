import React from 'react';
import IconContainer from '../IconContainer';


const MapPinIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M12.333 1c4.051 0 7.334 3.068 7.334 6.866 0 6.567-7.334 15.134-7.334 15.134S5 14.434 5 7.866C5 4.066 8.282 1 12.333 1zm.047 1.681c-3.203 0-5.798 2.492-5.798 5.578 0 5.336 5.798 12.295 5.798 12.295s5.797-6.96 5.797-12.295c0-3.085-2.595-5.578-5.797-5.578zm2.86 5.958c0 1.555-1.234 2.815-2.757 2.815-1.523 0-2.757-1.26-2.757-2.815 0-1.554 1.234-2.814 2.757-2.814 1.523 0 2.757 1.26 2.757 2.814zm-1.347 0c0-.794-.631-1.439-1.41-1.439-.779 0-1.41.644-1.41 1.44 0 .795.631 1.439 1.41 1.439.779 0 1.41-.645 1.41-1.44z"
      fill="currentColor" fillRule="nonzero"/>

  </IconContainer>
);

MapPinIcon.iconId = 'mapPin';


export default MapPinIcon;
