import { useContext } from 'react';
import context from '../../context';


const useDatePickerConfig = () => {
  return {
    forceNative: false,
    outputFormat: 'yyyy-MM-dd',
    ...(useContext(context)?.datePicker ?? {})
  };
};


export default useDatePickerConfig;
