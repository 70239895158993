import React from "react";

import AlertsDashboard from "modules/notifications/routes/AlertsDashboard";

export default [
  {
    path: "/alerts",
    element: <AlertsDashboard />,
  },
];
