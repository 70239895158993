import React from 'react';
import IconContainer from '../IconContainer';


const HelpIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M16.556 18.457A8.251 8.251 0 0 0 6.944 5.045a8.25 8.25 0 0 0-1.899 11.512 8.248 8.248 0 0 0 11.51 1.9zm.873 1.219A9.748 9.748 0 0 1 3.826 17.43C.69 13.054 1.694 6.963 6.071 3.826 10.447.689 16.538 1.694 19.674 6.07c3.137 4.377 2.132 10.469-2.245 13.606zM9.912 9.459c0-1.081.795-1.921 1.725-1.921 1.122 0 1.951.72 1.951 1.921 0 .688-.222.982-.993 1.504l-.143.097c-1.056.714-1.509 1.273-1.509 2.369a.75.75 0 1 0 1.5 0c0-.46.167-.666.849-1.127l.144-.097c1.14-.773 1.652-1.448 1.652-2.746 0-2.08-1.544-3.421-3.45-3.421-1.778 0-3.226 1.529-3.226 3.421a.75.75 0 1 0 1.5 0zm2.965 6.655a1.127 1.127 0 1 0-2.255 0 1.127 1.127 0 0 0 2.255 0z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

HelpIcon.iconId = 'help';


export default HelpIcon;
