import useDocumentTitle from "app/hooks/useDocumentTitle";
import { useElevateConsent } from "elevate_plus/hooks/useElevateConsent";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useFarmAdvisorConfig } from "collection/graphql/advisor";
import App from "layout/app";

import { Button } from "components/fl-ui";
import { Radio, RadioGroup } from "components/fl-ui/Form";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const ConsentPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [consentDecision, setConsentDecision] = useState(false);
  const { handleElevateConsentSubmit } = useElevateConsent();
  const {
    data: { programName },
  } = useFarmAdvisorConfig(true);

  const answeredConsent = (consentAnswer) => {
    setSubmitButtonEnabled(true);
    setConsentDecision(consentAnswer);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    try {
      await handleElevateConsentSubmit({
        token,
        consentDecision,
      });
      setHasSubmitted(true);
    } catch (error) {
      App.notify(error.message ?? "Failed to submit. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const okClick = () => {
    navigate("/");
  };

  useDocumentTitle(`${programName} Consent`);

  return (
    <Container>
      <Header title={`${programName} Access`} />
      <Content>
        <ContentMain fullWidth>
          <LoadingWrapper isLoading={loading}>
            {!hasSubmitted && (
              <>
                <p>
                  {programName} is requesting access to your Bushel Farm account as part of your program enrollment. By
                  accepting this request, you are giving {programName} full access to Contracts, Marketing, and Market
                  Prices features and view-only access to the Fields feature of your Bushel Farm account for the
                  duration of your participation in {programName}.
                </p>

                <RadioGroup name="consent_radio" onChange={({ value }) => answeredConsent(value === "accept")}>
                  <Radio
                    label={
                      <span>
                        I <b>ACCEPT</b> the {programName} request to access my account
                      </span>
                    }
                    value="accept"
                  />
                  <Radio
                    label={
                      <span>
                        I <b>DENY</b> the {programName} request to access my account
                      </span>
                    }
                    value="deny"
                  />
                </RadioGroup>

                <Button type="submit" color="primary" disabled={!submitButtonEnabled} onClick={handleSubmit}>
                  Submit
                </Button>
              </>
            )}
            {hasSubmitted && consentDecision && (
              <>
                <h2>Getting Started</h2>
                <p>
                  Thank you for confirming access. Please continue the set up instructions for your {programName}
                  Bushel Farm account.
                </p>
              </>
            )}
            {hasSubmitted && !consentDecision && (
              <>
                <h2>Thank You For Confirming</h2>
                <p>
                  This account will not be shared with {programName}. A support team member will contact you to help set
                  up a separate Bushel Farm account.
                </p>
              </>
            )}
            {hasSubmitted && (
              <Button type="submit" color="primary" onClick={okClick}>
                Ok
              </Button>
            )}
          </LoadingWrapper>
        </ContentMain>
      </Content>
    </Container>
  );
};

export default ConsentPage;
