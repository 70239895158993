import * as Overlays from 'react-overlays';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { format, isValid } from 'date-fns';

import DateInput from './DateInput';
import FullCalendar from './FullCalendar';
import Input from '../Form/Input';
import OverlayContainer from '../Overlay/OverlayContainer';
import PopoverBody from '../Popover/PopoverBody';

import useDatePickerConfig from './hooks/useDatePickerConfig';
import useDatePickerOverlay from './hooks/useDatePickerOverlay';
import useDisplayedDate from './hooks/useDisplayedDate';
import useInputValue from './hooks/useInputValue';
import { normalizeDate } from './utils';


const DatePickerContainer = styled.div`
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="date"]::-webkit-clear-button {
    display: none;
    appearance: none;
  }
`;


const DatePicker = (props) => {
  const { disabled, hideIcon, inputId, onChange, max, min, placeholder, value } = props;
  const date = normalizeDate(value);
  const maxDate = normalizeDate(max) || undefined;
  const minDate = normalizeDate(min) || undefined;
  const inputRef = useRef();

  const config = useDatePickerConfig();
  const showNativeControl = props.forceNative ?? config.forceNative;
  const outputFormat = props.outputFormat ?? config.outputFormat;
  const { containerRef, hideCalendar, isCalendarShown, overlayRef, popoverId, showCalendar } = useDatePickerOverlay();

  const [ inputValue, setInputValue ] = useInputValue(value, props.format);
  const [ displayedDate, setDisplayedDate ] = useDisplayedDate(inputValue);

  const handleOnBlur = (event) => {
    if (!overlayRef.current?.contains(event.relatedTarget)) {
      // only emit change if you are tapping outside the calendar overlay
      const newDate = normalizeDate(inputValue);
      setInputValue(newDate ? format(newDate, props.format) : "");
      emitChange(newDate);
    }
  }

  const emitChange = (date) => {
    if (typeof outputFormat === 'string' && date) {
      date = format(date, outputFormat);
    }
    onChange(date);
  };

  if (showNativeControl) {
    const handleChange = ({ target: { value }}) => emitChange(normalizeDate(value) || null);
    const value = isValid(date) ? format(date, 'yyyy-MM-dd') : inputValue;

    return (
      <Input
        id={inputId}
        name="date"
        onChange={handleChange}
        placeholder={placeholder}
        type="date"
        value={value}
      />
    );
  }


  return (
    <DatePickerContainer ref={containerRef}>
      <DateInput
        aria-describedby={isCalendarShown ? popoverId : undefined}
        autoComplete="off"
        disabled={disabled}
        hideIcon={hideIcon}
        id={inputId}
        onBlur={handleOnBlur}
        onFocus={showCalendar}
        onChange={setInputValue}
        placeholder={placeholder}
        ref={inputRef}
        value={inputValue}
      />

      <Overlays.Overlay show={isCalendarShown} target={inputRef}>
        {({ placement, props }) => {
          const setRef = (element) => {
            overlayRef.current = element;
            return props.ref(element);
          };

          return (
            <OverlayContainer {...props} id={popoverId} role="dialog" ref={setRef} placement={placement}>
              <PopoverBody placement={placement}>
                <FullCalendar
                  displayedDate={displayedDate}
                  hideCalendar={hideCalendar}
                  max={maxDate}
                  min={minDate}
                  onChange={emitChange}
                  setDisplayedDate={setDisplayedDate}
                  value={date}
                />
              </PopoverBody>
            </OverlayContainer>
          );
        }}
      </Overlays.Overlay>
    </DatePickerContainer>
  );
};

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  forceNative: PropTypes.bool,
  format: PropTypes.string,
  hideIcon: PropTypes.bool,
  inputId: PropTypes.string,
  max: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  min: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  onChange: PropTypes.func.isRequired,
  outputFormat: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  placeholder: PropTypes.string,
  value: PropTypes.any
};

DatePicker.defaultProps = {
  disabled: false,
  format: 'MM/dd/yyyy',
  hideIcon: false,
  placeholder: 'MM/DD/YYYY'
};


export default DatePicker;
