import PropTypes from "prop-types";
import React from "react";

import useHistoricalRainfall from "collection/graphql/weather/hooks/useHistoricalRainfall";

import RainfallBadge from "fields/components/rainfall_badge";

const RainfallIndicator = ({ disableTip, fieldId }) => {
  const recent = useHistoricalRainfall().historicalRainfallMap.get(fieldId);
  return !recent ? null : <RainfallBadge event={recent} disableTip={disableTip} />;
};

RainfallIndicator.propTypes = {
  disableTip: PropTypes.bool,
  fieldId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default RainfallIndicator;
