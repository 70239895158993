import FieldActivitiesTable from "activity/components/FieldActivities/FieldActivitiesTable";
import WriteActivityWorkflow from "activity/components/WriteActivityWorkflow";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import withCropMutation from "hoc/withCropMutation";
import useFieldCropsByField from "modules/fields/hooks/useFieldCropsByField";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import BlankDetail from "fields/components/BlankDetail";

const FieldActivities = ({ createCrop, year }) => {
  const fieldId = +useParams().id;
  const [selectedCropId, setSelectedCropId] = useState();
  const [fullRefreshCounter, setFullRefreshCounter] = useState(1);
  const { fieldCrops: crops, loading } = useFieldCropsByField({ fieldId, year });
  const selectedCrop = _.find(crops, { id: selectedCropId });
  const sortedCrops = _.sortBy(crops, "commodity.name");
  sortedCrops.sort((a, b) => b.cropYear - a.cropYear);

  const handleCreateCropClick = () => {
    createCrop(fieldId, { cropYear: year });
  };

  return (
    <LoadingWrapper isLoading={loading && !sortedCrops.length}>
      {!sortedCrops.length && (
        <BlankDetail onClick={handleCreateCropClick}>
          <p>Add a crop to log activities</p>
          <span>{`Add ${year || new Date().getFullYear()} crop`}</span>
        </BlankDetail>
      )}

      {sortedCrops.map((fieldCrop) => (
        <div key={fieldCrop.id}>
          <h3 className="small-caps-heading">
            {fieldCrop.commodity.name} {fieldCrop.cropYear}
          </h3>

          <FieldActivitiesTable
            cropId={fieldCrop.id}
            cropYear={fieldCrop.cropYear}
            fieldId={fieldId}
            refreshToken={fullRefreshCounter}
            setSelectedCropId={setSelectedCropId}
          />
        </div>
      ))}

      {selectedCrop && (
        <WriteActivityWorkflow
          defaults={{ crop: selectedCrop }}
          onAbort={() => setSelectedCropId(null)}
          onCompletion={({ activityDescriptor }) => {
            setSelectedCropId(null);
            if (activityDescriptor.action === "create") {
              setFullRefreshCounter(fullRefreshCounter + 1);
            }
          }}
        />
      )}
    </LoadingWrapper>
  );
};

const WrappedActivities = withCropMutation(FieldActivities);

WrappedActivities.propTypes = {
  fieldId: PropTypes.number,
  fieldIds: PropTypes.arrayOf(PropTypes.number),
  year: PropTypes.number,
};

export default WrappedActivities;
