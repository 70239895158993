import React from 'react';
import IconContainer from '../IconContainer';


const MarketingPricesIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M18.485 14.85v1.575c1.872.393 2.702.984 2.702 2.172 0 1.362-.823 1.782-2.702 1.856v.702a.625.625 0 0 1-1.25 0v-.71c-1.071-.084-1.976-.423-2.638-.932a.625.625 0 0 1 .762-.99c.45.345 1.09.591 1.876.668v-1.732c-1.65-.34-2.613-.866-2.613-1.89 0-1.454.89-1.924 2.613-1.998v-.915a.625.625 0 1 1 1.25 0v.937c.792.074 1.575.271 2.217.564a.625.625 0 1 1-.519 1.138 5.733 5.733 0 0 0-1.698-.445zm-1.25-.029c-1.01.048-1.363.25-1.363.748 0 .04.116.16.443.309.243.11.554.21.92.299V14.82zm1.25 4.383c1.121-.045 1.452-.217 1.452-.607 0-.374-.35-.644-1.452-.895v1.502zm.265-8.077l-1.629.057c.344-1.11.554-2.216.554-3.265 0-3.366-2.89-6.085-6.457-6.085-3.566 0-6.456 2.718-6.456 6.085 0 5.821 6.456 13.413 6.456 13.413s.744-.875 1.727-2.258c.185.593.456 1.12.812 1.582-1.439 2.026-2.59 3.344-2.59 3.344S3 14.655 3 7.49C3 3.346 6.655 0 11.167 0c4.511 0 8.166 3.347 8.166 7.49 0 1.17-.218 2.4-.583 3.637zm-4.346-2.793a3.07 3.07 0 1 1-6.14 0 3.07 3.07 0 0 1 6.14 0zm-1.5 0a1.57 1.57 0 1 0-3.14 0 1.57 1.57 0 0 0 3.14 0z"
      fill="currentColor"
      fillRule="nonzero"/>

  </IconContainer>
);

MarketingPricesIcon.iconId = 'marketingPrices';


export default MarketingPricesIcon;
