"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const dismissUserNotification = (0, client_1.gql) `
  mutation dismissUserNotification($id: Int!) {
    dismissUserNotification(notificationId: $id) {
      ok
    }
  }
`;
exports.default = dismissUserNotification;
