import { useQuery } from "@apollo/client";
import { fileClient } from "components/fl-ui/common";
import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { marketingClient } from "collection/graphql/client";
import useAsync from "hooks/useAsync";

import LoadingWrapper, { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";

const clientConfig = {
  clientSideID: process.env.LAUNCHDARKLY_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  deferInitialization: true,
};

/**
 * Builds a LaunchDarkly user context
 */
const buildUserContext = (user) => {
  return {
    kind: "user",
    key: user.id,
    name: user.name,
    avatar: user.icon ? fileClient.getUrl(user.icon) : undefined,
    email: user.email,
  };
};

/*
 * We do not want to initialize LaunchDarkly for anonymous contexts.
 */
const AnonymousProvider = ({ children }) => {
  return <>{children}</>;
};
AnonymousProvider.displayName = "AnonymousFlagProvider";
AnonymousProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

/*
 * If the user has been authenticated, initialize the LDProvider + the LaunchDarklyFlagLoader
 */
const AuthenticatedProvider = withSuspenseWrapper(({ children, currentUser }) => {
  const Provider = useMemo(() => {
    const config = {
      ...clientConfig,
      context: buildUserContext(currentUser),
    };

    return withLDProvider(config)(LaunchDarklyFlagLoader);
  }, [currentUser]);

  return <Provider>{children}</Provider>;
});
AuthenticatedProvider.displayName = "AuthenticatedFlagProvider";
AuthenticatedProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

/*
 * This component exists to ensure that the client has been initialized before we start to render the children.
 */
const LaunchDarklyFlagLoader = ({ children }) => {
  const client = useLDClient();
  const { value: hasInitialized = false } = useAsync(async () => {
    if (client) {
      await client.waitForInitialization();
      return true;
    }

    return false;
  }, [client]);

  return hasInitialized ? <>{children}</> : <LoadingWrapper isLoading />;
};

const LaunchDarklyProvider = ({ children }) => {
  if (!clientConfig.clientSideID) {
    throw new Error("LD clientSideID is required");
  }

  const { data, error, loading } = useQuery(GET_CURRENT_USER, {
    client: marketingClient,
  });
  if (loading) {
    return <LoadingWrapper isLoading />;
  } else if (!data?.currentUser || error) {
    return <AnonymousProvider>{children}</AnonymousProvider>;
  }

  return <AuthenticatedProvider currentUser={data?.currentUser}>{children}</AuthenticatedProvider>;
};

export default LaunchDarklyProvider;
