import getUrl from './getUrl';


/**
 * Generates a preview url for a given file by id.
 * @param {String} fileId
 * @param {Object} [options={}]
 * @param {String} [options.fit='crop']
 * @param {String} [options.format='jpg']
 * @param {Number} [options.height]
 * @param {Number} [options.width]
 * @return {String}
 */
export default function getImagePreviewUrl(fileId, options = {}) {
  options = {
    format: 'jpg',
    ...options
  };

  return `${getUrl(fileId)}/convert?${new URLSearchParams(options).toString()}`;
}
