"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationStatusStrings = void 0;
/**
 * A representation of the status of an integration, backend returns a sting, but we do more with it in the frontend.
 */
exports.IntegrationStatusStrings = {
    UNAUTHENTICATED: "UNAUTHENTICATED",
    MISSING_PERMISSION: "MISSING_PERMISSION",
    OK: "OK",
};
