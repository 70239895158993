import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';
import {
  addDays,
  format,
  getDaysInMonth,
  isAfter, isBefore, isSameDay,
  startOfDay, startOfMonth, startOfWeek
} from 'date-fns';


const Day = styled.button`
  background: transparent;
  border: 1px solid ${themeGet('colors.datePicker.dayBorderColor')};
  cursor: pointer;
  
  &.selected {
    background: ${themeGet('colors.datePicker.dayBackgroundSelected')};
    border: 2px solid ${themeGet('colors.datePicker.dayBorderColorSelected')};
    color: ${themeGet('colors.datePicker.dayColorSelected')};
    cursor: default;
    padding: 10px;
  }
  
  :hover:not(:disabled):not(&.selected) {
    background: ${themeGet('colors.datePicker.dayBackgroundHover')};
    border: 2px solid ${themeGet('colors.datePicker.dayBorderColorHover')};
    padding: 9px;
  }
  
  :disabled {
    cursor: default;
  }
`;


const CalendarUI = styled.div`
  display: grid;
  font-size: ${themeGet('fontSizes.md')};
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  
  > * {
    padding: 10px;
  }
  
  /* Weekdays */
  > :nth-child(-n+7) {
    cursor: default;
    font-weight: ${themeGet('fontWeights.bold')};
  }
  /* Days of month */
  > :nth-child(8) {
    grid-column: ${_.property('column')};
  }
`;


const Calendar = (props) => {
  const { displayedDate, max, min, onChange, selectedDate } = props;

  const theFirstDay = new Date(displayedDate.getFullYear(), displayedDate.getMonth(), 1);
  const firstSunday = startOfWeek(startOfMonth(displayedDate));
  const days = _.range(0, getDaysInMonth(theFirstDay));
  const firstDayOffset = +format(theFirstDay, 'e');


  return (
    <CalendarUI column={firstDayOffset}>
      {_.range(0, 7).map((i) => (
        <div key={`weekday${i}`} title={format(addDays(firstSunday, i), 'EEEE')}>
          {format(addDays(firstSunday, i), 'EEEEEE')}
        </div>
      ))}

      {days.map((i) => {
        const thisDate = addDays(theFirstDay, i);
        const isSelected = isSameDay(thisDate, selectedDate);

        let isDisabled = min && isBefore(startOfDay(thisDate), startOfDay(min));
        isDisabled = !isDisabled && max ? isAfter(startOfDay(thisDate), startOfDay(max)) : isDisabled;

        const onClick = isSelected || isDisabled ? undefined : () => onChange(thisDate);
        const title = format(thisDate, 'MMMM do, yyyy');

        return (
          <Day key={i} className={cx({ selected: isSelected })} disabled={isDisabled} isSelected={isSelected} onClick={onClick} title={title}>
            <time dateTime={format(thisDate, 'yyyy-MM-dd')}>
              {format(thisDate, 'd')}
            </time>
          </Day>
        );
      })}
    </CalendarUI>
  );
};

Calendar.propTypes = {
  displayedDate: PropTypes.instanceOf(Date).isRequired,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.any
};


export default Calendar;
