import { StyleSheet } from "aphrodite";

import { styles as paneStyles } from "components/fl-ui/Layout/Pane/PaneContents";
import { UIColors, GreyColors } from "components/fl-ui/colors";
import { Mixins, Spacing, Typography, BorderRadius, Borders } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  aarIcon: {
    color: UIColors.green,
  },
  aarIcon_lg: {
    height: "2em",
    width: "2em",
  },
  aarIcon_md: {
    height: "1.2rem",
    width: "1.2rem",
  },
  centerVertically: {
    alignItems: "center",
    display: "flex",
  },
  alignCenter: {
    textAlign: "center",
  },
  alignRight: {
    textAlign: "right",
  },
  aphButton: {
    marginTop: "1rem",
    width: "max-content",
  },
  aphRow: {
    border: Borders.regular,
    borderBottom: "none",
    margin: "0rem",
    padding: `${Spacing.regular} ${Spacing.xxsmall}`,
    ":last-of-type": {
      borderBottom: Borders.regular,
    },
  },
  blank_content: {
    display: "flex",
    width: "100%",
  },
  blank_contentError: {
    border: `2px solid ${UIColors.red}`,
  },
  blueText: {
    color: UIColors.blue,
  },
  borderStyle: {
    border: Borders.regular,
  },
  bottom_margin: {
    marginBottom: Spacing.large,
  },
  cardStyle: {
    boxShadow: `0 2px 4px 0 ${UIColors.shadowOne}`,
    marginBottom: Spacing.xsmall,
  },
  cardText: {
    fontSize: Typography.sizes.lg,
    fontWeight: Typography.weights.medium,
    marginBottom: Spacing.xsmall,
  },
  check: {
    height: Spacing.medium,
    marginLeft: Spacing.xsmall,
    width: Spacing.medium,
  },
  checkedBackground: {
    background: "rgb(249, 252, 255)",
    border: "1px solid rgb(123, 178, 234)",
  },
  dark_green_text: {
    color: "rgb(43, 108, 59)",
  },
  fieldCard: {
    border: Borders.regular,
    borderRadius: BorderRadius.medium,
    padding: Spacing.regular,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  fieldCard_Col: {
    marginBottom: Spacing.xsmall,
  },
  fieldCard_Col_MinHeight: {
    minHeight: "339px",
  },
  fieldCard_compact: {
    paddingTop: Spacing.xsmall,
    paddingBottom: Spacing.xsmall,
  },
  fieldCard_fieldImg: {
    width: Spacing.xxxlarge,
    height: Spacing.xxxlarge,
    backgroundColor: GreyColors.smoke95,
    marginRight: Spacing.xsmall,
  },
  fieldCard_groupName: {
    margin: 0,
    textTransform: "uppercase",
    fontSize: Mixins.toRem(11),
    lineHeight: 1.45,
    color: GreyColors.smoke30,
    letterSpacing: "1.38px",
  },
  fieldCard_header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fieldCard_info: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  fieldCard_irrigationIcon: {
    width: Spacing.regular,
    height: Spacing.regular,
    color: UIColors.primary,
    display: "inline",
    verticalAlign: "middle",
    marginRight: Spacing.xsmall,
  },
  fieldCard_name: {
    cursor: "pointer",
    margin: 0,
    color: GreyColors.smoke30,
    fontSize: Typography.sizes.lg,
    lineHeight: 1.225,
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  fieldCard_p: {
    margin: 0,
    textAlign: "left",
  },
  fieldCard_subHeader: {
    textTransform: "uppercase",
    letterSpacing: "0.5px",
    lineHeight: 1,
    fontSize: Typography.sizes.sm,
    fontWeight: Typography.weights.regular,
    margin: 0,
    marginBottom: Spacing.xsmall,
    color: GreyColors.smoke30,
  },
  fieldGroup_childContainer: paneStyles.defaultContainer,
  fieldGroup_childContainer_noGroups: paneStyles.unIndentedContainer,
  fieldGroup_dialogPrimaryButton: {
    marginLeft: `${Spacing.large} !important`,
  },
  fieldGroup_edit: {
    marginRight: Spacing.regular,
    marginLeft: Spacing.xxsmall,
    display: "flex",
    flexDirection: "row",
    "@media only screen and (max-width: 520px)": {
      flexDirection: "column",
    },
  },
  fieldGroup_buttonGroup: {
    display: "flex",
    justifyContent: "center",
    "@media only screen and (max-width: 520px)": {
      marginTop: Spacing.xsmall,
      gap: Spacing.small,
    },
  },
  fieldGroup_editButton: {
    marginLeft: `${Spacing.xxsmall}`,
    "@media only screen and (max-width: 520px)": {
      width: "100%",
      marginLeft: 0,
    },
  },
  fieldGroup_grid: {
    marginLeft: "-0.25rem",
    marginRight: "-0.25rem",
  },
  fieldGroup_grid_noGroup: {
    marginLeft: "-0.5rem",
    marginRight: "-0.5rem",
  },
  fieldGroup_popover: {
    marginLeft: Spacing.regular,
    float: "right",
  },
  fieldsSummary: {
    borderBottom: Borders.regular,
    borderTop: Borders.regular,
    margin: `${Spacing.small} 0`,
    padding: `${Spacing.small} 0`,
  },
  fieldsSummary_bold: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    "@media only screen and (min-width: 60rem)": {
      fontSize: Typography.sizes.xl,
    },
    "@media only screen and (max-width: 30rem)": {
      fontSize: Typography.sizes.sm,
    },
  },
  fieldsSummary_columns: {
    alignItems: "center",
    display: "flex",
    flexFlow: "column",
    fontSize: Typography.sizes.rg,
    justifyContent: "center",
    ":first-child": {
      borderRight: Borders.regular,
    },
    ":last-child": {
      display: "none",
    },
    "@media only screen and (min-width: 60rem)": {
      ":last-child": {
        display: "flex",
      },
    },
  },
  filterBar_container: {
    marginBottom: Spacing.xxlarge,
  },
  filterBar_controls: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    "@media only screen and (min-width: 60rem)": {
      justifyContent: "flex-start",
    },
    "@media print": {
      display: "none",
    },
  },
  filterBar_controls_center: {
    justifyContent: "center",
  },
  filterBar_layoutIcon: {
    height: Spacing.xlarge,
    marginTop: Spacing.regular,
    width: Spacing.xlarge,
  },
  filterBar_text: {
    fontWeight: 500,
    margin: `0 ${Spacing.xsmall}`,
  },
  filterBar_viewButton: {
    margin: `0 ${Spacing.xxsmall} !important`,
  },
  filterModal_viewButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    ":hover": {
      backgroundColor: UIColors.primary,
      color: UIColors.white,
      borderColor: UIColors.primary,
    },
    ":first-child": {
      borderRadius: `3px 0 0 3px`,
    },
    ":last-child": {
      borderRadius: `0 3px 3px 0`,
    },
  },
  filterModal_viewButton_selected: {
    backgroundColor: UIColors.primary,
    color: UIColors.white,
    borderColor: UIColors.primary,
  },
  greenText: {
    color: UIColors.green,
  },
  helpIcon: {
    height: Mixins.toRem(16),
    marginRight: Spacing.xxsmall,
    width: Mixins.toRem(16),
  },
  hide_on_desktop: {
    "@media only screen and (min-width: 60rem)": {
      display: "none",
    },
  },
  hide_on_mobile: {
    "@media only screen and (max-width: 60rem)": {
      display: "none",
    },
  },
  iconButton: {
    display: "inline",
    width: Spacing.xxlarge,
    height: Spacing.xxlarge,
    padding: 0,
    backgroundColor: UIColors.white,
    ":hover": {
      backgroundColor: GreyColors.smoke97,
      color: UIColors.primary,
    },
  },
  iconButton_selected: {
    backgroundColor: GreyColors.smoke97,
  },
  inputText: {
    fontSize: Typography.sizes.rg,
    marginBottom: 0,
  },
  linkText: {
    color: UIColors.blue,
    fontSize: Typography.sizes.mn,
  },
  no_marginBottom: {
    marginBottom: 0,
  },
  progress_bar: {
    margin: `${Spacing.xsmall} 0`,
  },
  progress_legend: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    ":only-child": {
      justifyContent: "start",
      width: "100%",
    },
  },
  progress_legend_container: {
    display: "flex",
    flexFlow: "row-reverse",
    fontSize: Typography.sizes.rg,
    justifyContent: "space-between",
    marginTop: Spacing.xsmall,
  },
  progress_legend_indicator: {
    borderRadius: "50%",
    display: "inline-block",
    height: "8px",
    marginRight: "6px",
    width: "8px",
  },
  progress_popover: {
    background: "rgba(23, 24, 28, 0.9)",
    borderRadius: BorderRadius.medium,
  },
  progress_popover_container: {
    display: "flex",
    flexFlow: "column-reverse",
  },
  progress_popover_text: {
    fontSize: Typography.sizes.mn,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.3,
    margin: `0 ${Spacing.xxsmall}`,
    ":last-child": {
      marginLeft: Spacing.large,
    },
  },
  redText: {
    color: UIColors.red,
  },
  rightAlign: {
    display: "flex",
    flexFlow: "row-reverse",
  },
  rightMargin: {
    marginRight: Spacing.xsmall,
  },
  search: {
    marginRight: Spacing.regular,
    "@media only screen and (min-width: 62.51rem) and (max-width: 80rem)": {
      display: "none",
    },
  },
  sortDropdown: {
    display: "flex",
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.regular,
    justifyContent: "space-between",
    marginBottom: Spacing.xsmall,
    minHeight: Spacing.xxlarge,
    width: "100%",
    ":last-child": {
      marginBottom: 0,
    },
  },
  spacedBetweenContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  statusText: {
    fontSize: Typography.sizes.sm,
    textTransform: "uppercase",
    marginBottom: Spacing.xsmall,
  },
  suffixDropdownButton: {
    marginRight: `-${Spacing.minor}`,
    minWidth: Spacing.xxxlarge,
    padding: Spacing.xxsmall,
  },
  suffixDropdownList: {
    display: "flex",
    fontWeight: Typography.weights.regular,
    padding: `0 ${Spacing.xxsmall} ${Spacing.xsmall}`,
  },
  top_margin: {
    marginTop: Spacing.regular,
  },
  ellipsify: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});

export default styles;
