import { ApolloProvider } from "@apollo/client";
import { ConfigProvider, theme } from "components/fl-ui/common";
import { isNative } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React from "react";
import { AuthProvider as OIDCProvider } from "react-oidc-context";

import { AuthContextProvider } from "collection/graphql/auth";
import { marketingClient } from "collection/graphql/client";
import AnalyticsProvider from "context/AnalyticsProvider";
import LaunchDarklyProvider from "context/LaunchDarklyProvider";
import RedirectionProvider from "context/RedirectionProvider";
import oidcConfiguration from "context/oidcConfiguration";

import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";

const GlobalContextProvider = ({ children }) => {
  const uiConfig = {
    datePicker: {
      forceNative: isNative(),
      outputFormat: "yyyy-MM-dd",
    },
  };

  return (
    <ConfigProvider config={uiConfig} theme={theme}>
      <OIDCProvider {...oidcConfiguration}>
        <AuthContextProvider>
          <LaunchDarklyProvider>
            <ErrorBoundary>
              <ApolloProvider client={marketingClient}>
                <AnalyticsProvider>
                  <RedirectionProvider>{children}</RedirectionProvider>
                </AnalyticsProvider>
              </ApolloProvider>
            </ErrorBoundary>
          </LaunchDarklyProvider>
        </AuthContextProvider>
      </OIDCProvider>
    </ConfigProvider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default GlobalContextProvider;
