import React from "react";
import ReportDetail from "reports/routes/ReportDetail";

import ReportsDashboard from "modules/reports/routes/ReportsDashboard";

export default [
  {
    path: "/reports",
    element: <ReportsDashboard />,
  },

  {
    path: "/reports/:reportId",
    element: <ReportDetail />,
  },
];
