import _ from "lodash";

import utils from "lib/validation/utils";

export const isPositiveInteger = (value) => utils.isInt(value, { min: 1 });
export const isValidField = (value) =>
  isPositiveInteger(value) || (_.isPlainObject(value) && isPositiveInteger(value.id || value.value));

export const isValid = (formData, errors = {}) => _.isEmpty(validate(formData, { errors }));

export const validate = (formData, options = {}) => {
  const errors = options.errors || {};

  // name
  if (!_.trim(formData.name)) {
    errors.name = { value: formData.name };
  }

  // commodity
  if (!isPositiveInteger(formData.commodity) && !isPositiveInteger((formData.commodity || {}).id)) {
    errors.commodity = { value: formData.commodity };
  }

  // fields
  if (formData.fields && !_.isArray(formData.fields)) {
    errors.fields = { value: formData.fields };
  } else if (!_.every(formData.fields, isValidField)) {
    errors.fields = { value: formData.fields };
  }

  // acresPlanted
  if (!isPositiveInteger(formData.acresPlanted)) {
    errors.acresPlanted = { value: formData.acresPlanted, msg: "Crop acres must be greater than zero" };
  }

  // averageYield
  if (!(formData.averageYield > 0) || !utils.isDecimal(formData.averageYield, { decimal_digits: "0," })) {
    errors.averageYield = { value: formData.averageYield, msg: "Average yield must be greater than zero" };
  }

  return errors;
};

export default {
  isValid,
  validate,
};
