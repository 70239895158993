import { useQuery } from "@apollo/client";
import React from "react";
import AddEditUserModal from "settings/components/AddEditUserModal";
import UserSettingsCargillElevateBanner from "settings/components/UserSettingsCargillElevateBanner";
import UserSettingsItem from "settings/components/UserSettingsItem";
import useUserSettingsPage from "settings/hooks/useUserSettingsPage";

import { getCurrentUserMembership } from "collection/graphql/enterprise/queries";
import usePermissions from "hooks/usePermissions";
import InviteSuccess from "modules/settings/components/invite_success";

import { Button } from "components/fl-ui";
import { Stack } from "components/fl-ui/Layout";

const Users = () => {
  const {
    addAnotherUser,
    isEditingSelf,
    hideSuccessModal,
    isSuccessModalShown,
    newlyAddedUserEmail,
    userData,
    userGroups,
    showUserModal,
    onAdd,
    onCancel,
    onEdit,
    onSave,
  } = useUserSettingsPage();
  const canEdit = usePermissions().canWrite("access_control");

  const { data: userMembership } = useQuery(getCurrentUserMembership);

  const currentUserRole = userMembership?.currentMembership?.role?.name;

  const canEditRole = (role) => {
    if (canEdit) {
      if (currentUserRole === "admin") {
        return true;
      }
      return role !== "admin";
    }
    return canEdit;
  };

  return (
    <Stack className="margin-top-10">
      <div className="users-header margin-top-10">
        <img alt="Invite people" height="80" src="/images/invite/people-icon.svg" width="83" />
        <h1>Add people to your farm</h1>
        <p>
          Use Bushel Farm to its fullest potential. Adding people to your farm can help you and your team collaborate
          easily and stay in sync.
        </p>
      </div>

      <UserSettingsCargillElevateBanner />

      {/* Admins */}
      <UserSettingsItem
        addButton={
          <Button disabled={!canEditRole("admin")} onClick={() => onAdd("admin")}>
            + Add Admin
          </Button>
        }
        blankSlateText="No admins"
        canEdit={canEditRole("admin")}
        description="Admins have full access to your entire Bushel Farm account, including the financial data you enter."
        onEditUser={onEdit}
        role="admin"
        title="Admins"
        users={userGroups.admin}
      />

      {/* Managers */}
      <UserSettingsItem
        addButton={<Button onClick={() => onAdd("manager")}>+ Add Manager</Button>}
        blankSlateText="No managers"
        canEdit={canEditRole("manager")}
        description="Managers have full access to everything except financial data."
        onEditUser={onEdit}
        role="manager"
        title="Managers"
        users={userGroups.manager}
      />

      {/* Contributors */}
      <UserSettingsItem
        addButton={<Button onClick={() => onAdd("contributor")}>+ Add Contributor</Button>}
        blankSlateText="No users"
        canEdit={canEditRole("contributor")}
        description="Contributors can log in and record activities but cannot see financial data or edit other users."
        onEditUser={onEdit}
        role="contributor"
        title="Contributors"
        users={userGroups.contributor}
      />

      {/* No access */}
      <UserSettingsItem
        addButton={<Button onClick={() => onAdd("no_access")}>+ Add No Access</Button>}
        blankSlateText="No no-access users"
        canEdit={canEditRole("no_access")}
        description="These people cannot view your Bushel Farm account. However, other users can still record activities for this person."
        onEditUser={onEdit}
        role="no_access"
        title="No Access"
        users={userGroups.no_access}
      />

      {showUserModal && (
        <AddEditUserModal
          isEditingSelf={isEditingSelf}
          canEdit={canEdit}
          data={userData}
          isNew={!userData.id}
          onCancel={onCancel}
          onSave={onSave}
        />
      )}

      {isSuccessModalShown && (
        <InviteSuccess
          handleDoneClick={hideSuccessModal}
          handleYesClick={addAnotherUser}
          newEmail={newlyAddedUserEmail}
        />
      )}
    </Stack>
  );
};

Users.tabId = "users";
Users.title = "People Settings";

export default Users;
