import { css } from "aphrodite/no-important";
import React from "react";

import useReleaseFeature from "hooks/useReleaseFeature";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const ImageryBlankSlateBanner = (props) => {
  const noCCRequired = useReleaseFeature("no-cc-required-for-trial");
  const actionText = noCCRequired ? "Upgrade Plan" : "Start a free trial";
  const secondaryText = noCCRequired
    ? "Satellite Imagery requires a paid subscription. Included in the Essentials or Business plan."
    : "Satellite Imagery is included in the Essentials and Business plans and you can try it for free.";

  props = {
    ...props,
    actionButtonText: actionText,
    secondaryText: secondaryText,
    imagePath: "images/pay_gates/banners/imagery.svg",
    title: "With Satellite Imagery you can:",
  };

  return (
    <PayGateCommonBlankSlateBanner {...props}>
      <ul>
        <li className={css(styles.valueProp)}>
          Receive 10 meter resolution images delivered to your account approximately every week.
        </li>
        <li className={css(styles.valueProp)}>Toggle between true color and NDVI images.</li>
        <li className={css(styles.valueProp)}>
          Identify yield threats and focus scouting efforts on areas showing signs of stress.
        </li>
      </ul>
    </PayGateCommonBlankSlateBanner>
  );
};

export default ImageryBlankSlateBanner;
