import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { border, color, space, typography } from 'styled-system';


const Input = styled.input`
  ${border}
  ${color}
  ${space}
  ${typography}
`;

Input.propTypes = {
  /**
   * Analogous to the type attribute on native html inputs.
   */
  type: PropTypes.string
};

Input.defaultProps = {
  border: 'basic',
  borderRadius: 4,
  color: 'darkGray',
  fontSize: 'md',
  p: '.5em',
  type: 'text'
};


export default Input;
