import { css } from "aphrodite";
import { styles } from "billing/lib";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import WarningBanner from "settings/components/WarningBanner";

import { fetch } from "lib/ajax";

import { Button } from "components/fl-ui";
import { Input } from "components/fl-ui/Form";
import { Cluster } from "components/fl-ui/Layout";

const ErrorMessage = ({ error }) => (
  <span data-cy="couponError">
    The promo code [<code className={css(styles.couponErrorValue)}>{error}</code>] is invalid or expired.
  </span>
);

const CouponFieldset = () => {
  const form = useFormContext();
  const [mode, setMode] = useState("ready");
  const [error, setError] = useState(null);
  const [value, setValue] = useState("");
  const [isSelected, setIsSelected] = useState(false);

  const onSubmit = async (event) => {
    const handleFailure = () => {
      setError(value);
      setMode("ready");
      setIsSelected(true);
    };

    event.preventDefault();
    if (value) {
      setMode("pending");
      setError(null);
      setIsSelected(false);

      try {
        const resp = await fetch(`/v2.0/subscriptions/coupon/${value}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const result = await resp.json();

        if (resp.status !== 200 || result.expired) {
          handleFailure();
        } else {
          form.setValue("coupon", result);
        }
      } catch (err) {
        handleFailure();
      }
    }
  };

  return (
    <form className={css(styles.bottom_line)} data-cy="couponFieldset" onSubmit={onSubmit}>
      <Cluster between>
        <span>Promo code</span>
        <Input
          className={css(styles.pos_promo_code)}
          isSelected={isSelected}
          name="coupon"
          onChange={(event, { coupon }) => setValue(coupon.trim())}
          suffix={
            <Button
              className={css(styles.pos_promo_code_button)}
              color="primary"
              disabled={mode !== "ready" || !value}
              loading={mode === "pending"}
              style={{ marginRight: "-0.75rem" }}
              type="submit"
            >
              Apply
            </Button>
          }
          value={value}
        />
      </Cluster>

      {error && (
        <WarningBanner
          cssClassName={styles.couponErrorContainer}
          icon="warning"
          message={<ErrorMessage error={error} />}
        />
      )}
    </form>
  );
};

export default CouponFieldset;
