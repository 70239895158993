import { getClientInstance } from './client';


/**
 * Fetches metadata for a given file by id.
 *
 * @param {String} fileId
 * @return {Promise} FilePicker metadata
 * @see {@link https://www.filestack.com/docs/api/file/#metadata}
 */
export default function getMetadata(fileId) {
  return getClientInstance().retrieve(fileId, { metadata: true })
    .then(({ filename, mimetype, ...metaData }) => {
      return {
        ...metaData,
        fileName: filename,
        filepickerId: fileId,
        mimeType: mimetype,
        s3Id: metaData.key
      };
    });
};
