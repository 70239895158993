import React from 'react';
import IconContainer from '../IconContainer';


const ActivitiesIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M20.238 4.003L5.848 4C4.284 4 3 5.194 3 6.688 3 8.18 4.284 9.376 5.848 9.376h11.936c.74 0 1.324.543 1.324 1.194 0 .65-.584 1.194-1.324 1.194L5.85 11.758C4.284 11.758 3 12.953 3 14.446s1.284 2.688 2.848 2.688l11.937-.016c.739 0 1.323.544 1.323 1.194 0 .651-.584 1.195-1.324 1.195H12.11a.755.755 0 0 0-.762.746c0 .413.341.747.762.747h5.675c1.565 0 2.848-1.194 2.848-2.688 0-1.493-1.283-2.687-2.848-2.687l-11.937.015c-.738 0-1.323-.544-1.323-1.194 0-.65.585-1.195 1.324-1.195l11.936.007c1.565 0 2.848-1.195 2.848-2.688s-1.283-2.687-2.848-2.687H5.848c-.739 0-1.324-.545-1.324-1.195s.585-1.195 1.324-1.195l14.39.003c.42 0 .762-.334.762-.746a.754.754 0 0 0-.762-.747z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

ActivitiesIcon.iconId = 'activities';


export default ActivitiesIcon;
