import { css } from "aphrodite/no-important";
import { reverse, sortBy } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";

import SoilLayer from "lib/map/layers/soil";
import useSoilLayers from "lib/map/layers/useSoilLayers";

import BasicFieldMap from "components/field/BasicFieldMap";
import { Card, CardHeader } from "components/fl-ui/Card";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import styles from "fields/onboarding/onboardingStyles";

const soilLayer = new SoilLayer();
const getSoilData = (features) => {
  features.forEach((feature) => soilLayer.addFeature(feature));
  const soilData = sortBy(soilLayer.getProperties(), ["acreage"])
    .slice(-3)
    .map(({ acreage, color, id, name, slope }) => ({ acreage, color, id, name, slope }));

  return reverse(soilData);
};

const SoilMapCard = ({ fieldId, geometry, skip }) => {
  const { loading, soilLayers } = useSoilLayers({ fieldId, skip });
  const features = soilLayers[0]?.geometry?.features;
  const soilData = features ? getSoilData(features) : [];

  return (
    <Card className={css(styles.card)}>
      <CardHeader className={css(styles.bottomBorder)}>
        <h2 className={css(styles.mainText)}>Soil maps</h2>
        <h4 className={css(styles.subText)}>SSURGO</h4>
      </CardHeader>

      <LoadingWrapper isLoading={loading}>
        <Row>
          <Col xs={12} sm={5}>
            {!!features && <BasicFieldMap geometry={geometry} layer={soilLayer} style={{ height: "14rem" }} />}
          </Col>

          <Col className={css(styles.soilDataContainer)} xs={12} sm={7}>
            {soilData.map((s) => (
              <div className={css(styles.soilData)} key={s.id}>
                <div style={{ background: s.color }} className={css(styles.soilLegend)} />
                <div>
                  <p className={css(styles.soilDataText)}>
                    <strong>{s.name}</strong>
                  </p>
                  <p className={css(styles.soilDataText)}>
                    {`${s.acreage} ac `}
                    {s.slope && `\u25CF ${s.slope.split(" percent")[0].replace(" to ", "-")}% slopes`}
                  </p>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </LoadingWrapper>
    </Card>
  );
};

SoilMapCard.propTypes = {
  fieldId: PropTypes.number.isRequired,
  geometry: PropTypes.shape({
    coordinates: PropTypes.array,
    type: PropTypes.string,
  }).isRequired,
  skip: PropTypes.bool.isRequired,
};

export default SoilMapCard;
