import React from 'react';
import IconContainer from '../IconContainer';


const PrintIcon = (props) => (
  <IconContainer {...props}>
    <path
      d="M7.499 6.583h8.85V3.5h-8.85v3.083zm10.35 8.995v5.337a.75.75 0 0 1-.75.75H6.749a.75.75 0 0 1-.75-.75v-5.337H3.75a.75.75 0 0 1-.75-.75V7.25a.75.75 0 0 1 .75-.75h2.249V2.75a.75.75 0 0 1 .75-.75h10.35a.75.75 0 0 1 .75.75V6.5h2.249a.75.75 0 0 1 .75.75v7.578a.75.75 0 0 1-.75.75h-2.25zm0-1.5h1.499V8h-1.906a.747.747 0 0 1-.343.083H6.749A.747.747 0 0 1 6.406 8H4.5v6.078h1.499v-1.71a.75.75 0 0 1 .75-.75h10.35a.75.75 0 0 1 .75.75v1.71zm-1.5 6.087v-7.047h-8.85v7.046h8.85zm-7-4.165a.75.75 0 1 1 0-1.5h5.375a.75.75 0 1 1 0 1.5H9.349zm0 2.75a.75.75 0 1 1 0-1.5h3.333a.75.75 0 1 1 0 1.5H9.349z"
      fillRule="nonzero"
      fill="currentColor"
    />

  </IconContainer>
);

PrintIcon.iconId = 'print';


export default PrintIcon;
