/**
 * Returns true if the file is capable of showing previewable thumbnail images.
 * @param {Object} metadata
 * @param {String} metadata.mimetype
 * @return {Boolean}
 */
export default function isFilePreviewable(metadata) {
  const { mimeType } = metadata;

  const isImage = mimeType.startsWith('image/');
  const isPDF = mimeType === 'application/pdf';
  const isXLS = mimeType === 'application/vnd.ms-excel';
  const isXLX = mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';


  return [isImage, isPDF, isXLS, isXLX].some((type) => type);
};
