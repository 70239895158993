import React from 'react';
import IconContainer from '../IconContainer';


const TypeToSearchIcon = (props) => (
  <IconContainer {...props} viewBox="0 0 49 49">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1663.000000, -1483.000000)">
          <g transform="translate(1643.000000, 1463.000000)">
            <g transform="translate(16.000000, 16.000000)">
              <polygon points="0 0 56 0 56 56 0 56"/>
              <path
                d="M42,4.66666667 C44.5666667,4.66666667 46.6666667,6.76666667 46.6666667,9.33333333 L46.6666667,9.33333333 L46.6666667,22.1666667 C45.22,21.56 43.6566667,21.1633333 42,21.0466667 L42,21.0466667 L42,9.33333333 L9.33333333,9.33333333 L9.33333333,42 L25.6666667,42 L21,46.6666667 L9.33333333,46.6666667 C6.76666667,46.6666667 4.66666667,44.5666667 4.66666667,42 L4.66666667,42 L4.66666667,9.33333333 C4.66666667,6.76666667 6.76666667,4.66666667 9.33333333,4.66666667 L9.33333333,4.66666667 Z M37.177777,16.3333333 L37.177777,19.9929823 L31.078362,19.9929823 L31.0784322,28.7832939 C29.8671996,30.4033844 29.1497206,32.4137578 29.1497206,34.5906889 L29.15,34.6313333 L27.418713,34.6315783 L27.418713,19.9929823 L21.319298,19.9929823 L21.319298,16.3333333 L37.177777,16.3333333 Z M24.978947,22.4327483 L24.978947,26.0923973 L21.319298,26.0923973 L21.319298,34.6315783 L17.659649,34.6315783 L17.659649,26.0923973 L14,26.0923973 L14,22.4327483 L24.978947,22.4327483 Z M32.9466667,41.7433333 C31.92,40.11 31.3133333,38.22 31.3133333,36.1666667 C31.3133333,30.3566667 36.0033333,25.6666667 41.8133333,25.6666667 C47.6233333,25.6666667 52.3133333,30.3566667 52.3133333,36.1666667 C52.3133333,41.9766667 47.6233333,46.6666667 41.8366667,46.6666667 C39.7833333,46.6666667 37.87,46.06 36.26,45.0333333 L28.98,52.3133333 L25.6666667,49 L32.9466667,41.7433333 Z M42,42 C45.22,42 47.8333333,39.3866667 47.8333333,36.1666667 C47.8333333,32.9466667 45.22,30.3333333 42,30.3333333 C38.78,30.3333333 36.1666667,32.9466667 36.1666667,36.1666667 C36.1666667,39.3866667 38.78,42 42,42 Z"
                fill="currentColor" fillRule="nonzero"/>
              <polygon points="0 0 56 0 56 56 0 56"/>
            </g>
          </g>
        </g>
      </g>
  </IconContainer>
);

TypeToSearchIcon.iconId = 'typeToSearch';


export default TypeToSearchIcon;
