import useDocumentTitle from "app/hooks/useDocumentTitle";
import { LeftButton } from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import DataImportRow from "settings/components/DataImportRow";
import FieldBoundariesUpload from "settings/components/FieldBoundariesUpload";
import YieldUpload from "settings/components/YieldUpload";
import { Container, ResponsiveHeading, StyledText } from "settings/styledComponents";

import App from "layout/app";
import { fetch } from "lib/ajax";
import { downloadFile } from "lib/download/downloadFile";

import { Button } from "components/fl-ui";

const FIELD_BOUNDARIES = "fieldBoundaries";
const YIELD_DATA = "yieldData";

const DataImportExport = ({ initialModalId }) => {
  const navigate = useNavigate();
  const [activeModal, setActiveModal] = useState(() => {
    if ([FIELD_BOUNDARIES, YIELD_DATA].includes(initialModalId)) {
      return initialModalId;
    } else {
      return null;
    }
  });
  const [isExportingFieldBoundaries, setIsExportingFieldBoundaries] = useState(false);
  const [isExportingUserData, setIsExportingUserData] = useState(false);

  const onClose = () => {
    navigate("/settings/data", { replace: true });
    setActiveModal(null);
  };
  useDocumentTitle("Data Settings");

  const handleFieldBoundariesExport = async (event) => {
    event.preventDefault();

    setIsExportingFieldBoundaries(true);
    const response = await fetch("/v2.0/export/field_boundaries", {
      method: "get",
    }).catch((error) => {
      App.notify("A field data error occurred, please try again.");
      setIsExportingFieldBoundaries(false);
      throw error;
    });

    downloadFile(response, "field_boundaries").catch((error) => {
      App.notify("A field data download error occurred, please try again.");
      setIsExportingFieldBoundaries(false);
      throw error;
    });
    setIsExportingFieldBoundaries(false);
  };

  const handleUserDataExport = async (event) => {
    event.preventDefault();

    setIsExportingUserData(true);
    const response = await fetch("v2.0/export/user_data", { method: "get" }).catch((error) => {
      App.notify("A user data error occurred, please try again.");
      setIsExportingUserData(false);
      throw error;
    });

    downloadFile(response, "user_data").catch((error) => {
      App.notify("A user data download error occurred, please try again.");
      setIsExportingUserData(false);
      throw error;
    });
    setIsExportingUserData(false);
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Container>
            <div>
              <ResponsiveHeading>Data import & export</ResponsiveHeading>
              <StyledText>Move information in or out of your account manually with files.</StyledText>
            </div>
          </Container>
        </Col>
      </Row>

      <DataImportRow
        copy="Zipped shapefiles contain the boundaries of your fields. Upload them here to import your field boundaries into Bushel Farm, or download to export a shapefile of all your boundaries in Bushel Farm."
        icon="fields"
        title="Field Boundary Shapefiles"
      >
        <LeftButton color="primary" hollow onClick={() => setActiveModal(FIELD_BOUNDARIES)}>
          Upload
        </LeftButton>
        <Button
          color="primary"
          disabled={isExportingFieldBoundaries}
          hollow
          loading={isExportingFieldBoundaries}
          onClick={handleFieldBoundariesExport}
        >
          {isExportingFieldBoundaries ? "Downloading" : "Download"}
        </Button>
      </DataImportRow>
      {activeModal === FIELD_BOUNDARIES && <FieldBoundariesUpload onClose={onClose} />}

      <DataImportRow
        copy="Upload zipped files from equipment displays to import yield, as-applied, or as-planted data generated by your machines."
        icon="harvesting"
        title="Equipment or Machine Data"
      >
        <Button color="primary" hollow onClick={() => setActiveModal(YIELD_DATA)}>
          Upload
        </Button>
      </DataImportRow>
      {activeModal === YIELD_DATA && <YieldUpload onClose={onClose} />}

      <DataImportRow
        copy="Export your fields, crops, activities, input applications, sales, and harvest loads as CSV files."
        icon="people"
        title="Account data"
      >
        <Button
          color="primary"
          disabled={isExportingUserData}
          hollow
          loading={isExportingUserData}
          onClick={handleUserDataExport}
        >
          {isExportingUserData ? "Downloading" : "Download"}
        </Button>
      </DataImportRow>
    </>
  );
};

DataImportExport.propTypes = {
  initialModalId: PropTypes.oneOf([FIELD_BOUNDARIES, YIELD_DATA]),
};

export default DataImportExport;
