import propTypes from '@styled-system/prop-types';
import React from 'react';
import styled from 'styled-components';
import { background, border, color, flexbox, fontSize, grid, layout, space } from 'styled-system';


/**
 * Implementation for the `gap` prop since styled-system ignores it.
 * @param props
 * @return {string}
 */
const gapFix = (props) => {
  return "gap" in props ? `gap: ${props.gap};` : "";
};

const BaseElement = styled.div`
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${fontSize}
  ${grid}
  ${layout}
  ${space}
  
  ${gapFix}
`;

BaseElement.propTypes = {
  ...propTypes.background,
  ...propTypes.border,
  ...propTypes.color,
  ...propTypes.flexbox,
  ...propTypes.fontSize,
  ...propTypes.grid,
  ...propTypes.layout,
  ...propTypes.space
};

BaseElement.displayName = 'BaseElement';


export default BaseElement;
