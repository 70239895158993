import MobileForcedAppUpdateRequired from "mobile/MobileForcedAppUpdateRequired";
import getMobileForcedAppUpdateState from "mobile/getMobileForcedAppUpdateState";
import { showAppDeprecated } from "mobile/mobileAppUpdate";
import React, { useEffect } from "react";

import useAsync from "hooks/useAsync";
import useReleaseFeature from "hooks/useReleaseFeature";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const APP_DEPRECATED_ALERT_TIMESTAMP_KEY = "appDeprecatedAlertTimestamp";

const getCurrentTimestamp = () => new Date().getTime();

const shouldShowDeprecatedAlert = (alertCoolDownMilliseconds) => {
  const lastAlertTimestamp = sessionStorage.getItem(APP_DEPRECATED_ALERT_TIMESTAMP_KEY);
  if (!lastAlertTimestamp) {
    return true;
  }
  return getCurrentTimestamp() - parseInt(lastAlertTimestamp, 10) > alertCoolDownMilliseconds;
};

const updateLastAlertTime = () => {
  sessionStorage.setItem(APP_DEPRECATED_ALERT_TIMESTAMP_KEY, getCurrentTimestamp().toString());
};

const MobileForcedAppUpdateGuard = ({ children }) => {
  const config = useReleaseFeature("mobile-forced-app-update-config");

  const { loading, value } = useAsync(async () => {
    return await getMobileForcedAppUpdateState(config);
  }, [config]);

  useEffect(() => {
    if (!value) {
      return;
    }

    const deprecatedAlertCoolDownMilliseconds = value.deprecatedAlertCoolDownSeconds * 1000;
    if (value.appIsDeprecated && shouldShowDeprecatedAlert(deprecatedAlertCoolDownMilliseconds)) {
      showAppDeprecated();
      updateLastAlertTime();
    }
  }, [value]);

  if (loading) {
    return <LoadingWrapper />;
  }

  if (value?.appIsExpired) {
    return <MobileForcedAppUpdateRequired />;
  }

  return <React.Fragment key="MobileForcedAppUpdateGuard">{children}</React.Fragment>;
};

export default MobileForcedAppUpdateGuard;
