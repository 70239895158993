const translations = {
  attach_file: 'Attach file',
  attach_file_plural: 'Attach files',
  attach_image: 'Attach image',
  attach_image_plural: 'Attach images',
  attach_media: 'Attach media',
  attach_video: 'Attach video',
  attach_video_plural: 'Attach videos'
};


export default {
  translations
};
