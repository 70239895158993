import { BaseElement } from "components/fl-ui/common";
import RateChooser from "activity/components/choosers/RateChooser";
import { MicroLabel } from "agreements/forms/components";
import React, { useEffect, useMemo, useState } from "react";

import { Button } from "components/fl-ui";
import { Stack } from "components/fl-ui/Layout";

const RateChooserDemo = () => {
  const [rate, setRate] = useState(null);
  const [inputType, setInputType] = useState("ACTIVITY");
  useEffect(() => setRate(null), [inputType]);

  const handleChange = (rate) => {
    console.info(rate);
    setRate(rate);
  };

  const options = useMemo(() => {
    switch (inputType) {
      case "ACTIVITY":
        return [
          { label: "/ ac", value: "PER_ACRE" },
          { label: "total", value: "TOTAL" },
        ];

      case "PURCHASE":
        return [
          { label: "/ unit", value: "PER_UNIT" },
          { label: "total", value: "TOTAL" },
        ];
    }
  }, [inputType]);

  return (
    <div>
      <h4>RateChooser</h4>

      <Stack>
        <BaseElement alignItems="center" display="flex" gridGap="10px">
          <MicroLabel label="Rate">
            <RateChooser onChange={handleChange} options={options} value={rate} />
          </MicroLabel>

          <Button color={inputType === "ACTIVITY" ? "primary" : "default"} onClick={() => setInputType("ACTIVITY")}>
            Activity Input rates
          </Button>

          <Button color={inputType === "PURCHASE" ? "primary" : "default"} onClick={() => setInputType("PURCHASE")}>
            Purchase Input rates
          </Button>

          <Button disabled={!rate} onClick={() => setRate(null)}>
            Clear
          </Button>
        </BaseElement>

        <div>
          <small>Component value:</small> <strong>{rate || "(null)"}</strong>
        </div>
      </Stack>
    </div>
  );
};

export default RateChooserDemo;
