import React from 'react';
import IconContainer from '../IconContainer';


const SignOutIcon = (props) => (
  <IconContainer {...props}>
    <g transform="rotate(90 9.5 12.5)" fill="none" fillRule="evenodd">
      <mask id="b" fill="none">
        <path
          d="M7.957 2.56L3.929 6.588a.75.75 0 0 1-1.06-1.06L8.175.218a.75.75 0 0 1 1.061 0l5.308 5.308a.75.75 0 0 1-1.06 1.061L9.456 2.56v9.816a.75.75 0 1 1-1.5 0V2.56zM1.5 17.73h14.793l.011-6.717a.75.75 0 0 1 1.5.003l-.013 7.465a.75.75 0 0 1-.75.749H.75a.75.75 0 0 1-.75-.75v-7.455a.75.75 0 1 1 1.5 0v6.705z"/>
      </mask>
      <path
        d="M7.957 2.56L3.929 6.588a.75.75 0 0 1-1.06-1.06L8.175.218a.75.75 0 0 1 1.061 0l5.308 5.308a.75.75 0 0 1-1.06 1.061L9.456 2.56v9.816a.75.75 0 1 1-1.5 0V2.56zM1.5 17.73h14.793l.011-6.717a.75.75 0 0 1 1.5.003l-.013 7.465a.75.75 0 0 1-.75.749H.75a.75.75 0 0 1-.75-.75v-7.455a.75.75 0 1 1 1.5 0v6.705z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <g mask="url(#b)" fill="none">
        <path d="M-3-2h24v24H-3z"/>
      </g>
    </g>

  </IconContainer>
);

SignOutIcon.iconId = 'signOut';


export default SignOutIcon;
